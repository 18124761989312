import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import filterReducer from './slices/filterSlice';
import userReducer from './slices/userSlice';
import parcelReducer from './slices/parcelSlice';
import globalModalReducer from './slices/globalModalSlice';
import regionReducer from './slices/regionSlice';
import articleReducer from './slices/articleSlice';
import notificationReducer from './slices/notificationSlice';
import analyticsTabReducer from './slices/analyticsTabSlice';
import pollingReducer from './slices/pollingSlice';
import globalLayoutReducer from './slices/globalLayoutSlice';
import premiumListTooltipReducer from './slices/premiumListTooltipSlice';
import premiumReducer from './slices/premiumSlice';
import mapInteractionReducer from './slices/mapInteractionSlice';
import homeReducer from './slices/homeSlice';
import auctionListOptionReducer from './slices/auctionListOptionSlice';
import lastTabReducer from './slices/lastTabsSlice';

import { isDevelopmentPhase } from 'utils/environment';

const reducer = {
  auth: authReducer,
  user: userReducer,
  filter: filterReducer,
  parcel: parcelReducer,
  globalModal: globalModalReducer,
  globalLayout: globalLayoutReducer,
  region: regionReducer,
  article: articleReducer,
  notification: notificationReducer,
  analyticsTab: analyticsTabReducer,
  polling: pollingReducer,
  premiumListTooltip: premiumListTooltipReducer,
  premium: premiumReducer,
  mapInteraction: mapInteractionReducer,
  home: homeReducer,
  auctionListOption: auctionListOptionReducer,
  lastTab: lastTabReducer,
};

const DEV_OPTIONS = {
  devTools: {
    trace: true,
  },
};

export const store = configureStore({
  reducer,
  ...(isDevelopmentPhase && DEV_OPTIONS),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void | Promise<void>> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
