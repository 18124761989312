import { log10, getIntegerLength, getFractionDigits } from './numberUtils';

type ToCurrencyOption = {
  digits?: number;
  fixedKoreaNumeral?: KoreaNumeral;
};

type FormatOption = {
  blind?: boolean;
  currencyUnit?: boolean;
  comma?: boolean;
  separator?: string;
  digits?: number;
  defaultValue?: string | number;
  fixedKoreaNumeral?: KoreaNumeral;
};

export const KOREA_NUMERAL = ['', '만', '억', '조', '경'] as const;
export type KoreaNumeral = typeof KOREA_NUMERAL[number];
const getKoreaNumeralIndex = (value: KoreaNumeral) =>
  KOREA_NUMERAL.indexOf(value);

/** 단위간 배수 10_000 */
const UNIT_MULTIPLE = 10_000;

type PLACE_VALUE_MAP_TYPE = { [key in KoreaNumeral]: number };

const PLACE_VALUE_MAP = KOREA_NUMERAL.reduce(
  (prev, cur, index) => ({
    ...prev,
    [cur]: Math.pow(UNIT_MULTIPLE, index),
  }),
  {} as PLACE_VALUE_MAP_TYPE
);

/**
 * 수사를 반환한다.
 * @example getKoreaNumeral(10_000) // 만
 * @example getKoreaNumeral(1_000_000) // 만
 * @example getKoreaNumeral(100_000_000) // 억
 */
export const getKoreaNumeral = (value: number): KoreaNumeral => {
  const digitCount = getIntegerLength(value) - 1;
  /** 제곱하기 위해 사용하는 지수. 10⁴ -> 4  */
  const exponent = log10(UNIT_MULTIPLE);

  // 단위의 인덱스 0:'', 1:'만', 2:'억'
  const unitIndex = Math.floor(digitCount / exponent);
  return KOREA_NUMERAL[unitIndex] || '';
};

/**
 * 마스킹 여부
 */
export const blindValue = <T = any>(value: T, blind?: boolean): T | string =>
  blind ? '***' : value;

/**
 * 콤마를 찍어서 반환한다.
 * @param value
 */
export const printComma = (value: number, digits?: number) => {
  return new Intl.NumberFormat('ko-KR', {
    minimumFractionDigits: digits ?? 0,
    maximumFractionDigits: digits ?? getFractionDigits(value),
  }).format(value);
};

/**
 * 수를 단위와 값으로 반환한다.
 */
export const toCurrency = (value: number, _option?: ToCurrencyOption) => {
  const defaultOption = { digits: 1 };
  const option = { ...defaultOption, ...(_option && _option) };
  const koreaNumeral = option.fixedKoreaNumeral ?? getKoreaNumeral(value);
  const placeValue = PLACE_VALUE_MAP[koreaNumeral] ?? 1;
  const shortValue = parseFloat((value / placeValue).toFixed(option.digits));

  return {
    shortValue,
    unit: koreaNumeral,
    unitIndex: KOREA_NUMERAL.indexOf(koreaNumeral),
    placeValue,
    originValue: value,
    realValue: placeValue * shortValue,
  };
};

/**
 * @deprecated
 */
export const numberToCurrency = (value: number, comma: boolean = true) => {
  const { shortValue } = toCurrency(value);
  return comma ? printComma(shortValue) : shortValue.toString();
};

/**
 * @deprecated
 */
export const getCurrencyUnit = (_value: number = 0, withWon = true) => {
  const koreaNumeral = getKoreaNumeral(_value);
  const unit = withWon ? '원' : '';
  return koreaNumeral + unit;
};

/**
 * API 응답의 가격이 만원 단위라 원 단위로 환산해주기 위함
 */
export const PRICE_FACTOR = 10000;

/**
 * @example priceFormatter(3_100_000_000) // 31.0억원
 */
export const priceFormatter = (price: number) =>
  currencyFormatter(price * PRICE_FACTOR);

export type CurrencyFormatterOption = FormatOption;

/**
 * @example currencyFormatter(10_000) // 1만원
 * @example currencyFormatter(19_000) // 2만원
 * @example currencyFormatter(100_000_000) // 1억원
 * @example currencyFormatter(180_000_000) // 1.8억원
 * @example currencyFormatter(10_000, { separator:' ' }) // 1 만원
 * @example currencyFormatter(10_000, { blind:true }) // *** 만원
 * @example currencyFormatter(10_000, { currencyUnit:false }) // 1 만
 * @example currencyFormatter(0, { defaultValue: '-' }) // -
 */
export const currencyFormatter = (
  value: number,
  option?: CurrencyFormatterOption
) => {
  const {
    shortValue,
    realValue,
    unit: koreaNumeral,
  } = toCurrency(value, {
    fixedKoreaNumeral: option?.fixedKoreaNumeral,
  });

  /** 1억 이상을 때 소수점 첫째자리까지 표기*/
  const defaultDigits = Math.abs(realValue) >= PLACE_VALUE_MAP['억'] ? 1 : 0;
  const digits = option?.digits ?? defaultDigits;

  const {
    separator = '',
    comma = true,
    currencyUnit = true,
    blind = false,
    defaultValue,
  } = option ?? {};

  let displayValue = comma
    ? printComma(shortValue, digits)
    : shortValue.toFixed(digits);

  displayValue = blindValue(displayValue, blind);

  const koreaCurrencyUnit = currencyUnit ? '원' : '';
  const displayUnit = koreaNumeral + koreaCurrencyUnit;
  const isEmpty = !shortValue && !koreaNumeral;
  const hasDefault = typeof defaultValue !== 'undefined';
  const shouldFallbackDefault = isEmpty && hasDefault;

  if (shouldFallbackDefault) {
    return defaultValue + '';
  }

  return [displayValue, displayUnit].join(separator);
};

interface FullCurrencyFormatterOptions {
  lastKoreaNumeral?: KoreaNumeral;
}

/**
 * @example fullCurrencyFormatter(36509000) // 3650만 9000원
 */
export const fullCurrencyFormatter = (
  price: number,
  options?: FullCurrencyFormatterOptions
) => {
  const currency = toCurrency(price);
  const unitIndex = currency.unitIndex;

  return (
    Array(unitIndex + 1)
      .fill(0)
      .map((item, index) => {
        const koreaNumeral = KOREA_NUMERAL[index];

        if (typeof options?.lastKoreaNumeral === 'string') {
          const allowKoreaNumeralIndex = getKoreaNumeralIndex(
            options.lastKoreaNumeral
          );
          if (index < allowKoreaNumeralIndex) {
            return '';
          }
        }

        const currency = toCurrency(price, {
          fixedKoreaNumeral: koreaNumeral,
          digits: 4,
        });

        const shortValue = Math.floor(currency.shortValue).toString().slice(-4);

        return `${shortValue}${koreaNumeral}`;
      })
      .reverse()
      .join(' ') + '원'
  );
};

export const formatToKoreanCurrency = (
  price: number,
  unit: boolean = true,
  thousandDivision?: boolean
) => {
  let koreaNumeral = unit;
  let division = thousandDivision ? 1000 : 1;

  if (!price) {
    return `0${koreaNumeral ? '천원' : ''}`;
  }

  // 천 단위로 나누기
  const thousandUnit = Math.round(price) / division;

  // 천 단위로 콤마 추가
  const formattedNumber = Math.round(thousandUnit).toLocaleString();

  // 결과 문자열 생성
  const result = `${formattedNumber}${koreaNumeral ? '천원' : ''}`;

  return result;
};
