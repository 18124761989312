import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/client';
import { useAppSelector } from 'hooks';

type searchMapType = 'ARTICLE' | 'PUBLIC_AUCTION' | 'COURT_AUCTION';

type notificationType = 'NOTICE_NOTIFICATION' | 'USER_NOTIFICATION';

interface LastTabSlice {
  searchMap: searchMapType | null;
  bookmarks: string | null;
  notifications: notificationType | null;
}

const initialState: LastTabSlice = {
  searchMap: null,
  bookmarks: null,
  notifications: null,
};

const lastTabSlice = createSlice({
  name: 'lastTab',
  initialState,
  reducers: {
    setSearchMapTab: (state, action) => {
      state.searchMap = action.payload;
    },
    setBookmarksTab: (state, action) => {
      state.bookmarks = action.payload;
    },
    setNotificationTab: (state, action) => {
      state.notifications = action.payload;
    },
    resetNotificationTab: state => {
      state.notifications = null;
    },
  },
});

export const {
  setSearchMapTab,
  setBookmarksTab,
  setNotificationTab,
  resetNotificationTab,
} = lastTabSlice.actions;

export const selectLastTab = (state: RootState) => state.lastTab;

export const useSelectLastTab = () => {
  return useAppSelector(selectLastTab);
};

export default lastTabSlice.reducer;
