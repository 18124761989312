import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import {
  FilterQuery,
  FilterParams,
  RangeInput,
  FilterType as ParcelFilterType,
} from 'apis/filter';
import { DEFAULT_FILTER_TYPES } from 'components/Filter/shared/constants';
import {
  FilterOptions,
  FilterType,
} from 'components/Filter/FilterDefaultOption';
import { toNumber } from './numberUtils';
import { Range } from 'components/Filter/FilterSlider';
import isNumber from 'lodash/isNumber';
import { toNumberFromQueryObect } from './urlUtil';
import { convertArea } from './areaUtil';

const buildRange = ([min, max]: Range): Range | null =>
  isNumber(min) || isNumber(max) ? [min, max] : null;

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

/**
 * 옵션 직렬화
 * @description  FilterOptions를 filterQuery로 전환
 */
const toFilterQuery = (options: FilterOptions): FilterQuery => {
  // 키가 있고 데이터가 없으면 null로 초기화해라.

  const initialization = (filterType: FilterType) =>
    options.hasOwnProperty(filterType) ? null : undefined;

  const [minDealPrice, maxDealPrice] = options[FilterType.DEAL_PRICE] || [
    initialization(FilterType.DEAL_PRICE),
    initialization(FilterType.DEAL_PRICE),
  ];
  const [minLotArea, maxLotArea] = options[FilterType.LOT_AREA] || [
    initialization(FilterType.LOT_AREA),
    initialization(FilterType.LOT_AREA),
  ];
  const [minAge, maxAge] = options[FilterType.BUILDING_AGE] || [
    initialization(FilterType.BUILDING_AGE),
    initialization(FilterType.BUILDING_AGE),
  ];
  const [minGfArea, maxGfArea] = options[FilterType.GROSS_FLOOR_AREA] || [
    initialization(FilterType.GROSS_FLOOR_AREA),
    initialization(FilterType.GROSS_FLOOR_AREA),
  ];
  const [minTimeToStation, maxTimeToStation] = options[
    FilterType.TIME_TO_STATION
  ] || [
    initialization(FilterType.TIME_TO_STATION),
    initialization(FilterType.TIME_TO_STATION),
  ];

  const registeredWithInDays =
    options[FilterType.REGISTRED_WITHIN_DAYS] ||
    initialization(FilterType.REGISTRED_WITHIN_DAYS);
  const ratings =
    options[FilterType.RATINGS] || initialization(FilterType.RATINGS);
  const type =
    options[FilterType.ARTICLE_TYPE] || initialization(FilterType.ARTICLE_TYPE);

  const filterQuery: FilterQuery = {
    maxDealPrice,
    minDealPrice,
    maxLotArea,
    minLotArea,
    maxAge,
    minAge,
    maxGfArea,
    minGfArea,
    maxTimeToStation,
    minTimeToStation,
    type,
    registeredWithInDays,
    ratings,
  };

  return filterQuery;
};

/**
 * 옵션 역직렬화
 * @description filterQuery를 FilterOptions로 전환
 */
const toFilterOptions = (filterQuery: FilterQuery): Partial<FilterOptions> => {
  const filterEntry = Object.entries(filterQuery).map(([filterType, value]) => [
    filterType,
    toNumber(value),
  ]);

  const {
    maxDealPrice,
    minDealPrice,
    maxLotArea,
    minLotArea,
    maxAge,
    minAge,
    maxGfArea,
    minGfArea,
    maxTimeToStation,
    minTimeToStation,
    registeredWithInDays,
    type,
    ratings,
  } = Object.fromEntries(filterEntry);

  return {
    [FilterType.DEAL_PRICE]: buildRange([minDealPrice, maxDealPrice]),
    [FilterType.LOT_AREA]: buildRange([minLotArea, maxLotArea]),
    [FilterType.BUILDING_AGE]: buildRange([minAge, maxAge]),
    [FilterType.GROSS_FLOOR_AREA]: buildRange([minGfArea, maxGfArea]),
    [FilterType.TIME_TO_STATION]: buildRange([
      minTimeToStation,
      maxTimeToStation,
    ]),
    [FilterType.ARTICLE_TYPE]: type || null,
    [FilterType.REGISTRED_WITHIN_DAYS]: registeredWithInDays || null,
    [FilterType.RATINGS]: ratings || null,
  };
};

export const filterQueryArr = [
  'maxDealPrice',
  'minDealPrice',
  'maxLotArea',
  'minLotArea',
  'maxAge',
  'minAge',
  'maxGfArea',
  'minGfArea',
  'maxTimeToStation',
  'minTimeToStation',
  'type',
  'registeredWithInDays',
  'ratings',
] as const;

export type FilterQueryType = typeof filterQueryArr[number];

const removeFilterOptionFrom = (search?: string) => {
  const queryString = search || window.location.search;
  const queryObj = qs.parse(queryString, { ignoreQueryPrefix: true });

  const queryEntry = Object.entries(queryObj as { [s: string]: string });
  const removedFilterQuery = queryEntry.filter(
    ([queryType]) => !filterQueryArr.includes(queryType as FilterQueryType)
  );

  return qs.stringify(Object.fromEntries(removedFilterQuery), {
    arrayFormat: 'brackets',
    encode: false,
    skipNulls: true,
  });
};

/**
 * api요청시
 * @version 3.0
 */
const convertToSquareMeter = (filterQuery: FilterQuery): FilterQuery => {
  const queryObj = toNumberFromQueryObect(filterQuery);

  const { maxLotArea, minLotArea, maxGfArea, minGfArea } = queryObj;

  return {
    ...filterQuery,
    maxLotArea: isNumber(maxLotArea)
      ? convertArea.pyeongToM2(maxLotArea)
      : null,
    minLotArea: isNumber(minLotArea)
      ? convertArea.pyeongToM2(minLotArea)
      : null,
    maxGfArea: isNumber(maxGfArea) ? convertArea.pyeongToM2(maxGfArea) : null,
    minGfArea: isNumber(minGfArea) ? convertArea.pyeongToM2(minGfArea) : null,
  };
};

type QueryObject = {
  [query: string]: any;
};

const isEmptyQuery = (query: QueryObject) => {
  const filterQuery = filterQueryArr.reduce((obj, key) => {
    if (query[key]) {
      obj[key] = query[key];
    }

    return obj;
  }, {} as QueryObject);

  return isEmpty(filterQuery);
};

const toFilterQueryWithFallback = (filterOptions: FilterOptions) => {
  const filterQuery = filterUtil.toFilterQuery(filterOptions);

  if (filterUtil.isEmptyQuery(filterQuery)) {
    // 필터 조건 없이 지도로 이동하면
    // `useArticleQuery`에 의해 의도되지 않은 필터로 덮어씌워지기 때문에
    // 최소한의 파라미터를 가지고 이동하기
    return { minDealPrice: 0 };
  }

  return filterQuery;
};

const toFilterQueryFromUrl = () => {
  const filterOptions = toFilterOptionsFromUrl();
  return toFilterQuery(filterOptions);
};

const toFilterOptionsFromUrl = () => {
  const search = window.location.search;
  const queryObj = qs.parse(search, { ignoreQueryPrefix: true });
  return toFilterOptions(queryObj);
};

/**
 * @version 3.0_Analytics
 * @description API 호출 전 파라미터를 보정하기 위한 함수
 * 요구사항:
 * - 음수인 경우 0으로 변환
 * - 최소 값이 최대 값보다 큰 경우 swap
 */

export const correctRangeInput = ({ min, max }: RangeInput): RangeInput => {
  let handledMin = min;
  let handledMax = max;

  if (min !== null) {
    handledMin = Math.max(0, min);
  }

  if (max !== null) {
    handledMax = Math.max(0, max);
  }

  if (min !== null && max !== null && min > max) {
    [handledMin, handledMax] = [handledMax, handledMin];
  }

  return {
    min: handledMin,
    max: handledMax,
  };
};

type DealPriceInput = {
  minDealPrice: number | null;
  maxDealPrice: number | null;
};

const correctDealPrice = ({ minDealPrice, maxDealPrice }: DealPriceInput) => {
  const { min, max } = correctRangeInput({
    min: minDealPrice,
    max: maxDealPrice,
  });

  return {
    minDealPrice: min,
    maxDealPrice: max,
  };
};

type LotAreaInput = {
  minLotArea: number | null;
  maxLotArea: number | null;
};

const correctLotArea = ({ minLotArea, maxLotArea }: LotAreaInput) => {
  const { min, max } = correctRangeInput({
    min: minLotArea,
    max: maxLotArea,
  });

  return {
    minLotArea: min,
    maxLotArea: max,
  };
};

type ExclusiveAreaInput = {
  minExclusiveArea: number | null;
  maxExclusiveArea: number | null;
};

const correctExclusiveArea = ({
  minExclusiveArea,
  maxExclusiveArea,
}: ExclusiveAreaInput) => {
  const { min, max } = correctRangeInput({
    min: minExclusiveArea,
    max: maxExclusiveArea,
  });

  return {
    minExclusiveArea: min,
    maxExclusiveArea: max,
  };
};

type GeneralRangeInput = {
  minValue: number | null;
  maxValue: number | null;
  minName: string;
  maxName: string;
};

// min, max의 순서를 맞춰주는 함수
const correctRange = ({
  minValue,
  maxValue,
  minName,
  maxName,
}: GeneralRangeInput) => {
  const { min, max } = correctRangeInput({
    min: minValue,
    max: maxValue,
  });

  return {
    [minName]: min,
    [maxName]: max,
  };
};

type PeriodInput = {
  start: string | null;
  end: string | null;
};
// end 날짜가 start 날짜보다 앞서 있을때, 순서를 맞춰주는 함수
const correctBiddingPeriod = ({ start, end }: PeriodInput) => {
  if (start && end) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    return startDate < endDate
      ? {
          startBiddingPeriod: start,
          endBiddingPeriod: end,
        }
      : {
          startBiddingPeriod: end,
          endBiddingPeriod: start,
        };
  }
  return {
    startBiddingPeriod: start,
    endBiddingPeriod: end,
  };
};

const correctFilter = (filter: FilterParams): FilterParams => {
  const {
    mapArticle,
    nearby,
    newConstruction,
    interestedArticle,
    dealCase,
    courtAuction,
    publicAuction,
  } = filter;

  return {
    mapArticle: {
      ...mapArticle,
      ...correctDealPrice({
        minDealPrice: mapArticle.minDealPrice,
        maxDealPrice: mapArticle.maxDealPrice,
      }),
      ...correctLotArea({
        minLotArea: mapArticle.minLotArea,
        maxLotArea: mapArticle.maxLotArea,
      }),
    },
    nearby: {
      ...nearby,
      ...correctLotArea({
        minLotArea: nearby.minLotArea,
        maxLotArea: nearby.maxLotArea,
      }),
    },
    newConstruction: {
      ...newConstruction,
      ...correctLotArea({
        minLotArea: newConstruction.minLotArea,
        maxLotArea: newConstruction.maxLotArea,
      }),
    },
    interestedArticle: {
      ...interestedArticle,
      ...correctDealPrice({
        minDealPrice: interestedArticle.minDealPrice,
        maxDealPrice: interestedArticle.maxDealPrice,
      }),
      ...correctLotArea({
        minLotArea: interestedArticle.minLotArea,
        maxLotArea: interestedArticle.maxLotArea,
      }),
    },
    dealCase: {
      ...dealCase,
    },
    courtAuction: {
      ...courtAuction,
      ...correctRange({
        minValue: courtAuction.minAppraisedValue,
        maxValue: courtAuction.maxAppraisedValue,
        minName: 'minAppraisedValue',
        maxName: 'maxAppraisedValue',
      }),
      ...correctRange({
        minValue: courtAuction.minLowestBiddingPrice,
        maxValue: courtAuction.maxLowestBiddingPrice,
        minName: 'minLowestBiddingPrice',
        maxName: 'maxLowestBiddingPrice',
      }),
      ...correctRange({
        minValue: courtAuction.minFailedBiddingCount,
        maxValue: courtAuction.maxFailedBiddingCount,
        minName: 'minFailedBiddingCount',
        maxName: 'maxFailedBiddingCount',
      }),
      ...correctBiddingPeriod({
        start: courtAuction.startBiddingPeriod,
        end: courtAuction.endBiddingPeriod,
      }),
    },
    publicAuction: {
      ...publicAuction,
      ...correctRange({
        minValue: publicAuction.minAppraisedValue,
        maxValue: publicAuction.maxAppraisedValue,
        minName: 'minAppraisedValue',
        maxName: 'maxAppraisedValue',
      }),
      ...correctRange({
        minValue: publicAuction.minLowestBiddingPrice,
        maxValue: publicAuction.maxLowestBiddingPrice,
        minName: 'minLowestBiddingPrice',
        maxName: 'maxLowestBiddingPrice',
      }),
      ...correctRange({
        minValue: publicAuction.minFailedBiddingCount,
        maxValue: publicAuction.maxFailedBiddingCount,
        minName: 'minFailedBiddingCount',
        maxName: 'maxFailedBiddingCount',
      }),
      ...correctBiddingPeriod({
        start: publicAuction.startBiddingPeriod,
        end: publicAuction.endBiddingPeriod,
      }),
    },
  };
};

/**
 * 설정된 필터 값이 하나 이상인지 판단하기 위함
 *
 * @version 3.0_Analytics
 */
const isFilterActive = (
  filter: FilterParams | undefined,
  visibleTypes: ParcelFilterType[] = DEFAULT_FILTER_TYPES
) => {
  if (!filter) {
    return false;
  }

  const values = visibleTypes
    .map(filterType => Object.values(filter[filterType] || {}))
    .flat(1);
  const isEmptyArray = (v: any) => Array.isArray(v) && v.length === 0;

  return values.some(value => value !== null && !isEmptyArray(value));
};

const filterUtil = {
  queryKeys: filterQueryArr,
  toFilterQuery,
  toFilterOptions,
  toFilterQueryFromUrl,
  toFilterOptionsFromUrl,
  removeFilterOptionFrom,
  convertToSquareMeter,
  isEmptyQuery,
  toFilterQueryWithFallback,
  correctFilter,
  correctLotArea,
  correctExclusiveArea,
  isFilterActive,
};

export default filterUtil;
