import { includesKorean } from 'utils/lang';
import {
  AxiosError as _AxiosError,
  AxiosRequestConfig as _AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

type MessageMap = {
  [code: number]: string | undefined;
};

type AxiosError = Omit<_AxiosError, 'code'>;

type AxiosRequestConfig = _AxiosRequestConfig & {
  _retry?: boolean; // 재요청 구분 플래그
};

/**
 * 에러코드 정규화를 위한 커스텀 에러
 * @see https://github.com/axios/axios/blob/master/lib/core/enhanceError.js
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#custom_error_types
 */
class ServerResponseError extends Error {
  code: number;
  config?: AxiosRequestConfig;
  status?: number;
  response?: AxiosResponse;
  toJSON: () => object;
  /**  정상응답 */
  static SUCCESS = 0 as const;
  /**  로그인/비밀번호 변경 등 이메일 혹은 비밀번호가 올바르지 않은 경우 */
  static NOT_FOUND_USER = 1001 as const;
  /** 비밀번호 찾기에서 없는 이메일을 입력한 경우*/
  static NOT_FOUND_EMAIL = 1002 as const;
  /**  회원 가입 시 이미 가입된 이메일로 가입하려는 경우 */
  static DUPLICATED_EMAIL = 1003 as const;
  /**  토큰 refresh 요청 시 token 정보를 보내지 않는 경우 */
  static EMPTY_TOKEN = 1004 as const;
  /**  이메일 인증, 비밀번호 재설정 시 24시간 이후에 처리하는 경우 */
  static EXPIRED_TIME = 1005 as const;
  /**  회원 가입 후 이메일 인증 없이 로그인 시도하는 경우 */
  static UNAUTHENTICATED = 1006 as const;
  /**  만료된 토큰을 사용하여 API 요청하는 경우 */
  static EXPIRED_TOKEN = 1007 as const;
  /**  소셜 가입 시 사용자가 약관 동의 거부한 경우 (Cancel) */
  static DISAGREE_TERMS = 1008 as const;
  /**  전화번호 설정 시 (회원가입 및 사용자 정보 수정) 전화번호가 인증정보와 일치하지 않은 경우 */
  static INVALID_PHONE_NUMBER = 1009 as const;
  /**  전화번호 설정 시 (회원가입 및 사용자 정보 수정) 인증번호가 유효하지 않은 경우 */
  static INVALID_CERTIFICATION_NUMBER = 1010 as const;

  /**  알려지지 않은 에러 */
  static UNKNOWN_ERROR = -1 as const;
  static NOTI_MESSAGE: MessageMap = {
    [ServerResponseError.SUCCESS]: undefined,
    [ServerResponseError.NOT_FOUND_USER]: '입력값이 올바르지 않습니다.',
    [ServerResponseError.NOT_FOUND_EMAIL]: '규칙에 맞지 않은 비밀번호 입니다.',
    [ServerResponseError.DUPLICATED_EMAIL]: '이미 가입된 이메일입니다',
    [ServerResponseError.EMPTY_TOKEN]: undefined,
    [ServerResponseError.EXPIRED_TIME]: '시간이 만료되었습니다.',
    [ServerResponseError.UNAUTHENTICATED]: undefined,
    [ServerResponseError.EXPIRED_TOKEN]: undefined,
    [ServerResponseError.DISAGREE_TERMS]: undefined,
  };

  constructor(message: string, originError: AxiosError) {
    super(message);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerResponseError);
    }

    const { config, response, toJSON } = originError || {};

    this.name = 'ServerResponseError';
    this.config = config;
    this.response = response;
    this.status = response?.status;
    this.toJSON = toJSON;
    this.code =
      (
        response?.data as {
          code: number;
        }
      )?.code || ServerResponseError.UNKNOWN_ERROR;

    const customMessage = includesKorean(message)
      ? message
      : ServerResponseError.NOTI_MESSAGE[this.code];

    this.message = customMessage || this.message;
  }
}

/**
 * 500번대 서버 오류
 */
export const isServerError = (error: unknown): error is ServerResponseError =>
  error instanceof ServerResponseError && (error.status || 0) >= 500;

/**
 * 400번대 클라이언트 오류
 */
export const isClientError = (error: unknown): error is ServerResponseError => {
  if (error instanceof ServerResponseError) {
    const status = error.status || 0;
    return status >= 400 && status < 500;
  }
  return false;
};

export const isNetworkError = (e: ServerResponseError) =>
  e.message === 'Network Error';
export const isTimeoutError = (e: ServerResponseError) =>
  e.message.startsWith('timeout');

export default ServerResponseError;
