import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/client';

import type {
  BookmarkMarker,
  MarkerTypeDesktop,
} from 'components/ParcelSearchMap/shared';
import { useAppSelector } from 'hooks';

export type IsEnabledMarkerDesktop = {
  [key in BookmarkMarker | MarkerTypeDesktop]: boolean;
};

export type MapLayer = 'distance' | 'area' | 'radius' | 'roadview' | 'preview';

export type MapInteraction = {
  zoomLevel: number;
  /** 목록 내 커서가 올려진 항목의 PNU */
  hoveredPnu: string | null;
  northEast: string;
  southWest: string;
  /** 목록 API 조회 활성화 여부 */
  isEnabled: boolean;
  /** 지도 마커 사용가능 여부 */
  isEnabledMarkerDesktop: IsEnabledMarkerDesktop;
  /** 현재 활성화된 마커 종류 */
  activeMarkerTypeDesktop: MarkerTypeDesktop;
  /** 매물 서비스 지역 여부 */
  isInArticleServiceArea: boolean;
  /** 경매 서비스 지역 여부 */
  isInAuctionServiceArea: boolean;

  /** 활성화된 지도 레이어 */
  activeMapLayer: MapLayer | null;

  /**
   * 프리미엄 분석 가능 필지 오버레이
   */
  premiumOverlay: boolean;
  landFilterOverlay: boolean;
  mapCenterZommLevel: null | number;
};

const initialState: MapInteraction = {
  zoomLevel: 0,
  hoveredPnu: null,
  northEast: '',
  southWest: '',
  isEnabled: false,
  isEnabledMarkerDesktop: {
    bookmark: false,
    land: false,
    newConstruction: false,
    article: false,
    rent: false,
    deal: false,
    courtAuction: true,
    publicAuction: true,
  },
  activeMarkerTypeDesktop: 'land',
  isInArticleServiceArea: false,
  isInAuctionServiceArea: true,
  activeMapLayer: null,
  /** AI건축분석 가능영역 오버레이 */
  premiumOverlay: false,
  landFilterOverlay: false,
  /** 포커스 필지로 이동 토글 */
  mapCenterZommLevel: null,
};

const mapInteractionSlice = createSlice({
  name: 'mapInteractionSlice',
  initialState,
  reducers: {
    setHoveredPnu: (
      state,
      action: PayloadAction<Pick<MapInteraction, 'hoveredPnu'>>
    ) => {
      state.hoveredPnu = action.payload.hoveredPnu;
    },
    setCoords: (
      state,
      action: PayloadAction<
        Pick<MapInteraction, 'northEast' | 'southWest' | 'isEnabled'>
      >
    ) => {
      state.northEast = action.payload.northEast;
      state.southWest = action.payload.southWest;
      state.isEnabled = action.payload.isEnabled;
    },
    setIsEnabledMarker: (
      state,
      action: PayloadAction<Partial<IsEnabledMarkerDesktop>>
    ) => {
      state.isEnabledMarkerDesktop = {
        ...state.isEnabledMarkerDesktop,
        ...action.payload,
      };
    },
    setActiveMarkerType: (state, action: PayloadAction<MarkerTypeDesktop>) => {
      state.activeMarkerTypeDesktop = action.payload;
    },
    setIsInArticleServiceArea: (state, action: PayloadAction<boolean>) => {
      state.isInArticleServiceArea = action.payload;
    },
    setIsInAuctionServiceArea: (state, action: PayloadAction<boolean>) => {
      state.isInAuctionServiceArea = action.payload;
    },
    setActiveMapLayer: (state, action: PayloadAction<MapLayer | null>) => {
      state.activeMapLayer = action.payload;
    },
    setPremiumOverlay: (state, action: PayloadAction<boolean>) => {
      state.premiumOverlay = action.payload;
    },
    setLandFilterOverlay: (state, action: PayloadAction<boolean>) => {
      state.landFilterOverlay = action.payload;
    },
    setZoomLevel: (state, action: PayloadAction<number>) => {
      state.zoomLevel = action.payload;
    },
    setMapCenterZommLevel: (state, action: PayloadAction<null | number>) => {
      state.mapCenterZommLevel = action.payload;
    },
  },
});

export const {
  setHoveredPnu,
  setCoords,
  setIsEnabledMarker,
  setActiveMarkerType,
  setIsInArticleServiceArea,
  setActiveMapLayer,
  setPremiumOverlay,
  setLandFilterOverlay,
  setZoomLevel,
  setIsInAuctionServiceArea,
  setMapCenterZommLevel,
} = mapInteractionSlice.actions;

export const selectMapInteraction = (state: RootState) => state.mapInteraction;

export const useMapInteraction = () => {
  return useAppSelector(selectMapInteraction);
};

export default mapInteractionSlice.reducer;
