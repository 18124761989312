import { TimeArray } from 'utils/dateUtil';
import v2RestClient from './clients/v2RestClient';
import { Pagination, PagingParams } from './clients/v2RestClient/type';
import { AxiosRequestConfig } from 'axios';

export type NotificationType =
  | 'notice'
  | 'new_article'
  | 'analyze'
  | 'analyze_done'
  | 'analyze_failed'
  | 'event'
  | 'update'
  | 'data';

export type Notification = {
  id: number;
  type: NotificationType;
  title: string;
  contents: string;
  link: string;
  isOpened: boolean;
  createdAt: TimeArray;
  updatedAt: TimeArray;
  deleted: boolean;
  meta: {
    address: string;
    pnu: string;
  };
  articles: { pnu: string; link: string; contents: string }[];
};

export type NotificationSummary = {
  type: NotificationType;
  count: number;
};

export type Notifications = Pagination<Notification> & {
  summary?: NotificationSummary[];
};

export const defaultParams = {
  page: 0,
  size: 10,
};

export const defaultNotifications: Notifications = {
  totalCount: 0,
  currentPage: 0,
  pageSize: 10,
  items: [],
};

export type NotificationFilterParams = {
  summary?: boolean;
  type?: NotificationType;
};
export type NotificationsRequestParams = PagingParams &
  NotificationFilterParams;

const fetchNotifications = async (params?: NotificationsRequestParams) => {
  try {
    const response = await v2RestClient.get<Notifications>(
      `/users/me/notifications`,
      {
        params: params || defaultParams,
      }
    );
    return response.data;
  } catch (error) {
    return defaultNotifications;
  }
};

const fetchNotificationById = async (id: number) => {
  const response = await v2RestClient.get<Notification>(
    `/users/me/notifications/${id}`
  );

  return response.data;
};

export interface PushNotification {
  id: number;
  title: string;
  link: string;
  contents: string;
  pushedAt: string;
  createdAt: string;
  updatedAt: string;
}

const fetchPushNotificationById = async (pushNotificationId: number) =>
  v2RestClient.get<PushNotification>(
    `/push_notifications/${pushNotificationId}`
  );

type UpdateParams = {
  id?: string;
};

const updateNotifications = async (notificationIds: number[]) => {
  const params: UpdateParams = {};

  if (notificationIds.length > 0) {
    params.id = notificationIds.join(',');
  }

  const response = await v2RestClient.put<Notifications>(
    `/users/me/notifications`,
    null,
    { params }
  );

  return response.data;
};

const fetchNoticeNotifications = async (
  params?: NotificationsRequestParams
) => {
  try {
    const response = await v2RestClient.get<Notifications>(`/notices`, {
      params: params || defaultParams,
    });
    return response.data;
  } catch (error) {
    return defaultNotifications;
  }
};

export type NotificationMembershipInfo = {
  id: number;
  userId: number;
  send?: boolean;
  createdAt: number[];
  updatedAt: number[];
};

/** 멤버십 결제 3일 전 해지 알림 조회 */
const fetchNotificationMembership = async (config?: AxiosRequestConfig) => {
  const response = await v2RestClient.get<NotificationMembershipInfo | ''>(
    `/users/me/notifications/membership`,
    config
  );
  return response.data || '';
};
/** 멤버십 결제 3일 전 해지 알림 신청 */
const sendNotificationMembership = async (config?: AxiosRequestConfig) => {
  const response = await v2RestClient.post(
    `/users/me/notifications/membership`,
    config
  );
  return response.data as NotificationMembershipInfo;
};
/** 멤버십 결제 3일 전 해지 알림 해지 */
const deleteNotificationMembership = async (config?: AxiosRequestConfig) => {
  const response = await v2RestClient.delete<void>(
    `/users/me/notifications/membership`,
    config
  );
  return response.data;
};

const notificationApis = {
  fetchPushNotificationById,
  fetchNotifications,
  fetchNotificationById,
  updateNotifications,
  fetchNoticeNotifications,
  fetchNotificationMembership,
  sendNotificationMembership,
  deleteNotificationMembership,
};

export default notificationApis;
