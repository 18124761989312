import Cookies from 'js-cookie';

import { convertibleToNumber } from './numberUtils';

import { MAX_ZOOM_LEVEL, MIN_ZOOM_LEVEL } from 'constants/map';
import storageUtil from './storageUtil';

export const isAvailableCoords = (latitude: number, longitude: number) => {
  // 대한민국 좌표 범위
  // ref: https://ko.wikipedia.org/wiki/%ED%8B%80:%EC%9C%84%EC%B9%98_%EC%A7%80%EB%8F%84_%EB%8C%80%ED%95%9C%EB%AF%BC%EA%B5%AD
  const latitudeRange = { min: 33, max: 38.9 };
  const longitudeRange = { min: 124.5, max: 132 };
  const isLatitudeInRange =
    latitude >= latitudeRange.min && latitude <= latitudeRange.max;
  const isLongitudeInRange =
    longitude >= longitudeRange.min && longitude <= longitudeRange.max;

  return isLatitudeInRange && isLongitudeInRange;
};

export const setLastLocation = (latitude: number, longitude: number) => {
  if (isAvailableCoords(latitude, longitude)) {
    Cookies.set('lastLocation', `${latitude}|${longitude}`);
  }
};

export const getLastLocation = () => {
  const [latitude, longitude] =
    Cookies.get('lastLocation')?.split('|').map(parseFloat) || [];

  if (isAvailableCoords(latitude, longitude)) {
    return [latitude, longitude];
  }

  return [37.48628139, 126.91802553]; // 서울 관악구 신림동 479-34
};

export type MapLatLng = {
  latitude: number;
  longitude: number;
};

export type MapBounds = {
  sw: MapLatLng;
  ne: MapLatLng;
  toString(): string;
};

export const toMapLatLng = (latLng: naver.maps.Coord): MapLatLng => ({
  latitude: latLng.y,
  longitude: latLng.x,
});

export const toMapLatLngFromBounds = ({ sw, ne }: MapBounds): MapLatLng => ({
  latitude: (sw.latitude + ne.latitude) / 2,
  longitude: (sw.longitude + ne.longitude) / 2,
});

export const isEqualLatLng = (
  a: MapLatLng | naver.maps.Coord,
  b: MapLatLng | naver.maps.Coord
) => {
  const latLngA = 'equals' in a ? toMapLatLng(a) : a;
  const latLngB = 'equals' in b ? toMapLatLng(b) : b;

  return (
    latLngA.latitude === latLngB.latitude &&
    latLngA.longitude === latLngB.longitude
  );
};

export const toMapBounds = (bounds: naver.maps.LatLngBounds): MapBounds => ({
  sw: toMapLatLng(bounds.getSW()),
  ne: toMapLatLng(bounds.getNE()),
  toString: () => bounds.toString(),
});

export const toCoords = (bounds?: MapBounds) => {
  if (!bounds) {
    return {
      northEast: '',
      southWest: '',
    };
  }

  return {
    northEast: `${bounds.ne.latitude},${bounds.ne.longitude}`,
    southWest: `${bounds.sw.latitude},${bounds.sw.longitude}`,
  };
};

/** 37.484516966337964,126.9301702701601 와 같은 위경도 문자열 여부 체크 */
export const isLatLngString = (value: string = '') => {
  const latLngArr = value.split(',');
  return latLngArr.length === 2 && latLngArr.every(convertibleToNumber);
};

type Latitude = number;
type Longitude = number;

export type Coordinate = [Longitude, Latitude];

// parcel API 응답은 longitude, latitude 순으로 구성되어 있음
export type Coordinates = Coordinate[][];

export interface PolygonGeometry {
  type: 'Polygon';
  coordinates: Coordinates;
}
export interface MultiPolygonGeometry {
  type: 'MultiPolygon';
  coordinates: Coordinates[];
}

export type Geometry = PolygonGeometry | MultiPolygonGeometry;

const toNaverPoint = (coords: [Longitude, Latitude][][]) =>
  coords
    .flat(1)
    .map(([longitude, latitude]) => new naver.maps.LatLng(latitude, longitude));

export const toPath = (geometry: Geometry) => {
  const { type, coordinates } = geometry;

  if (type === 'MultiPolygon') {
    let temp: Coordinates = [];
    coordinates.forEach(item => (temp = temp.concat(item)));

    return toNaverPoint(temp);
  }

  return toNaverPoint(coordinates as Coordinates);
};

export type RecentMapRecord = {
  latLng: MapLatLng;
  zoomLevel: number;
};

const DEFAULT_MAP_RECORD: RecentMapRecord = {
  latLng: {
    latitude: 37.48628139,
    longitude: 126.91802553,
  },
  zoomLevel: 18,
};

/**
 * 지도의 최근 상태를 기록한다.
 */
export const mapRecorder = {
  save: (record: RecentMapRecord) => {
    storageUtil.set('recentMapRecord', record);
  },
  restore: (): RecentMapRecord => {
    return storageUtil.get('recentMapRecord', DEFAULT_MAP_RECORD);
  },
};

export const isValidZoomLevel = (zoomLevel: number) =>
  zoomLevel <= MIN_ZOOM_LEVEL && zoomLevel >= MAX_ZOOM_LEVEL;
