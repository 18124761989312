// import { isFeatureEnabled } from 'constants/featureFlags';

import { buildQueryString } from 'utils/urlUtil';
import { COMMON_APP_ROUTES } from './routes';

/**
 * AI 건축분석 최대 연산 시간
 */
export const MAX_PREMIUM_CALCULATION_TIME = 120;

/** AI 건축분석이 지원되는 지역 */
export const PREMIUM_COVERAGE_REGIONS =
  '서울, 경기, 인천, 부산, 대구, 광주, 대전, 울산, 제주';
export const PREMIUM_COVERAGE_REGIONS_DESCRIPTION = '수도권, 5대 광역시, 제주';

// export const PREMIUM_COVERAGE_REGIONS = isFeatureEnabled( // TODO: 관련코드 삭제필요
//   'premiumCoverageIncheon'
// )
//   ? '서울, 인천'
//   : '서울';

/**
 * AI 건축분석 샘플페이지 pnu
 */
export const SAMPLE_PNU = '1144012300103860001';
export const SAMPLE_UNIQUE_CODE = '768fe896d20c42db88c4ffe100589f98';

/**
 * /analytics/premium/sample?pnus[]=1144012300103860001
 */
export const SAMPLE_ANALYTICS_URL = [
  COMMON_APP_ROUTES.ANALYTICS_PREMIUM_SAMPLE,
  `pnus[]=${SAMPLE_PNU}`,
].join('?');

export const SAMPLE_LIGHT_ANALYTICS_URL = [
  COMMON_APP_ROUTES.ANALYTICS_LIGHT_SAMPLE,
  `pnus[]=${SAMPLE_PNU}`,
].join('?');
/**
 *
 * @return /analytics/premium/sample/scenario/768fe896d20c42db88c4ffe100589f98?pnus[]=1144012300103860001
 */
export const buildAnalyticsDetailUrl = (params: {
  pnus: string[];
  uniqueCode: string;
  isSample?: boolean;
  premiumBuildRequestLogId?: string;
}) => {
  const { pnus, uniqueCode, isSample, premiumBuildRequestLogId } = params;

  const baseUrl = isSample
    ? COMMON_APP_ROUTES.ANALYTICS_PREMIUM_SAMPLE_DETAILE
    : COMMON_APP_ROUTES.ANALYTICS_PREMIUM_SCENARIO;

  const queryString = buildQueryString({ pnus, premiumBuildRequestLogId });
  const fullUrl = [baseUrl, uniqueCode].join('/');

  return [fullUrl, queryString].join('?');
};

export const buildLightAnalyticsDetailUrl = (params: {
  pnus: string[];
  uniqueCode: string;
  isSample?: boolean;
  lightBuildRequestLogId?: string;
}) => {
  const { pnus, uniqueCode, isSample, lightBuildRequestLogId } = params;

  const baseUrl = isSample
    ? COMMON_APP_ROUTES.ANALYTICS_LIGHT_SAMPLE_DETAILE
    : COMMON_APP_ROUTES.ANALYTICS_LIGHT_SCENARIO;

  const queryString = buildQueryString({ pnus, lightBuildRequestLogId });
  const fullUrl = [baseUrl, uniqueCode].join('/');

  return [fullUrl, queryString].join('?');
};
