import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'raf/polyfill';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { store } from './store/client';
import gtm from 'utils/gtm';
import hj from 'utils/hotjar';
import sentry from 'utils/sentry';
import { isMobileDevice } from 'utils/environment';
import retryLazy from 'utils/retryLazy';
import { getSessionStorage } from 'utils/storageUtil';

const NextApp = retryLazy(() => import('./NextApp'));
const NextAppDesktop = retryLazy(() => import('./NextAppDesktop'));

if (process.env.NODE_ENV === 'production') {
  gtm.initialize();
  hj.initialize();
  sentry.initialize();
}
// msw 사용시 주석 해제
// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser');
//   worker.start();
// }

getSessionStorage();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback="">
        {isMobileDevice ? <NextApp /> : <NextAppDesktop />}
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister(); // msw 사용시 주석처리
