import { toFixedNumber } from './numberUtils';

const KOREA_AREA_COEFFICIENT = 400 / 121.0;

export const numberToKoreanArea = (value: number, digit: number = 1) => {
  if (value === 0) {
    return 0;
  }

  return toFixedNumber(value / KOREA_AREA_COEFFICIENT, digit);
};

export const numberToKoreanPrice = (value: number, digit: number = 1) => {
  if (value === 0) {
    return 0;
  }

  return parseFloat((value * KOREA_AREA_COEFFICIENT).toFixed(digit));
};

export const convertPrice = {
  /**
   * convert price/m² -> price/평
   * @example convertPrice.m2ToPyeong(1) // 3.3
   */
  m2ToPyeong(price: number, digits: number = 3) {
    return toFixedNumber(price * KOREA_AREA_COEFFICIENT, digits);
  },

  /**
   * convert price/평 -> price/m²
   * @example convertPrice.pyeongToM2(3.3) // 1
   */
  pyeongToM2(price: number, digits: number = 3) {
    return toFixedNumber(price / KOREA_AREA_COEFFICIENT, digits);
  },
};

export const convertArea = {
  /**
   * 제곱미터를 평으로 변환
   * @param area 평 면적
   * @example m2ToPyeong(1) // 3.3
   */
  m2ToPyeong(area: number) {
    return Number((area / KOREA_AREA_COEFFICIENT).toFixed(1));
  },

  /**
   * 제곱미터(m2)에서 평으로 단위를 환산한다.
   * @example pyeongToM2(3.3) // 0.99825
   */
  pyeongToM2(area: number) {
    return Number((area * KOREA_AREA_COEFFICIENT).toFixed(1));
  },
};

/**
 * convert price/평 -> price/m²
 * @example numberToM2Price(3.3) // 1
 */
export const numberToM2Price = (value: number = 0, digit: number = 1) => {
  if (!value) {
    return value;
  }

  return parseFloat((value / KOREA_AREA_COEFFICIENT).toFixed(digit));
};
