import { AxiosResponse } from 'axios';
import debounce from 'lodash/debounce';

type Args = any[];

type AxiosRequest<T> = (...args: Args) => Promise<AxiosResponse<T>>;

type Options = {
  /** 중복 호출이 발생하지 않는 것으로 판단될 때까지의 대기 시간 */
  waitMs?: number;
};

type CacheItem = {
  promise: Promise<AxiosResponse>;
  invalidate: () => void;
} | null;

type Cache = {
  [key: string]: CacheItem;
};

const cache: Cache = {};

const makeCacheKey = (args: Args): string => {
  return args
    .map(arg => {
      if (typeof arg === 'object') {
        return JSON.stringify(arg);
      }

      if (typeof arg === 'function') {
        return arg.name || 'anonymous';
      }

      return arg;
    })
    .join();
};

/**
 * 전달된 문자열을 기준으로 hash code 생성
 * ref: https://stackoverflow.com/a/7616484
 */
const makeHashCode = (str: string = '') =>
  str
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    );

/**
 * @deprecated react-query를 통한 중복요청 제거를 권장합니다.
 * 짧은 시간 내 중복된 API 요청 발생 시 1회만 호출되도록 보장해주는 유틸 함수
 * @param request Promise를 반환하는 axios 호출 함수
 * @param options 대기 시간 등을 설정하는 옵션 객체
 */
const dedupe = <T>(request: AxiosRequest<T>, options: Options = {}) => {
  // production 빌드에서는 `request.name`으로 식별할 수 없어
  // function string을 기반으로 request 식별 키를 생성함
  const requestHash = makeHashCode(request.toString());

  return async (...args: Args) => {
    const { waitMs = 500 } = options;
    const key = makeCacheKey([requestHash, ...args]);

    if (cache[key]) {
      const response = await cache[key]?.promise;
      cache[key]?.invalidate();

      return response as AxiosResponse<T>;
    }

    cache[key] = {
      promise: request(...args),
      invalidate: debounce(() => {
        cache[key] = null;
        delete cache[key];
      }, waitMs),
    };

    const response = await cache[key]?.promise;
    cache[key]?.invalidate();

    return response as AxiosResponse<T>;
  };
};

export default dedupe;
