import { AxiosRequestConfig } from 'axios';
import v2RestClient, * as v2RestClientMethods from './clients/v2RestClient';
import { Provider } from './user';

export type SignUpPayload = {
  email: string;
  password: string;
  passwordConfirmation: string;
  name: string;
  phoneNumber: string;
  birth: string;
  /** 본인인증 UID */
  impUid: string;
  /** 필수 약관 동의 여부 */
  requiredTermsAgreement: boolean;
  /** 마케팅 동의 여부 */
  marketingAgreement: boolean;
  /** 개인정보처리방침 동의 여부 */
  personalDataProcessAgreement: boolean;
  /** 제 3자 정보 제공 동의 여부 */
  disclosureOfInformationToThirdPartiesAgreement: boolean;

  /** 직업 */
  position?: string;
  /** 토지 매매 경험 여부  */
  hasEverDeal?: boolean;
  /** 신축 투자경험 유무 여부 */
  hasEverNewConstruction?: boolean;
  /** 가입목적 */
  purposeOfFunnels?: string[];
  /** 유입경로 */
  funnels?: string;
  /** 토지주 여부 */
  landOwner: boolean;
  /** 토지 주소 */
  landAddress?: string;
};

export type SignInPayload = {
  email: string;
  password: string;
};

export type TokenInfo = {
  token: string;
};

export type ResetPasswordPayload = {
  previousPassword: string;
  password: string;
  passwordConfirmation: string;
};

export type ServerResponse = {
  code: string;
  msg: string;
};

const signUp = (payload: SignUpPayload) => {
  return v2RestClient.post<TokenInfo | ServerResponse>(`/auth/signup`, payload);
};

const signIn = (payload: SignInPayload) => {
  return v2RestClient.post<TokenInfo | ServerResponse>(`/auth/signin`, payload);
};

const signOut = () => {
  return v2RestClient.post<ServerResponse>(`/auth/signout`);
};

const deleteAccount = (reason?: string) => {
  return v2RestClient.delete<ServerResponse>(`/auth/users/me`, {
    params: {
      reason,
    },
  });
};

const refreshToken = (config?: AxiosRequestConfig) => {
  return v2RestClient.put<TokenInfo | ServerResponse>(
    `/auth/refresh`,
    null,
    config
  );
};

const checkEmail = (email: string) => {
  return v2RestClient.get(`/auth/check`, {
    params: { email: encodeURIComponent(email) },
  });
};

const resetPassword = (payload: ResetPasswordPayload) =>
  v2RestClient.put<ServerResponse>(`/auth/password`, payload);

const resetPasswordWithToken = (
  payload: Omit<ResetPasswordPayload, 'previousPassword'>,
  token: string
) =>
  v2RestClient.put<ServerResponse>(`/auth/password`, payload, {
    params: {
      token,
    },
  });

const findPassword = (email: string) =>
  v2RestClient.post<ServerResponse>(`/auth/password`, {
    email,
  });

const setAuthToken = (token: string) => {
  v2RestClientMethods.updateDefaultHeader('Authorization', `Bearer ${token}`);
};

const clearAuthToken = () => {
  v2RestClientMethods.deleteDefaultHeader('Authorization');
};

const resendConfirmMail = (email: string) =>
  v2RestClient.post<ServerResponse>(`/auth/verify/email/send`, {
    email,
  });

/**
 * @see https://docs.iamport.kr/tech/mobile-authentication
 */
export type Certification = {
  birth: string;
  birthday: string;
  /** carrier */
  certified: string;
  certified_at: number;
  foreigner: boolean;
  foreigner_v2: boolean | null;
  gender: null;
  imp_uid: string;
  merchant_uid: string;
  name: string;
  origin: string;
  pg_provider: string;
  pg_tid: string;
  phone: string;
  /** DI 값과 동일. 상점아이디(사이트)별로 할당되는 식별키 */
  unique_in_site: string | null;
  /** CI 값과 동일. 온라인 주민번호와 같은 개인고유식별키 */
  unique_key: string;
};

/**
 * 본인인증 정보 조회
 */
const getCertification = (imgUid: string) =>
  v2RestClient.get<Certification>(`/auth/certifications/${imgUid}`);

export type CheckExistingAccountParam = {
  phoneNumber: string;
  imp_uid: string;
};

export type AccountProviderType =
  | 'landbook'
  | 'kakao'
  | 'naver'
  | 'apple'
  | 'facebook';

export interface Account {
  email: string;
  /** 주계정 유무 */
  group: boolean;
  provider: Provider;
}

export type ExistingAccountItem = {
  userId: number;
  account: Account[];
};

export type ExistingAccount = {
  count: number;
  items: ExistingAccountItem[];
};

/**
 * 해당 휴대폰번호로 인증하여 가입한 계정이 있는지 조회
 * @returns 해당 휴대폰번호로 인증하여 가입한 계정(e-mail),provider
 */
const getExistingAccount = (
  params: CheckExistingAccountParam,
  config?: AxiosRequestConfig
) => {
  return v2RestClient.get<ExistingAccount>(`/auth/certifications/check`, {
    params,
    ...config,
  });
};

export interface PostLinkedSocialAccountParam {
  imp_uid: string;
  phoneNumber: string;
  /** 주계정의 userId */
  groupId: number;
  /** 연동되는 계정의 userId */
  userId: number;
}

/**
 * 소셜 회원가입시 계정 연동
 */
const postLinkedSocialAccount = (
  data: PostLinkedSocialAccountParam,
  config?: AxiosRequestConfig
) => v2RestClient.post<{ token: string }>('/auth/social/linked', data, config);

/**
 * userId를 현재 계정에 연동시킨다
 */
const postLinkedSocialAccountById = (
  userId: number,
  config?: AxiosRequestConfig
) =>
  v2RestClient({
    url: `/auth/users/me/linked/${userId}`,
    method: 'post',
    ...config,
  });

/**
 * @param userId 연동되는 SNS계정의 id
 */
const deleteLinkedAccountById = (userId: number) =>
  v2RestClient.delete(`/auth/users/me/unlinked/${userId}`);

const authApis = {
  signUp,
  signIn,
  signOut,
  deleteAccount,
  refreshToken,
  checkEmail,
  resetPassword,
  resetPasswordWithToken,
  findPassword,
  setAuthToken,
  clearAuthToken,
  resendConfirmMail,
  getCertification,
  getExistingAccount,
  postLinkedSocialAccount,
  postLinkedSocialAccountById,
  deleteLinkedAccountById,
};

export default authApis;
