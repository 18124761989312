import authService from 'services/auth';
import v2RestClient from '.';
import ServerResponseError from 'apis/clients/v2RestClient/ServerResponseError';
import { AxiosRequestConfig } from 'axios';

/**
 * 서버 에러 응답 포멧을 정규화한다.
 */
export const normalizeServerRespError = (err: any) => {
  // console.error(999, err);
  const resp = err?.response?.data || {};
  const message = resp.msg || err.message;
  throw new ServerResponseError(message, err);
};

interface CustomReqeustConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

/**
 * Silent Refresh Interceptor
 */
export const refreshWhenTokenExpired = async (err: ServerResponseError) => {
  const originalRequest = err.config || ({} as CustomReqeustConfig);
  const isExpiredToken = err.code === ServerResponseError.EXPIRED_TOKEN;
  // Fixme: refresh request 구분한거 string 으로 좀 파편화 되는 느낌이긴 한데.. 나중에 리팩토링
  const shouldSilentRefresh =
    !originalRequest._retry && originalRequest.url !== '/auth/refresh';

  if (isExpiredToken && shouldSilentRefresh) {
    const newTokenInfo = await authService.refreshTokenAndUpdate();

    if (newTokenInfo && originalRequest.headers) {
      originalRequest.headers['Authorization'] = `Bearer ${newTokenInfo.token}`;
      originalRequest._retry = true;
    }
    return v2RestClient(originalRequest);
  }

  throw err;
};
