import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { User, CaptureContext } from '@sentry/types';
import ServerResponseError from 'apis/clients/v2RestClient/ServerResponseError';
import isObject from 'lodash/isObject';

const dsn = process.env.REACT_APP_SENTRY_DSN;
const environment = process.env.REACT_APP_PHASE;
const releaseVersion = process.env.REACT_APP_RELEASE_VERSION;
const EXCEPTION_ERROR = ['ChunkLoadError'];

type SentryError = Error | string | { [key: string]: any };

const isException = (error?: SentryError | null) => {
  const hasNameProperty = isObject(error) && 'name' in error;
  const errorName = hasNameProperty
    ? (error as Error).name
    : typeof error === 'string'
    ? error
    : '';

  return EXCEPTION_ERROR.includes(errorName);
};

let isInitialized = false;

const sentry = {
  initialize: () => {
    try {
      if (!dsn || !environment) {
        return;
      }

      const options: Sentry.BrowserOptions = {
        dsn,
        environment,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: environment === 'production' ? 0.1 : 0,
        beforeSend(event, hint) {
          const error = hint?.originalException;

          if (isException(error)) {
            return null;
          }

          if ((error as ServerResponseError)?.status === 401) {
            return null;
          }

          return event;
        },
      };

      if (releaseVersion) {
        options.release = releaseVersion;
      }

      Sentry.init(options);

      isInitialized = true;
    } catch (error) {
      console.error('failed to initialize Sentry', error);
    }
  },
  setUser(user: User) {
    if (!isInitialized) {
      return;
    }

    try {
      Sentry.setUser(user);
    } catch (error) {
      console.error('failed to set Sentry user', error);
    }
  },
  resetUser() {
    if (!isInitialized) {
      return;
    }

    try {
      Sentry.configureScope(scope => scope.setUser(null));
    } catch (error) {
      console.error('failed to reset Sentry user', error);
    }
  },
  sendError(error: SentryError, context?: CaptureContext) {
    if (!isInitialized) {
      return;
    }

    try {
      Sentry.captureException(error, context);
    } catch (error) {
      console.error('failed to send an error to Sentry', error);
    }
  },
};

export const ErrorBoundary = Sentry.ErrorBoundary;

export default sentry;
