import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/client';

/** 해당 UI가 배치된 위치 (px) */
type LayoutPosition = {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
};

type LayoutInfo = {
  visible: boolean;
  position?: LayoutPosition;
};

type InitialState = {
  tabBar: LayoutInfo;
  unitConvertButton: LayoutInfo;
};

const initialState: InitialState = {
  tabBar: {
    visible: true,
  },
  unitConvertButton: {
    visible: false,
  },
};

const globalLayoutSlice = createSlice({
  name: 'globalLayoutSlice',
  initialState,
  reducers: {
    setTabBarLayout: (state, action: PayloadAction<LayoutInfo>) => {
      state.tabBar = action.payload;
    },
    setUnitConvertButtonLayout: (state, action: PayloadAction<LayoutInfo>) => {
      state.unitConvertButton = action.payload;
    },
  },
});

export const { setTabBarLayout, setUnitConvertButtonLayout } =
  globalLayoutSlice.actions;

export const selectGlobalLayout = (state: RootState) => state.globalLayout;

export default globalLayoutSlice.reducer;
