import axios from 'axios';
import qs from 'qs';
import {
  normalizeServerRespError,
  refreshWhenTokenExpired,
} from './interceptors';

const baseURL = process.env.REACT_APP_API_V2_ENDPOINT;

const v2RestClient = axios.create({
  baseURL,
  timeout: 10000,
  withCredentials: true,
  paramsSerializer: {
    serialize: (params: {}) =>
      qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
      }),
  },
});

v2RestClient.interceptors.response.use(undefined, normalizeServerRespError);
v2RestClient.interceptors.response.use(undefined, refreshWhenTokenExpired);

export const updateDefaultHeader = (key: string, value: string) => {
  v2RestClient.defaults.headers.common[key] = value;
};

export const deleteDefaultHeader = (key: string) => {
  delete v2RestClient.defaults.headers.common[key];
};

export default v2RestClient;
