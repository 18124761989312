import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PremiumType } from 'components/Analytics/Premium';
import { RootState } from '..';

interface PremiumState {
  selectedPremiumType: PremiumType;
  selectedPremiumUniqueCode: string;
}

const initialState: PremiumState = {
  selectedPremiumType: 'ALL_RESIDENTIAL',
  selectedPremiumUniqueCode: '',
};

const premiumSlice = createSlice({
  name: 'premium',
  initialState,
  reducers: {
    setSelectedPremiumType: (state, action: PayloadAction<PremiumType>) => {
      state.selectedPremiumType = action.payload;
    },
    setSelectedPremiumUniqueCode: (
      state,
      action: PayloadAction<PremiumType>
    ) => {
      state.selectedPremiumType = action.payload;
    },
  },
});

export const { setSelectedPremiumType } = premiumSlice.actions;

export const selectPremium = (state: RootState) => state.premium;

export default premiumSlice.reducer;
