import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/client';

export type SubSectionType = 'NearbyTradingPrices' | 'NearbyDevelopmentCase';

/**
 * @property scrollTop 서브섹션을 닫을 때 메인섹션의 scrollTop을 0으로 변경합니다.
 */
export type SubSectionCloseType = 'scrollTop';

interface ParcelState {
  isOpen: boolean;
  type: SubSectionType | undefined;
  hoveredItem: any;
  data: any[];
  closeType?: SubSectionCloseType;
}

const initialState: ParcelState = {
  isOpen: false,
  type: undefined,
  hoveredItem: undefined,
  data: [],
  closeType: undefined,
};

const parcelSlice = createSlice({
  name: 'parcel',
  initialState,
  reducers: {
    openSubsection: (
      state,
      {
        payload,
      }: PayloadAction<{
        type: SubSectionType;
        data: any[];
      }>
    ) => {
      state.isOpen = true;
      state.data = payload.data;
      state.type = payload.type;
      state.closeType = undefined;
    },
    closeSubsection: (
      state,
      { payload }: PayloadAction<SubSectionCloseType | undefined>
    ) => {
      state.isOpen = false;
      state.type = undefined;
      state.data = [];
      state.hoveredItem = undefined;
      state.closeType = payload;
    },
    setHoveredItem: (state, action) => {
      state.hoveredItem = action.payload;
    },
  },
});

export const { openSubsection, closeSubsection, setHoveredItem } =
  parcelSlice.actions;
export const selectParcel = (state: RootState) => state.parcel;
export const selectParcelByType = (state: RootState, type: SubSectionType) =>
  state.parcel.type === type ? state.parcel : initialState;

export default parcelSlice.reducer;
