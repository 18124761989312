import { MAX_PREMIUM_CALCULATION_TIME } from 'constants/premium';
import dayjs from 'dayjs';

export type TimeArray = [
  number, // year
  number, // month
  number, // date
  number, // hour
  number, // minute
  number, // second
  number // nano second
];

export type TimeFormat =
  | 'YYYY-MM'
  | 'YYYY-MM-DD'
  | 'YYYY-MM-DD hh:mm'
  | 'YYYY-MM-DD hh:mm:ss'
  | 'YYYY.MM.DD'
  | 'YYYY.MM.DD hh:mm'
  | 'YYYY년 MM월 DD일'
  | 'YYYY.MM.DD (hh:mm)';

const fillZero = (num: number) => num.toString().padStart(2, '0');

const toDateArray = (dateString: string) => {
  const d = new Date(dateString);

  return [
    d.getFullYear(),
    d.getMonth() + 1,
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds(),
  ].map(padZero);
};

const dateFromTimeArray = (timeArray: TimeArray) => {
  const [year, month, date, hour, minute, second] = timeArray;

  return new Date(Date.UTC(year, month - 1, date, hour, minute, second));
};

const formatTimeArray = (
  value: TimeArray,
  format?: TimeFormat,
  tzd: boolean = true
) => {
  if (!Array.isArray(value)) {
    return '';
  }
  const [year, month, date, hour, minute, second] = value;
  const YYYY = year.toString();
  const MM = fillZero(month);
  const DD = fillZero(date);
  const hh = fillZero(hour);
  const mm = fillZero(minute);
  const ss = fillZero(second);
  const TZD = tzd ? '+09:00' : '';

  switch (format) {
    case 'YYYY-MM':
      return `${YYYY}-${MM} 00:00${TZD}`;
    case 'YYYY-MM-DD':
      return `${YYYY}-${MM}-${DD} 00:00${TZD}`;
    case 'YYYY-MM-DD hh:mm':
      return `${YYYY}-${MM}-${DD} ${hh}:${mm}${TZD}`;
    case 'YYYY-MM-DD hh:mm:ss':
      return `${YYYY}-${MM}-${DD} ${hh}:${mm}:${ss}${TZD}`;
    case 'YYYY.MM.DD':
      return `${YYYY}.${MM}.${DD} 00:00${TZD}`;
    case 'YYYY.MM.DD hh:mm':
      return `${YYYY}.${MM}.${DD} ${hh}:${mm}${TZD}`;
    case 'YYYY년 MM월 DD일':
      return `${YYYY}년 ${MM}월 ${DD}일`;
    case 'YYYY.MM.DD (hh:mm)':
      return `${YYYY}.${MM}.${DD} (${hh}:${mm})`;
    default:
      return `${YYYY}-${MM}-${DD}T${hh}:${mm}:${ss}${TZD}`;
  }
};
const formatDateString = (
  dateSrring: string,
  format?: TimeFormat,
  tzd: boolean = true
) => {
  const [YYYY, MM, DD, hh, mm, ss] = toDateArray(dateSrring);
  const TZD = tzd ? '+09:00' : '';

  switch (format) {
    case 'YYYY-MM':
      return `${YYYY}-${MM} 00:00${TZD}`;
    case 'YYYY-MM-DD':
      return `${YYYY}-${MM}-${DD} 00:00${TZD}`;
    case 'YYYY-MM-DD hh:mm':
      return `${YYYY}-${MM}-${DD} ${hh}:${mm}${TZD}`;
    case 'YYYY-MM-DD hh:mm:ss':
      return `${YYYY}-${MM}-${DD} ${hh}:${mm}:${ss}${TZD}`;
    case 'YYYY.MM.DD':
      return `${YYYY}.${MM}.${DD} 00:00${TZD}`;
    case 'YYYY.MM.DD hh:mm':
      return `${YYYY}.${MM}.${DD} ${hh}:${mm}${TZD}`;
    case 'YYYY년 MM월 DD일':
      return `${YYYY}년 ${MM}월 ${DD}일`;
    case 'YYYY.MM.DD (hh:mm)':
      return `${YYYY}.${MM}.${DD} (${hh}:${mm})`;
    default:
      return `${YYYY}-${MM}-${DD}T${hh}:${mm}:${ss}${TZD}`;
  }
};

export const MINUTE_MS = 60 * 1000;
export const HOUR_MS = 60 * MINUTE_MS;
export const DAY_MS = 24 * HOUR_MS;
export const WEEK_MS = 7 * DAY_MS;
export const prevMonthDayCount = new Date(new Date().setDate(0)).getDate();
export const MONTH_MS = prevMonthDayCount * DAY_MS;
export const YEAR_MS = 365 * DAY_MS;

export type DateFormat = `${number}-${number}-${number}`;
export type RunningTimeFormat = `${number}:${number}`;

const TIME_OFFSET_KR = '+09:00';

/**
 * 한국표준시(KST, UTC+09:00)를 기준으로 해당 날짜의 00시 00분 00초의 timeStap를 생성합니다.
 * @example getTimeStamp('2022-01-01') // 2022년 1월 1일 00시 00분 00초
 */
export const getTimeStamp = (
  dateFormat: DateFormat,
  timeFormat?: RunningTimeFormat
) => {
  const timeString = timeFormat
    ? `${timeFormat}:00${TIME_OFFSET_KR}`
    : `00:00:00${TIME_OFFSET_KR}`;
  const dateString = [dateFormat, timeString].join('T');
  return new Date(dateString).getTime();
};

const toTimeAgo = (date: string | number | Date) => {
  const now = Date.now();
  const timestamp = new Date(date).getTime();
  const diff = now - timestamp;

  switch (true) {
    case diff < MINUTE_MS:
      return '방금 전';
    case diff < HOUR_MS:
      return `${Math.floor(diff / MINUTE_MS)}분 전`;
    case diff < DAY_MS:
      return `${Math.floor(diff / HOUR_MS)}시간 전`;
    case diff < WEEK_MS:
      return `${Math.floor(diff / DAY_MS)}일 전`;
    case diff < MONTH_MS:
      return `${Math.floor(diff / WEEK_MS)}주 전`;
    case diff < YEAR_MS:
      return `${Math.floor(diff / MONTH_MS)}달 전`;
    default:
      return `${Math.floor(diff / YEAR_MS)}년 전`;
  }
};

const toTimeAgoOrDate = (date: string | number | Date) => {
  const now = Date.now();
  const timestamp = new Date(date).getTime();
  const diff = now - timestamp;

  switch (true) {
    case diff < MINUTE_MS:
      return '방금 전';
    case diff < HOUR_MS:
      return `${Math.floor(diff / MINUTE_MS)}분 전`;
    case diff < DAY_MS:
      return `${Math.floor(diff / HOUR_MS)}시간 전`;
    case diff < WEEK_MS:
      return `${Math.floor(diff / DAY_MS)}일 전`;
    case diff < MONTH_MS:
      return `${Math.floor(diff / WEEK_MS)}주 전`;
    default:
      const dateObj = new Date(date);
      const month = dateObj.getMonth() + 1; // 월은 0부터 시작하므로 +1 해줍니다.
      const day = dateObj.getDate();
      return `${month}월 ${day}일`;
  }
};

const timeLimitMs = MAX_PREMIUM_CALCULATION_TIME * MINUTE_MS;

/**
 * @description AI 건축분석 요청 진행상황을 표현하는 함수
 * 기본 시간은 {MAX_PREMIUM_CALCULATION_TIME}분이며, 남았을경우 음수, 시간이 지연되었을경우 양수로 반환합니다.
 * @param baseTimeStamp 요청햇던 시간(timeStamp)
 * @returns 음수 일 경우 초과, 양수 일 경우 미만 (분단위를 반한합니다.)
 * @return currentTime 남은 시간(분)
 * @return percentage 진행률
 */
const toPremiumTimeProgression = (
  baseTimeMs: number
): { currentTime: number; percentage: number } => {
  const diff = Date.now() - baseTimeMs;

  if (timeLimitMs <= 0) {
    return {
      currentTime: convertToMinutes(diff),
      percentage: 100,
    };
  }

  const percentage = Math.abs(Math.round((diff / timeLimitMs) * 100));
  const currentTime = convertToMinutes(timeLimitMs - diff);

  return {
    currentTime,
    percentage,
  };
};

const convertToMinutes = (value: number) => {
  return Math.round(value / MINUTE_MS);
};

/**
 *
 * @param startDate 시작 날짜 (yyyy-mm-dd)
 * @param endDate 종료 날짜 (yyyy-mm-dd) endDate가 2022-11-22 라면, 2022-11-21 23:59:59까지 유효함. (2022-11-22 00:00:00 부터 false)
 * @param startHour 시작 날짜 (hh:mm) // 없으면 00:00
 * @param endHour 시작 날짜 (hh:mm)  // 없으면 00:00
 * @returns 시작/종료 날짜에 포함되는지 여부
 */
const isBetween = (
  startDate: DateFormat,
  endDate: DateFormat,
  startTime?: RunningTimeFormat,
  endTime?: RunningTimeFormat
) => {
  const now = Date.now();

  const startPoint = getTimeStamp(startDate, startTime);
  const endPoint = getTimeStamp(endDate, endTime);

  return startPoint <= now && now <= endPoint;
};

const padString = (num: number) => num.toString().padStart(2, '0');

const getToday = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const date = today.getDate();

  return [year, padString(month), padString(date)].join('.');
};

const padZero = (value: number) => value.toString().padStart(2, '0');

const dateFormatter = (dateString: string) => {
  const [year, month, date] = toDateArray(dateString);
  return `${year}년 ${month}월 ${date}일`;
};

/**
 * 오늘을 기준으로 날의 차 계산
 */
const calcDiffDays = (dateString: string, isRound: boolean = true) => {
  const today = new Date();
  const targetDay = new Date(dateString);
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return isRound
    ? Math.round((targetDay.getTime() - today.getTime()) / millisecondsPerDay)
    : (targetDay.getTime() - today.getTime()) / millisecondsPerDay;
};

const checkExpired = (expiredAt: string) => {
  return calcDiffDays(expiredAt, false) <= 0;
};

const diffDate = (a: Date, b: Date) => {
  return a.getTime() - b.getTime();
};

const convertDate = (value: TimeArray | string) => {
  return Array.isArray(value) ? dateFromTimeArray(value) : new Date(value);
};

const convertedKoreanTime = (usedAt: string, hours?: number, minute?: number) =>
  dayjs(dateUtil.formatDateString(usedAt))
    .add(hours ? hours : 9, 'hours')
    .add(minute ? minute : 0, 'minute');

const dateUtil = {
  formatTimeArray,
  toTimeAgo,
  toPremiumTimeProgression,
  isBetween,
  getToday,
  dateFormatter,
  calcDiffDays,
  diffDate,
  dateFromTimeArray,
  convertDate,
  checkExpired,
  toDateArray,
  formatDateString,
  toTimeAgoOrDate,
  convertedKoreanTime,
};

export default dateUtil;
