import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'store/client';
import poll from 'components/Analytics/_comps/PremiumBuildStatusPolling';
import { batch } from 'react-redux';

type RegionState = {
  premiumBuildStatus: any;
  premiumBuildResult: any;
};

const initialState: RegionState = {
  premiumBuildStatus: 'IDLE',
  premiumBuildResult: null,
};

const pollingSlice = createSlice({
  name: 'polling',
  initialState,
  reducers: {
    setBuildStatus: (state, action: PayloadAction<any>) => {
      state.premiumBuildStatus = action.payload;
    },
    setBuildResult: (state, action: PayloadAction<any>) => {
      state.premiumBuildResult = action.payload;
    },
    clearPremiumPolling: state => {
      state.premiumBuildResult = null;
      state.premiumBuildStatus = 'IDLE';
    },
  },
});

export const { clearPremiumPolling } = pollingSlice.actions;

export const startPollingPremium =
  (interval: number): AppThunk =>
  async dispatch => {
    dispatch(pollingSlice.actions.setBuildStatus('RUNNING'));
    const result = await poll
      .requestPolling(interval)
      .then(res => res)
      .catch(e => e);

    batch(() => {
      dispatch(pollingSlice.actions.setBuildStatus(result?.buildStatus));
      dispatch(pollingSlice.actions.setBuildResult(result?.pnu));
    });
  };

export const selectPolling = (state: RootState) => state.polling;

export default pollingSlice.reducer;
