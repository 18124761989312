import v2RestClient from './clients/v2RestClient';

type FilterCondition = {
  id: number;
  gus: string[];
};

type SalesItem = {
  id: number;
  filterCondition: FilterCondition;
};

type OfferResponse = {
  currentPage: number;
  pageSize: number;
  salesItems: SalesItem[];
  totalCount: number;
};

const fetchNearbyOfferCount = (gus: string) => {
  return v2RestClient.head(`/trading/search?gus=${gus}`);
};

const fetchSimilarOffers = (pnu: string) => {
  return v2RestClient.get<OfferResponse>(`/trading/similar?pnu=${pnu}`);
};

export type Notification = {
  email?: string;
  method?: 'EMAIL' | 'SMS';
  phone?: string;
  status: 'DEACTIVATED' | 'ACTIVATED';
};

export type NotificationResponse = Notification & {
  userId: number;
};

const fetchNotification = () => {
  return v2RestClient.get<NotificationResponse>('/trading/me/alarm');
};

const updateNotification = (payload: Notification) => {
  return v2RestClient.put<NotificationResponse>('/trading/me/alarm', payload);
};

const tradingApis = {
  fetchNearbyOfferCount,
  fetchSimilarOffers,
  fetchNotification,
  updateNotification,
};

export default tradingApis;
