/**
 * string로 캐스팅된 number를 number로 캐스팅
 * @example
 *  numericConversionFromString("100") // 100
 *  numericConversionFromString("0") // 0
 *  numericConversionFromString("") // NaN
 */
export const numericConversionFromString = (str: string) => {
  let result = NaN;

  if (str === '') {
    result = NaN;
  } else if (typeof str === 'string') {
    result = Number(str);
  }

  return result;
};

/**
 * string으로 캐스팅된 number 값을 구분합니다.
 * @example
 * convertibleToNumber('100') // true
 * convertibleToNumber('0') // true
 * convertibleToNumber('') // false
 * convertibleToNumber('banana') // false
 */
export const convertibleToNumber = (str: string) =>
  !isNaN(numericConversionFromString(str));

export const toNumber = function toNumber(
  value: string | number | string[] | number[] | undefined | null
): any {
  if (value instanceof Array) {
    return value.map(toNumber);
  }

  if (typeof value === 'string' && convertibleToNumber(value)) {
    return numericConversionFromString(value);
  }

  return value;
};

/**
 *  문자열에서 숫자만 추출한다.
 */
export const onlyNumber = (str: string) => Number(str.replace(/[^0-9]/g, ''));

/**
 * toFixed의 결과를 숫자로 반환합니다.
 * @example toFixedNumber(1.5, 0) // 1
 * @example toFixedNumber(1.56, 1) // 1.5
 */
export const toFixedNumber = (value: number, digits: number = 0) => {
  const utilNumber = Math.pow(10, digits);
  const errorRange = 0.0001;
  const significantFigures = value * utilNumber;
  const primeNumber = significantFigures % 1; // 소수
  const shouldRound = 1 - primeNumber < errorRange;

  return shouldRound
    ? Math.round(significantFigures) / utilNumber
    : Math.floor(significantFigures) / utilNumber;
};

/**
 * 밑이 base인 log
 */
export const getBaseLog = (x: number, base: number) =>
  Math.log(x) / Math.log(base);

export const log10 = (x: number) => getBaseLog(x, 10);

/**
 * 자연수 여부를 반환
 * @param x
 * @example isNaturalNumber(10.2) // false
 * @example isNaturalNumber(10) // true
 * @example isNaturalNumber(10.0000) // true
 */
export const isNaturalNumber = (x: number) => x % 1 === 0;

/**
 * 정수의 자릿수를 반환한다.
 * @param x
 * @example getIntegerLength(10) // 2
 * @example getIntegerLength(110) // 3
 * @example getIntegerLength(10.1234) // 2
 * @example getIntegerLength(-99.555) // 2
 */
export const getIntegerLength = (x: number) =>
  Math.floor(Math.abs(x)).toString().length;

/**
 * 소수부의 자릿수를 반환한다.
 * @example getFractionDigits(-10.123) // 3
 * @example getFractionDigits(-1000.1) // 1
 * @example getFractionDigits(-1000.1234567) // 7
 */
export const getFractionDigits = (x: number) => {
  if (isNaturalNumber(x)) {
    return 0;
  }

  const [, decimals] = x.toString().split('.');
  return decimals.length;
};

/**
 * toFixed된 percentage(string)가 0.0 이거나 NaN일때, 0으로 반환한다.
 */
export const percentageValueFormatter = (percentage: string) => {
  if (isNaN(Number(percentage)) || Number(percentage) === 0) {
    return '0';
  }
  return percentage;
};
