import { AxiosRequestConfig } from 'axios';
import { QueryObject } from 'hooks/useQueryString';
import filterUtil from 'utils/filterUtil';
import { buildQueryString, toAllowedQueryParams } from 'utils/urlUtil';
import { isLatLngString } from 'utils/map';
import dedupe from 'utils/dedupe';
import v2RestClient from './clients/v2RestClient';
import { Pagination } from './clients/v2RestClient/type';
import { ArticleType, FilterQuery } from './filter';
import { RegionType } from './region';

const articleQueryKeys = {
  regionArticles: ['city', 'gu', 'dong', 'size'],
  pagingArticles: ['city', 'gu', 'dong', 'sortBy', 'orderBy', 'page', 'size'],
  boundsArticles: [
    'southWest',
    'northEast',
    'regionType',
    'sortBy',
    'orderBy',
    'page',
    'size',
  ],
};

export type Article = {
  /** pnu */
  id: string;
  pnu: string;

  address: string;
  /**
   * TODO
   */
  code: string;
  longitude?: number;
  latitude?: number;
  /** 매매가 (만) */
  dealPrice: number;
  /**
   * TODO
   */
  desktopUrl: string;
  /**
   * TODO
   */
  mobileUrl: string;
  /**
   * TODO
   */
  nearestPlace: any[];
  gfArea: number;
  /** m²당 추정가 (만) */
  estimatedPrice: number;
  estimatedUnitPrice: number;
  originEstimatedPrice: number;
  /** 토지면적 (m²) */
  lotArea: number;
  /** 매물면적 (m²) */
  groundSpace: number;
  /** 용도지역 */
  landuseZone?: string;
  imgUrl: string;
  imgUrls: string[];

  exposedAt?: string;
  /** 노후년도 */
  age: number;
  /** 신축별점 */
  ratings: number;
  /** 신축 | 단독다가구 */
  articleType: ArticleType;
};

/**
 * PRICE_DIFF 추정가 대비순
 * EXPOSED_AT 등록일
 * DEAL_PRICE 매매가
 * UNIT_PRICE 평당가
 * NEW_CONSTRUCTION 신축분양 수익순
 */
export enum SortType {
  PRICE_DIFF = 'PRICE_DIFF',
  EXPOSED_AT = 'EXPOSED_AT',
  DEAL_PRICE = 'DEAL_PRICE',
  UNIT_PRICE = 'UNIT_PRICE',
  NEW_CONSTRUCTION = 'NEW_CONSTRUCTION',
}
export type OrderType = 'ASC' | 'DESC';

export interface ArticlesQuery extends FilterQuery {
  city: string;
  gu: string;
  dong?: string;
  /** 정렬 기준 (default: PRICE_DIFF) */
  sortBy?: SortType;
  /** 오름차순 | 내림차순 기준 (default: DESC)  */
  orderBy?: OrderType;
  page?: number;
  /**
   * 페이지 사이즈 값 (default: 10)
   * size 값이 0이면 전체 목록 요청으로 취급됨
   */
  size?: number;
  /** 남서쪽 좌표 ex) 37.4874127684412,126.927123713685 */
  southWest?: string;
  /** 북동쪽 좌표 */
  northEast?: string;
}

/** 지도 내에서 사용되는 query 인터페이스 */
export interface ArticlesMapQuery extends ArticlesQuery {
  latitude?: number | string;
  longitude?: number | string;
  zoomLevel?: number | string;
  /** 이전 페이지에서 클릭했던 카드 아이디 */
  pnu?: string | null;
  /** 바텀시트 오픈 여부 */
  sheet?: 'open';
  /** count API 호출 시 사용됨 */
  regionType?: RegionType;
  address_name?: string;
}

/**
 * 필수 query parameter를 체크합니다.
 */
export const isArticlesQuery = (
  queryObject: QueryObject
): queryObject is ArticlesQuery => {
  const requiredFields = ['city', 'gu'];
  const isPrepared = requiredFields.every(filed => Boolean(queryObject[filed]));

  return isPrepared;
};

/**
 * '지도 내 검색' 여부를 판단합니다.
 */
export const isBoundsQuery = (query: ArticlesQuery) =>
  isLatLngString(query.northEast) && isLatLngString(query.southWest);

const ARTICLE_API_TIMEOUT = 30000;

export const defaultLatestArticles = {
  city: '',
  gu: '',
  dong: '',
  articles: [],
};

export type RecommendedArticles = {
  city: string;
  gu: string;
  dong: string;
  articles: Article[];
};

const getLastestArticles = async (params: ArticlesQuery) => {
  try {
    const queryObject = filterUtil.convertToSquareMeter(params);
    const querystring = buildQueryString(queryObject, {
      arrayFormat: 'repeat',
    });
    const endpoint = `/articles/me/latest?${querystring}`;
    const response = await v2RestClient.get<RecommendedArticles>(endpoint, {
      timeout: ARTICLE_API_TIMEOUT,
    });

    return response.data;
  } catch (error) {
    return defaultLatestArticles;
  }
};

export const defaultNearbyArticles = {
  targetCity: '',
  targetGu: '',
  targetDong: '',
  regionArticles: [],
};

export type NearbyArticles = {
  targetCity: string;
  targetGu: string;
  targetDong: string;
  regionArticles: RecommendedArticles[];
};

const getNearbyArticles = async (params: ArticlesQuery) => {
  try {
    const queryObject = filterUtil.convertToSquareMeter(params);
    const querystring = buildQueryString(queryObject, {
      arrayFormat: 'repeat',
    });
    const endpoint = `/articles/me/nearby?${querystring}`;
    const response = await v2RestClient.get<NearbyArticles>(endpoint, {
      timeout: ARTICLE_API_TIMEOUT,
    });

    return response.data;
  } catch (error) {
    return defaultNearbyArticles;
  }
};

/**
 * 매물목록을 조회합니다.
 * 매개변수로 전달받는 모든 면적 단위는 평입니다.
 * api 요청시 제곱마터로 단위 변환을 합니다.
 */

const dedupeFetchArticlesByPage = dedupe((endpoint: string) => {
  return v2RestClient.get<Pagination<Article>>(endpoint);
});

const fetchArticlesByPage = (params: ArticlesQuery) => {
  const isBoundsQueryParams = isBoundsQuery(params);
  const allowlist = [
    ...(isBoundsQueryParams
      ? articleQueryKeys.boundsArticles
      : articleQueryKeys.pagingArticles),
    ...filterUtil.queryKeys,
  ];
  const allowedParams = toAllowedQueryParams(params, allowlist);
  const queryObject = filterUtil.convertToSquareMeter(allowedParams);
  const querystring = buildQueryString(queryObject, { arrayFormat: 'repeat' });
  const apiPath = isBoundsQueryParams
    ? '/articles/search/coord'
    : '/articles/search/address';
  const endpoint = `${apiPath}?${querystring}`;

  return dedupeFetchArticlesByPage(endpoint, {
    timeout: ARTICLE_API_TIMEOUT,
  });
};

const dedupeFetchArticlesByAddress = dedupe(
  (endpoint: string, options?: AxiosRequestConfig) => {
    return v2RestClient.get<Pagination<Article>>(endpoint, options);
  }
);

const fetchArticlesByAddress = async (params: ArticlesMapQuery) => {
  const allowedParams = toAllowedQueryParams(params, [
    ...articleQueryKeys.regionArticles,
    ...filterUtil.queryKeys,
  ]);
  const queryObject = filterUtil.convertToSquareMeter(allowedParams);
  const querystring = buildQueryString(queryObject, { arrayFormat: 'repeat' });
  const endpoint = `/articles/search/address?${querystring}`;
  const response = await dedupeFetchArticlesByAddress(endpoint, {
    timeout: ARTICLE_API_TIMEOUT,
  });

  return response.data;
};

const dedupeFetchArticlesByBounds = dedupe(
  (endpoint: string, options?: AxiosRequestConfig) => {
    return v2RestClient.get<Pagination<Article>>(endpoint, options);
  }
);

const fetchArticlesByBounds = async (params: ArticlesMapQuery) => {
  const allowedParams = toAllowedQueryParams(params, [
    ...articleQueryKeys.boundsArticles,
    ...filterUtil.queryKeys,
  ]);
  const queryObject = filterUtil.convertToSquareMeter(allowedParams);
  const querystring = buildQueryString(queryObject, { arrayFormat: 'repeat' });
  const endpoint = `/articles/search/coord?${querystring}`;
  const response = await dedupeFetchArticlesByBounds(endpoint, {
    timeout: ARTICLE_API_TIMEOUT,
  });

  return response.data;
};

export type ArticleCount = {
  name: string;
  latitude: number;
  longitude: number;
  count: number;
};

export type ArticleCountsByRegion = {
  [key in RegionType]: ArticleCount[];
};

const fetchArticleCounts = async (params: ArticlesMapQuery) => {
  try {
    const allowedParams = toAllowedQueryParams(params, [
      ...articleQueryKeys.boundsArticles,
      ...filterUtil.queryKeys,
    ]);
    const queryObject = filterUtil.convertToSquareMeter(allowedParams);
    const querystring = buildQueryString(queryObject, {
      arrayFormat: 'repeat',
    });
    const endpoint = `/articles/search/count?${querystring}`;
    const response = await v2RestClient.get<ArticleCount[]>(endpoint, {
      timeout: ARTICLE_API_TIMEOUT,
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

const articlesApis = {
  getLastestArticles,
  getNearbyArticles,

  fetchArticlesByPage,
  fetchArticlesByAddress,
  fetchArticlesByBounds,
  fetchArticleCounts,
};

export default articlesApis;
