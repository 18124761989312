import getAgent from '@egjs/agent';

export type MobileAppOs = 'android' | 'ios';

/**
 * 실행환경
 * @param web web 환경
 * @param android android app 환경
 * @param ios ios app 환경
 */
export type Environment = MobileAppOs | 'mobileWeb' | 'desktopWeb' | 'web';

export const isDevelopmentPhase = ['local', 'development'].includes(
  process.env.REACT_APP_PHASE || 'local'
);

export const isStagingPhase = process.env.REACT_APP_PHASE === 'staging';
export const isProductionPhase = process.env.REACT_APP_PHASE === 'production';

export const agent = getAgent();
export const osName = agent.os.name;
export const isAndroid = osName === 'android';
export const isAndroidWebView = isAndroid && agent.browser.webview;
/**
 * iPad OS 13 버전부터 PC로 인식됨
 * 구분을 위해 maxTouchPoints 값을 함께 체크함
 * @see https://github.com/naver/egjs-agent/issues/42
 **/
export const isIpadOS = osName === 'mac' && navigator.maxTouchPoints > 0;
export const isIOS = osName === 'ios';
export const isIOSWebView = isIOS && agent.browser.webview;
export const isMobileDevice =
  agent.isMobile || isIOS || isIpadOS || isAndroid || agent.browser.webview;

export const isNoticeModalEnabled =
  process.env.REACT_APP_NOTICE_ENABLED === '1';

export const isAnalyticsEnabled = true;
