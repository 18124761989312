import dedupe from 'utils/dedupe';
import v2RestClient from './clients/v2RestClient';
import { Geometry } from 'utils/map';

type PointPolygonsResponse = {
  id: string;
  jimok: string;
};

const requestClickedPnu = (latitude: number, longitude: number) => {
  return v2RestClient.get<PointPolygonsResponse>('/point_polygons', {
    params: {
      lon: longitude,
      lat: latitude,
    },
  });
};

type FilterPolygonResponse = {
  id: number;
  pnu: string;
  address: string;
  latitude: number;
  longitude: number;
};

const requestFilteredPolygons = (params: any) => {
  return v2RestClient.get<FilterPolygonResponse[] | number>(`/parcel_searchs`, {
    params,
    timeout: 30000,
  });
};

type Bounds = {
  south_west: string;
  north_east: string;
};

const requestCorpIds = (params: Bounds) => {
  return v2RestClient.head(`/corporations`, {
    params,
  });
};

// ref: https://www.notion.so/spacewalkcorp/a0cd49f5a8f9408fa0c78b2060e7a024?v=19ac44db705a466992d1b487e2f5196a
export type Corp = {
  id: string;
  name: string;
  itemCount: number;
  latitude: number;
  longitude: number;
  items: CorpItem[];
  link: string;
  polygons: Geometry[];
};

// ref: https://www.notion.so/spacewalkcorp/a435ad695210469b8342fc6b87eb8d01?v=e8e1fcb74f784ea59c54e18c2a254874
export type CorpItem = {
  pnu: string;
  district: string;
  landPurpose: string;
  status: string;
  contractDate: string;
  contact: string;
  managingDepartment: string;
  latitude: number;
  longitude: number;
  link: string;
  isSelling: boolean;
  faRatio: number;
  bcRatio: number;
};

type CorpParams = {
  isSelling: boolean;
  south_west: string;
  north_east: string;
};

const requestCorp = (corpId: string, params: CorpParams) => {
  return v2RestClient.get<Corp>(`/corporations/${corpId}`, {
    params,
  });
};

const requestCorps = (corpIds: string[], params: CorpParams) => {
  return Promise.all(corpIds.map(corpId => requestCorp(corpId, params)));
};

const requestCorpItem = (corpId: string, pnu: string) => {
  return v2RestClient.get<CorpItem>(`/corporations/${corpId}/parcels/${pnu}`);
};

/**
 * total: 총액
 * py: 평당가
 * estimated: 추정가
 */
export type PriceUnit = 'total' | 'py' | 'estimated';

/**
 * normal: 일반
 * satellite: 위성
 * district: 지적도
 */
export type MapType = 'normal' | 'satellite' | 'district';

const mapApis = {
  requestClickedPnu,
  requestFilteredPolygons,
  requestCorpIds: dedupe(requestCorpIds),
  requestCorp: dedupe(requestCorp),
  requestCorps,
  requestCorpItem: dedupe(requestCorpItem),
};

export default mapApis;
