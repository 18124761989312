import userApis, {
  BehaviorLogParams,
  LandbookVersion,
  LandbookVersionCriteria,
} from 'apis/user';

import getAgent from '@egjs/agent';

import storageUtil from './storageUtil';

const THIRTY_MIN_MS = 60 * 30 * 1000;
/*
 * 다음 accesslog 세션 시간을 30분을 추가하여 저장합니다.
 */
const setNextSessionTime = () => {
  const nextTime = Date.now() + THIRTY_MIN_MS;
  storageUtil.set('nextAccessLogSessionTime', nextTime);
};

/*
 * 세션시간이 지난지 확인하고 로그를 보냅니다.
 */
const sendAccessLog = async () => {
  const next = storageUtil.get('nextAccessLogSessionTime', 0);
  const now = Date.now();
  const shouldSend = now >= next;
  if (shouldSend) {
    const { browser, os, landbookVersion } = await getAgentInfo();
    const params = {
      browser,
      os,
      landbookVersion,
    };
    userApis.sendAccessLog(params);
    setNextSessionTime();
  }
};

const sendAnalysisBasicLog = async (pnu: string, isBuildCoverage: boolean) => {
  const { browser, os, landbookVersion } = await getAgentInfo();
  const params: BehaviorLogParams = {
    browser,
    os,
    pnu,
    landbookVersion,
    isBuildCoverage,
    action: 'pageView',
    serviceType: 'basic',
  };
  userApis.sendBehaviorLog(params);
};

const sendAnalysisPremiumLog = async (pnu: string) => {
  const { browser, os, landbookVersion } = await getAgentInfo();
  const params: BehaviorLogParams = {
    browser,
    os,
    pnu,
    landbookVersion,
    action: 'pageView',
    serviceType: 'premium',
  };
  userApis.sendBehaviorLog(params);
};

/** AI 건축페이지 접근 LOG */
const sendAiAnalyticsLog = async () => {
  const { browser, os, landbookVersion } = await getAgentInfo();
  const params: BehaviorLogParams = {
    browser,
    os,
    pnu: '',
    landbookVersion,
    action: 'pageView',
    serviceType: 'analytics',
  };
  userApis.sendBehaviorLog(params);
};

/** 라이트 분석 시 LOG */
const sendAnalysisLightRequestLog = async (pnu: string) => {
  const { browser, os, landbookVersion } = await getAgentInfo();
  const params: BehaviorLogParams = {
    browser,
    os,
    pnu,
    landbookVersion,
    action: 'buildRequest',
    serviceType: 'light',
    isBuildCoverage: true,
  };
  userApis.sendBehaviorLog(params);
};

const sendAnalysisPremiumRequestLog = async (pnu: string) => {
  const { browser, os, landbookVersion } = await getAgentInfo();
  const params: BehaviorLogParams = {
    browser,
    os,
    pnu,
    landbookVersion,
    action: 'buildRequest',
    serviceType: 'premium',
    isBuildCoverage: true,
  };
  userApis.sendBehaviorLog(params);
};

const getLandbookVersion = (
  criteria: LandbookVersionCriteria
): LandbookVersion => {
  const { isWebview } = criteria;
  if (isWebview) {
    return 'app_3.0';
  }
  return 'web_3.0';
};

const getAgentInfo = async () => {
  const { os, browser } = getAgent();
  const criteria: LandbookVersionCriteria = {
    isWebview: browser.webview,
  };
  const landbookVersion = getLandbookVersion(criteria);
  const info = {
    browser: `${browser.name}/${browser.version}`,
    os: `${os.name} ${os.version}`,
    landbookVersion,
  };

  return info;
};

const loggingUtil = {
  sendAccessLog,
  sendAnalysisBasicLog,
  sendAnalysisPremiumLog,
  sendAnalysisPremiumRequestLog,
  getAgentInfo,
  getLandbookVersion,
  sendAiAnalyticsLog,
  sendAnalysisLightRequestLog,
};

export default loggingUtil;
