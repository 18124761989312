import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagingParams } from 'apis/clients/v2RestClient/type';
import notificationApis, {
  Notification,
  Notifications,
  defaultNotifications,
  defaultParams,
} from 'apis/notification';
import { AppThunk, RootState } from 'store/client';

type ApiStatus = 'idle' | 'fetching' | 'updating' | 'error';

type InitialState = {
  notification: Notification | null;
  notifications: Notifications;
  hasNew: boolean;
  hasMore: boolean;
  listApiStatus: ApiStatus;
  singleApiStatus: ApiStatus;
};

const initialState: InitialState = {
  notification: null,
  notifications: defaultNotifications,
  hasNew: false,
  hasMore: false,
  listApiStatus: 'idle',
  singleApiStatus: 'idle',
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<Notifications>) => {
      const { items, totalCount, currentPage, pageSize } = action.payload;

      if (action.payload.currentPage === 0) {
        state.notifications = action.payload;

        // 첫 페이지인 경우에만 신규 알림 유무 체크하기
        state.hasNew = items.some(({ isOpened }) => !isOpened);
      } else {
        state.notifications = {
          ...state.notifications,
          items: [...state.notifications.items, ...items],
          totalCount,
          currentPage,
          pageSize,
        };
      }

      state.hasMore = totalCount > (currentPage + 1) * pageSize;
    },
    setNotification: (state, action: PayloadAction<Notification>) => {
      state.notification = action.payload;
    },
    setListApiStatus: (state, action: PayloadAction<ApiStatus>) => {
      state.listApiStatus = action.payload;
    },
    setSingleApiStatus: (state, action: PayloadAction<ApiStatus>) => {
      state.singleApiStatus = action.payload;
    },
  },
});

export const fetchNotifications =
  (params?: PagingParams): AppThunk =>
  async dispatch => {
    dispatch(notificationSlice.actions.setListApiStatus('fetching'));

    const notifications = await notificationApis.fetchNotifications(params);

    dispatch(notificationSlice.actions.setNotifications(notifications));
    dispatch(notificationSlice.actions.setListApiStatus('idle'));
  };

export const fetchMoreNotifications =
  (): AppThunk => async (dispatch, getState) => {
    const { notification } = getState();
    const { currentPage, pageSize } = notification.notifications;

    dispatch(
      fetchNotifications({
        page: currentPage + 1,
        size: pageSize,
      })
    );
  };

export const refetchNotifications = (): AppThunk => async dispatch => {
  dispatch(fetchNotifications(defaultParams));
};

export const fetchNotificationById =
  (id: number): AppThunk =>
  async dispatch => {
    try {
      dispatch(notificationSlice.actions.setSingleApiStatus('fetching'));

      const notification = await notificationApis.fetchNotificationById(id);

      dispatch(notificationSlice.actions.setNotification(notification));
      dispatch(notificationSlice.actions.setSingleApiStatus('idle'));
    } catch (error) {
      dispatch(notificationSlice.actions.setSingleApiStatus('error'));
    }
  };

/**
 * @deprecated query사용 권장
 */
export const updateNotifications =
  (notificationIds?: number[]): AppThunk =>
  async dispatch => {
    try {
      await notificationApis.updateNotifications(notificationIds || []);
      dispatch(refetchNotifications());
    } catch (error) {}
  };

/**
 * @deprecated
 */
export const clearNotifications = (): AppThunk => dispatch => {
  dispatch(notificationSlice.actions.setNotifications(defaultNotifications));
};

/**
 * @deprecated
 */
export const selectNotification = (state: RootState) => state.notification;

export default notificationSlice.reducer;
