import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/client';
import { useAppSelector } from 'hooks';

interface AuctionListOptionSlice {
  isItemInMapOnly: boolean;
  selectedRegion: number[];
}

const initialState: AuctionListOptionSlice = {
  isItemInMapOnly: false,
  selectedRegion: [],
};

const auctionListOptionSlice = createSlice({
  name: 'autionListOption',
  initialState,
  reducers: {
    setIsItemInMapOnly: (state, action) => {
      state.isItemInMapOnly = action.payload;
    },
    setSelectedRegion: (state, action) => {
      state.selectedRegion = action.payload;
    },
  },
});

export const { setIsItemInMapOnly, setSelectedRegion } =
  auctionListOptionSlice.actions;

export const selectAuctionListOption = (state: RootState) =>
  state.auctionListOption;

export const useSelectAuctionListOption = () => {
  return useAppSelector(selectAuctionListOption);
};

export default auctionListOptionSlice.reducer;
