import { lazy } from 'react';
import storageUtil from './storageUtil';

const retryLazy = (componentImport: () => Promise<any>) =>
  lazy(async () => {
    try {
      const component = await componentImport();

      storageUtil.set('pageRefreshed', false);

      return component;
    } catch (error) {
      const pageAlreadyRefreshed = storageUtil.get('pageRefreshed', false);

      if (!pageAlreadyRefreshed) {
        storageUtil.set('pageRefreshed', true);

        return window.location.reload();
      }

      throw error;
    }
  });

export default retryLazy;
