import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SignInPayload } from 'apis/auth';
import authService from 'services/auth';
import { AppThunk, RootState } from 'store/client';
import loggingUtil from 'utils/loggingUtil';
import gtm from 'utils/gtm';
import sentry from 'utils/sentry';
import { fetchUser, resetUser, clearUserPreferences } from './userSlice';
import { User } from 'apis/user';

type AutoLoginStatus = 'idle' | 'inProgress' | 'done';
interface AuthState {
  autoLoginStatus: AutoLoginStatus;
  isSignedIn: boolean;
  /** 인앱결제 로그인 여부
   * @see https://docs.revenuecat.com/docs/user-ids
   */
  isPurcharseSignedIn: boolean;
}

const initialState: AuthState = {
  autoLoginStatus: 'idle',
  isSignedIn: false,
  isPurcharseSignedIn: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAutoLoginStatus: (state, action: PayloadAction<AutoLoginStatus>) => {
      state.autoLoginStatus = action.payload;
    },
    setIsSignedIn: (state, action: PayloadAction<boolean>) => {
      state.isSignedIn = action.payload;
    },
    setIsPurcharseSignedIn: (state, action: PayloadAction<boolean>) => {
      state.isPurcharseSignedIn = action.payload;
    },
  },
});

const signInWithToken =
  (token: string): AppThunk<Promise<User>> =>
  dispatch => {
    authService.updateToken({ token });
    return dispatch(updateStore());
  };

const signIn =
  (payload: SignInPayload): AppThunk<Promise<void>> =>
  async dispatch => {
    await authService.signIn(payload);
    dispatch(updateStore());
  };

const updateStore = (): AppThunk<Promise<User>> => dispatch => {
  loggingUtil.sendAccessLog();
  dispatch(authSlice.actions.setIsSignedIn(true));
  return dispatch(fetchUser());
};

const signOut = (): AppThunk<Promise<void>> => async dispatch => {
  await authService.signOut();
  dispatch(clearStore());
  dispatch(clearUserPreferences());

  sentry.resetUser();
  gtm.clearUser();
};

const deleteAccount =
  (reason?: string): AppThunk<Promise<void>> =>
  async dispatch => {
    await authService.deleteAccount(reason);
    dispatch(clearStore());
  };

const clearStore = (): AppThunk => async dispatch => {
  dispatch(authSlice.actions.setIsSignedIn(false));
  dispatch(resetUser());
};

const tryAutoLogin = (): AppThunk<Promise<void>> => async dispatch => {
  dispatch(authSlice.actions.setAutoLoginStatus('inProgress'));

  const authenticated = await authService.tryLoginWithSession();

  if (authenticated) {
    dispatch(updateStore());
  }

  dispatch(authSlice.actions.setAutoLoginStatus('done'));
};

export const { setIsPurcharseSignedIn } = authSlice.actions;
export { signIn, signInWithToken, signOut, deleteAccount, tryAutoLogin };
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
