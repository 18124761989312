import v2RestClient from 'apis/clients/v2RestClient';

import type { CoordsParams } from 'apis/parcelList';
import type { MapLatLng, Geometry } from 'utils/map';

export type CommonRegionInfo = {
  code: string;
  name: string;
  latitude: number;
  longitude: number;
  type: 'Sido' | 'Sigungu' | 'EubMyeonDong';
};

type GuInfo = CommonRegionInfo & {
  childs: CommonRegionInfo[];
};

type RegionInfo = CommonRegionInfo & {
  childs: GuInfo[];
};

type DongMap = {
  [dong: string]: CommonRegionInfo;
};

type GuMap = {
  [gu: string]: CommonRegionInfo &
    RegionChildren & {
      dongMap: DongMap;
    };
};

type RegionChildren = {
  children: CommonRegionInfo[];
};

type CityMap = {
  [city: string]: CommonRegionInfo &
    RegionChildren & {
      guMap: GuMap;
    };
};

export type RegionMap = RegionChildren & {
  cityMap: CityMap;
};

export type Region = {
  city: string;
  gu: string;
  dong: string;
  code: string;
};

export type RegionType = 'city' | 'gu' | 'dong';

const compareName = (childA: CommonRegionInfo, childB: CommonRegionInfo) =>
  childA.name.localeCompare(childB.name);

const toDongMap = (dongInfos: CommonRegionInfo[]) => {
  const dongMap: DongMap = {};

  dongInfos.forEach(dongInfo => {
    dongMap[dongInfo.name] = {
      code: dongInfo.code,
      name: dongInfo.name,
      latitude: dongInfo.latitude,
      longitude: dongInfo.longitude,
      type: dongInfo.type,
    };
  });

  return dongMap;
};

const toGuMap = (guInfos: GuInfo[]) => {
  const guMap: GuMap = {};

  guInfos.forEach(guInfo => {
    const children = guInfo.childs.sort(compareName);

    guMap[guInfo.name] = {
      code: guInfo.code,
      name: guInfo.name,
      latitude: guInfo.latitude,
      longitude: guInfo.longitude,
      type: guInfo.type,
      dongMap: toDongMap(guInfo.childs),
      children,
    };
  });

  return guMap;
};

const toRegionMap = (regionInfo: RegionInfo) => {
  const cityMap: CityMap = {};
  const commonInfo = {
    code: regionInfo.code,
    name: regionInfo.name,
    latitude: regionInfo.latitude,
    longitude: regionInfo.longitude,
    type: regionInfo.type,
  };
  const children = regionInfo.childs
    .map(({ childs, ...rest }) => rest)
    .sort(compareName);

  cityMap[regionInfo.name] = {
    ...commonInfo,
    guMap: toGuMap(regionInfo.childs),
    children,
  };

  const regionMap: RegionMap = {
    cityMap,
    children: [commonInfo],
  };

  return regionMap;
};

export type RegionCoords = {
  code: string;
  name: string;
  geometry: Geometry;
};

type RegionSearchParams = {
  q: string;
  exclude: 'childs' | 'geometry';
};

const fetchRegionMap = async (address: string) => {
  const params: RegionSearchParams = {
    q: address,
    exclude: 'geometry',
  };
  const response = await v2RestClient.get<RegionInfo>(`/regions/search`, {
    params,
  });

  return toRegionMap(response.data);
};

export const defaultRegionCoords: RegionCoords = {
  code: '',
  name: '',
  geometry: {
    type: 'Polygon',
    coordinates: [],
  },
};

const fetchRegionCoords = async (address: string) => {
  try {
    const params: RegionSearchParams = {
      q: address,
      exclude: 'childs',
    };
    const response = await v2RestClient.get<RegionCoords>(`/regions/search`, {
      params,
    });

    return response.data;
  } catch (error) {
    return defaultRegionCoords;
  }
};

const latLngToAddress = async (latLng: MapLatLng) => {
  try {
    const response = await v2RestClient.get<string>(`/regions/points/address`, {
      params: {
        lat: latLng.latitude,
        lon: latLng.longitude,
      },
    });

    return response.data;
  } catch (error) {
    return '';
  }
};

export const defaultInterestedRegion = {
  code: '',
  city: '',
  gu: '',
  dong: '',
};

const fetchInterestedRegion = async () => {
  const { data } = await v2RestClient.get<Region>('/interested/me/region');

  return data;
};

const updateInterestedRegion = async (region: Region) => {
  // 관심지역 업데이트에 실패한 경우 UI에서 피드백 제공하기 위해 try-catch 하지 않음
  const { data } = await v2RestClient.put<Region>(
    '/interested/me/region',
    region
  );

  return data;
};

const fetchRegions = async (params: Required<CoordsParams>) => {
  const { data } = await v2RestClient.get<CommonRegionInfo[]>('/regions', {
    params,
  });

  return data;
};

const regionApis = {
  fetchRegionMap,
  fetchRegionCoords,
  latLngToAddress,
  fetchInterestedRegion,
  updateInterestedRegion,
  fetchRegions,
};

export default regionApis;
