import authApis, { SignInPayload, TokenInfo } from 'apis/auth';
import userApis from 'apis/user';
import storageUtil from 'utils/storageUtil';

const setSession = (tokenInfo: TokenInfo) => {
  storageUtil.set('spwkSession', tokenInfo);
};

const hasSession = () => {
  return storageUtil.has('spwkSession');
};

const getSession = () => {
  const session = storageUtil.get('spwkSession');

  if (!session) {
    throw new Error('session not found');
  }

  return session as TokenInfo;
};

const clearSession = () => {
  return storageUtil.remove('spwkSession');
};

const tryLoginWithSession = async (): Promise<boolean> => {
  if (!hasSession()) {
    return false;
  }

  try {
    const session = getSession();

    await userApis.fetchUser({
      headers: {
        Authorization: `Bearer ${session.token}`,
      },
    });

    updateToken(session);
    return true;
  } catch (error) {
    const refreshResult = await refreshTokenAndUpdate();
    return Boolean(refreshResult);
  }
};

let tokenRefresherId: number | null = null;

const refreshTokenAndUpdate = async (): Promise<TokenInfo | null> => {
  try {
    const tokenInfo = getSession();
    const resp = await authApis.refreshToken({
      /** @ts-ignore */
      headers: {
        Authorization: `Bearer ${tokenInfo.token}`,
      },
    });

    updateToken(resp.data as TokenInfo);

    return resp.data as TokenInfo;
  } catch (error) {
    clearToken();
    return null;
  }
};

const clearReservedTokenRefresh = () => {
  if (tokenRefresherId !== null) {
    clearTimeout(tokenRefresherId);
  }
};

const updateToken = (tokenInfo: TokenInfo) => {
  setSession(tokenInfo);
  authApis.setAuthToken(tokenInfo.token);
};

const clearToken = () => {
  clearSession();
  authApis.clearAuthToken();
  clearReservedTokenRefresh();
};

const signIn = async (payload: SignInPayload) => {
  const resp = await authApis.signIn(payload);
  const tokenInfo = resp.data as TokenInfo;
  updateToken(tokenInfo);
};

const signOut = async () => {
  await authApis.signOut();

  clearToken();
};

const deleteAccount = async (reason?: string) => {
  await authApis.deleteAccount(reason);
  clearToken();
};

const authService = {
  tryLoginWithSession,
  updateToken,
  refreshTokenAndUpdate,
  clearToken,
  signIn,
  signOut,
  deleteAccount,
};

export default authService;
