import { InsightJson } from 'apis/parcel';
import {
  isLanduseZoneValid,
  validateLanduseZoneValue,
} from 'utils/aiAnalysisUtil';
import { Parcel } from 'services/parcel';
import { BASIC_SERVICE_REGION } from 'components/Search/localUtil';
import { STAGING_ENTERPRICE_MAX_AREA } from 'constants/area';

export type ErrorType =
  | 'outOfArea'
  | 'isNotResidentialArea'
  | 'multipleLanduseZone'
  | 'jimokCostCanBeIncurred';

export interface ErrorInfo<T extends ErrorType = ErrorType> {
  type: T;
  message: string;
}

export const InValidBasicParcelErrorMessage: {
  [Type in ErrorType]: ErrorInfo<Type>;
} = {
  outOfArea: {
    type: 'outOfArea',
    message: '토지 면적 70~400m² 내에서만 제공합니다',
  },

  isNotResidentialArea: {
    type: 'isNotResidentialArea',

    message: '도시지역 내에서만 제공합니다',
  },

  multipleLanduseZone: {
    type: 'multipleLanduseZone',
    message: '하나의 용도지역에서만 제공합니다',
  },

  jimokCostCanBeIncurred: {
    type: 'jimokCostCanBeIncurred',
    message: '대지로 지목 변경이 필요합니다',
  },
};

const NotificationTypes = {
  isolated: {
    type: 'isolated',
    message: '맹지일 가능성이 있습니다',
  },
  isSolarSetbackByHeight: {
    // 이 메세지를 볼 수 있는 pnu: 2647010100112450001
    type: 'isSolarSetbackByHeight',
    message:
      '토지의 경사도가 반영된 검토결과입니다. 실제 건축 시, 허가권자의 해석에 따라 기준이 다르게 적용될 수 있습니다',
  },
  setback: {
    type: 'setback',
    message: '건축선이 후퇴되었습니다',
  },
  jimok: {
    type: 'jimok',
    message: '대지로 지목 변경이 필요합니다',
  },
  districtPlanning: {
    type: 'districtPlanning',
    message:
      '지구단위계획이 적용된 필지입니다. 도시계획에 따른 실제결과와 다를 수 있습니다.',
  },
};

/**
 * 주거지역 여부
 */
export const isResidenceArea = (landuseZone: string) => {
  return (
    landuseZone.includes('제1종일반주거지역') ||
    landuseZone.includes('제2종일반주거지역') ||
    landuseZone.includes('제3종일반주거지역') ||
    landuseZone.includes('제1종전용주거지역') ||
    landuseZone.includes('제2종전용주거지역')
  );
};

/**
 * 맹지일 가능성을 계산한다.
 */
export const getIsolationProbability = (
  lotRoad: string,
  icpPotentialLandLocked: boolean
) => {
  const isBull = lotRoad && lotRoad.includes('불');
  const isIsolated = lotRoad && lotRoad.includes('맹지');
  const potentialIsolation = icpPotentialLandLocked;

  let isolationProbability = 0;
  if (isIsolated) {
    isolationProbability = 1;
  } else if (isBull && potentialIsolation) {
    isolationProbability = 0.5;
  }

  return isolationProbability;
};

/**
 * 다용도 지역 여부
 */
export const doesContainInvalidLanduseZone = (landuseZone: string) => {
  const isValid = validateLanduseZoneValue(landuseZone);

  return isValid === null ? false : true;
};

const isBetween = (param: { min: number; max: number; value: number }) =>
  param.min <= param.value && param.max >= param.value;

/**
 * 베이직 신축분석 유효범위
 */
export const isValidArea = (connectionArea: number) =>
  isBetween({
    min: 70,
    max: 400,
    value: connectionArea,
  });

/**
 * 베이직 신축분석 유효범위(enterprise용)
 */
export const isValidEnterpriseArea = (connectionArea: number) =>
  isBetween({
    min: 70,
    max: STAGING_ENTERPRICE_MAX_AREA,
    value: connectionArea,
  });
/**
 * 허용가능한 지목 여부
 */
export const isJimokAllowedAutoSchemata = (jimok: string) => {
  const jimokList = [
    '대지',
    '공장',
    '창고',
    '잡종지',
    '전',
    '답',
    '과수원',
    '임야',
    '목장',
  ];
  return jimokList.includes(jimok);
};

export class InvalidError extends Error {
  type: ErrorType;
  constructor(errorInfo: ErrorInfo) {
    super(errorInfo.message);

    this.name = 'invalidError';
    this.type = errorInfo.type;
  }
}

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'notfound';
  }
}

export const isInBasicServiceArea = (address: string) =>
  BASIC_SERVICE_REGION.some(region => address.startsWith(region));

/**
 * 베이직 분석 유효성 검증
 * @param parcel
 * @param hasModelingInfo
 */
export const basicParcelInfoValidator = (
  parcel: Parcel,
  hasModelingInfo: boolean,
  isEnterpriceRole?: boolean
): ErrorInfo | boolean => {
  const { parcel_info } = parcel;
  const { connection_area, landuse_zone, jimok, address } = parcel_info;

  if (!isInBasicServiceArea(address)) {
    throw new NotFoundError('수도권, 부산 지역에서만 제공합니다');
  } else if (
    isEnterpriceRole
      ? !isValidEnterpriseArea(connection_area)
      : !isValidArea(connection_area)
  ) {
    // '토지 면적 70~400m² 내에서만 제공합니다'
    throw new InvalidError(InValidBasicParcelErrorMessage.outOfArea);
  } else if (!isLanduseZoneValid(landuse_zone)) {
    // if (hasMultipleLanduseZone(landuse_zone)) {
    //   throw new InvalidError(
    //     InValidBasicParcelErrorMessage.multipleLanduseZone
    //   );
    //   // '하나의 용도지역에서만 제공합니다'
    // }
    throw new InvalidError(InValidBasicParcelErrorMessage.isNotResidentialArea);
    //  '전용주거지역, 일반주거지역 내에서만 제공합니다'
  } else if (!isJimokAllowedAutoSchemata(jimok)) {
    throw new InvalidError(
      InValidBasicParcelErrorMessage.jimokCostCanBeIncurred
    );
    // '대지로 지목 변경이 필요합니다'
  } else if (!hasModelingInfo) {
    throw new NotFoundError('건축분석 결과가 없습니다.');
  }

  return true;
};

// error를 던지지 않고, parcelInfo 만으로 smallHousing에 콜 할 필요가 있는 pnu인지를 확인.
export const checkParcelInfoValidation = (
  parcel: Parcel | undefined,
  isEnterpriceRole?: boolean
) => {
  if (!parcel) {
    return false;
  } else {
    const { connection_area, landuse_zone, jimok, address } =
      parcel.parcel_info;
    const validParcel =
      isInBasicServiceArea(address) &&
      (isEnterpriceRole
        ? isValidEnterpriseArea(connection_area)
        : isValidArea(connection_area)) &&
      isLanduseZoneValid(landuse_zone) &&
      isJimokAllowedAutoSchemata(jimok);
    return validParcel;
  }
};

export const getInsightJsonNotification = (insightJson: InsightJson) => {
  if (!insightJson?.is_solar_setback) {
    return [];
  }
  const isSetback = insightJson.is_archi_line_setback;
  const isSolarSetbackByHeight = insightJson.is_solar_setback;
  const result = [];
  if (isSetback) {
    result.push(NotificationTypes.setback);
  }
  if (isSolarSetbackByHeight) {
    result.push(NotificationTypes.isSolarSetbackByHeight);
  }

  if (result.length === 0) {
    return [];
  }
  return result;
};

export const getParcelNotification = (
  jimok: string,
  lotRoad: string,
  icpPotentialLandLock: boolean,
  isPlanningDistrict: boolean
) => {
  const isIsolation =
    getIsolationProbability(lotRoad, icpPotentialLandLock) >= 1;
  const isJimok = !jimok.includes('대지');
  const result = [];
  if (isIsolation) {
    result.push(NotificationTypes.isolated);
  }
  if (isJimok) {
    result.push(NotificationTypes.jimok);
  }
  if (isPlanningDistrict) {
    result.push(NotificationTypes.districtPlanning);
  }

  if (result.length === 0) {
    return [];
  }

  return result;
};
