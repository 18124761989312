/**
 * 모바일, 데스크탑 공통경로
 */
export const COMMON_APP_ROUTES = {
  /**
   * 데스크탑의 경우 메인홈
   */
  ROOT: '/',
  /** 지도 홈 */
  HOME: '/home',
  /** 랜드북 VE건축 */
  BUILDING_PM: '/pm',
  /** 건축 소개 */
  BUILDING_CASE: '/buildingcase',
  PROMOTIONS: '/promotions',
  PROMOTIONS_PREMIUM: '/promotions/premium',
  SERVICE: '/service',
  ADMIN: '/admin',
  /** ai 건축분석 소개 페이지 */
  AI_ANALYTICS_SERVICE: '/service/ai-analytics',
  AUTH: '/auth',
  SEARCH: '/search',
  SEARCH_MAP: '/search/map',
  BOOKMARKS: '/bookmarks',
  ANALYTICS: '/analytics',
  ANALYTICS_BASIC: '/analytics/basic',
  /** AI건축분석 설계 유형 리스트 페이지  (구독제)*/
  ANALYTICS_LIGHT: '/analytics/light',
  /** AI건축분석 설계 유형 리스트 페이지 */
  ANALYTICS_PREMIUM: '/analytics/premium',
  ADMIN_ANALYTICS_PREMIUM: '/analytics/premium/admin',
  /** AI건축분석 라이트버전 설계 유형 상세 페이지 (구독제)*/
  ANALYTICS_LIGHT_SCENARIO: '/analytics/light/scenario',
  ANALYTICS_LIGHT_SAMPLE: '/analytics/light/sample',
  ANALYTICS_LIGHT_SAMPLE_DETAILE: '/analytics/light/sample/scenario',
  /** AI건축분석 설계 유형 상세 페이지 */
  ANALYTICS_PREMIUM_SCENARIO: '/analytics/premium/scenario',
  ANALYTICS_PREMIUM_SAMPLE: '/analytics/premium/sample',
  /** 셈플 상세 페이지 */
  ANALYTICS_PREMIUM_SAMPLE_DETAILE: '/analytics/premium/sample/scenario',
  /** 요청한 AI건축분석 목록 */
  ANALYTICS_PREMIUMS: '/analytics/premiums',
  ANALYTICS_MERGED: '/analytics/merged',
  ACCOUNT: '/account',
  /** 쿠폰함 */
  COUPON: '/account/coupon',
  /** 사용중인 이용권 */
  PROFILE: '/account/profile',
  LINK_ACCOUNT: '/account/profile/link',
  /** 분석 구매내역 */
  PAYMENT_HISTORY: '/account/payment/history',
  /** 분석 구매내역 */
  LIGHT_MEMBERSHIP_HISTORY: '/account/payment/membershipHistory',
  QNA: '/qna',
  /** 로드뷰 */
  ROADVIEW: '/roadview',
  /** 알림 */
  NOTIFICATIONS: '/notifications',
  /** 알림 */
  SUCCESS_MEMBERSHIP: '/success/membership',
  /** 분석 기준 */
  CRITERIA: '/criteria',
  CRITERIA_LANDPRICE: '/criteria/landPrice',
  CRITERIA_ARCHITECTURE: '/criteria/architecture',
  CRITERIA_BUSINESS: '/criteria/business',
  CRITERIA_DATA_STANDARD: '/criteria/dataStandard',
  CRITERIA_PREMIUM: '/criteria/premium',
  AUCTION: '/auction',
  MEMBERSHIP: '/membership',
  /** 분석 구매내역 */
  MEMBERSHIP_INFO: '/memebershipChat',
  MAIN_MEMBERSHIP: '/main/membership',
} as const;

/**
 * 모바일웹 경로
 */
export const NEXT_APP_ROUTES = {
  ...COMMON_APP_ROUTES,
  HOME_INTRODUCE: '/home/introduce',
  HOME_INTRO_ANALYSIS: '/home/details/analysis',
  HOME_INTRO_ESTIMATION: '/home/details/estimation',
  /** 모바일 본인인증 완료 리다이렉트 경로 */
  CERTIFICATION_COMPLETE: '/auth/certification/complete',
  ANALYTICS_SAMPLE: '/analytics/sample',
  ANALYTICS_AUTH: '/analytics/auth',
  ANALYTICS_LOCATION: '/analytics/location',
  ACCOUNT_NOTIFICATION: '/account/notification',
  /** 공지 */
  NOTICES: '/notices',
  PAYMENT_HISTORY: '/account/payment/history',
  /** 결제완료 모바일 리다이렉트 경로 */
  LIGHT_MEMBERSHIP_HISTORY: '/account/payment/membershipHistory',
  PAYMENT_COMPLETE: '/account/payment/complete',
  /** 마케팅 푸시 알림  */
  MARKETING_NOTIFICATION: '/push_notifications',

  /** 설문조사 */
  SURVEY: '/survey',
} as const;

export type NextAppRoutes =
  typeof NEXT_APP_ROUTES[keyof typeof NEXT_APP_ROUTES];

export const NEXT_APP_ROUTES_DESKTOP = {
  ...COMMON_APP_ROUTES,
  /** 건축 컨설팅 */
  CONSULTING: '/consulting',
  // /** 랜드북 VE건축 */
  // BUILDING_PM: '/pm',
  // /** 건축 소개 */
  // BUILDING_CASE: '/buildingcase',

  /** 무료 컨설팅 신청 */
  APPLY_CONSULTING: '/application',
} as const;

export type NextAppRoutesDesktop =
  typeof NEXT_APP_ROUTES_DESKTOP[keyof typeof NEXT_APP_ROUTES_DESKTOP];

export const CURRENT_APP_ROUTES = {
  ANALYTICS_PARCEL: '/analytics/parcel',
  BOOKMARK: '/analytics/bookmark',
} as const;
