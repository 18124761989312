import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import regionApis, {
  RegionMap,
  Region,
  RegionCoords,
  defaultRegionCoords,
  defaultInterestedRegion,
} from 'apis/region';
import { AppThunk, RootState } from 'store/client';

export const SERVICE_AREA_LIST = [
  {
    CODE: '11',
    NAME: '서울',
  },
  {
    CODE: '28',
    NAME: '인천',
  },
  {
    CODE: '41',
    NAME: '경기',
  },
] as const;

export const ARTICLE_SERVICE_AREA_STRING = SERVICE_AREA_LIST.map(
  item => item.NAME
).join(', ');

export const DEFAULT_CITY = SERVICE_AREA_LIST[0];

export type InterestedRegionStatus = 'idle' | 'fetching' | 'updating' | 'error';

type RegionState = {
  regionMap: RegionMap;
  regionCoords: RegionCoords;
  interestedRegion: Region;
  interestedRegionStatus: InterestedRegionStatus;
};

const initialState: RegionState = {
  regionMap: {
    cityMap: {},
    children: [],
  },
  regionCoords: defaultRegionCoords,
  interestedRegion: defaultInterestedRegion,
  interestedRegionStatus: 'idle',
};

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    setRegionMap: (state, action: PayloadAction<RegionMap>) => {
      state.regionMap = action.payload;
    },
    setRegionCoords: (state, action: PayloadAction<RegionCoords>) => {
      state.regionCoords = action.payload;
    },
    setInterestedRegion: (state, action: PayloadAction<Region>) => {
      state.interestedRegion = action.payload;
    },
    setInterestedRegionStatus: (
      state,
      action: PayloadAction<InterestedRegionStatus>
    ) => {
      state.interestedRegionStatus = action.payload;
    },
  },
});

export const fetchRegionMap =
  (addressList: string[]): AppThunk =>
  async dispatch => {
    const promiseList = addressList.map(regionApis.fetchRegionMap);
    const regionMapList = await Promise.all(promiseList);
    const mergedRegionMap = regionMapList.reduce((prev, cur) => ({
      cityMap: {
        ...prev.cityMap,
        ...cur.cityMap,
      },
      children: [...prev.children, ...cur.children],
    }));

    if (mergedRegionMap) {
      dispatch(regionSlice.actions.setRegionMap(mergedRegionMap));
    }
  };

export const fetchRegionCoords =
  (address: string): AppThunk =>
  async dispatch => {
    const regionCoords = await regionApis.fetchRegionCoords(address);

    dispatch(regionSlice.actions.setRegionCoords(regionCoords));
  };

export const fetchInterestedRegion = (): AppThunk => async dispatch => {
  try {
    dispatch(regionSlice.actions.setInterestedRegionStatus('fetching'));

    const interestedRegion = await regionApis.fetchInterestedRegion();
    dispatch(regionSlice.actions.setInterestedRegion(interestedRegion));
    dispatch(regionSlice.actions.setInterestedRegionStatus('idle'));
  } catch (error) {
    dispatch(regionSlice.actions.setInterestedRegionStatus('error'));
  }
};

export const clearInterestedRegion = (): AppThunk => dispatch => {
  dispatch(regionSlice.actions.setInterestedRegion(defaultInterestedRegion));
};

export const updateInterestedRegion =
  (region: Region): AppThunk =>
  async dispatch => {
    try {
      dispatch(regionSlice.actions.setInterestedRegionStatus('updating'));

      await regionApis.updateInterestedRegion(region);
      dispatch(regionSlice.actions.setInterestedRegion(region));
      dispatch(regionSlice.actions.setInterestedRegionStatus('idle'));
    } catch (error) {
      dispatch(regionSlice.actions.setInterestedRegionStatus('error'));
    }
  };

export const selectRegion = (state: RootState) => state.region;

export default regionSlice.reducer;
