import v2RestClient from './clients/v2RestClient';

/**
 * kakao local API
 * @description 로컬(local) API는 키워드로 특정 장소 정보를 조회하거나,
 * 좌표를 주소 또는 행정구역으로 변환하는 등 장소에 대한 정보를 제공합니다.
 * 특정 카테고리로 장소를 검색하는 등 폭넓은 활용이 가능하며,
 * 지번 주소와 도로명 주소 체계를 모두 지원합니다.
 *
 * @see https://developers.kakao.com/docs/latest/ko/local/common#intro
 */

/**
 * 카테고리 그룹 코드
 * @property {string} MT1 대형마트
 * @property {string} CS2 편의점
 * @property {string} PS3 어린이집,유치원
 * @property {string} SC4 학교
 * @property {string} AC5 학원
 * @property {string} PK6 주차장
 * @property {string} OL7 주유소,충전소
 * @property {string} SW8 지하철역
 * @property {string} BK9 은행
 * @property {string} CT1 문화시설
 * @property {string} AG2 중개업소
 * @property {string} PO3 공공기관
 * @property {string} AT4 관광명소
 * @property {string} AD5 숙박
 * @property {string} FD6 음식점
 * @property {string} CE7 카페
 * @property {string} HP8 병원
 * @property {string} PM9 약국
 */
enum CategoryGroupCode {
  MT1 = 'MT1',
  CS2 = 'CS2',
  PS3 = 'PS3',
  SC4 = 'SC4',
  AC5 = 'AC5',
  PK6 = 'PK6',
  OL7 = 'OL7',
  SW8 = 'SW8',
  BK9 = 'BK9',
  CT1 = 'CT1',
  AG2 = 'AG2',
  PO3 = 'PO3',
  AT4 = 'AT4',
  AD5 = 'AD5',
  FD6 = 'FD6',
  CE7 = 'CE7',
  HP8 = 'HP8',
  PM9 = 'PM9',
}

export type FetchPlaceByKeywordReq = {
  query: string;
  category_group_code?: CategoryGroupCode;
  x?: string;
  y?: string;
  radius?: number;
  rect?: string;
  page?: number;
  size?: number;
  sort?: string;
};

/**
 * RegionInfo	질의어의 지역 및 키워드 분석 정보
 * @description (11.04.26) 정의된 타입 정보를 확인할 수 없다.
 */
type RegionInfo = any;

/**
 * @property {number} total_count 검색어에 검색된 문서 수
 * @property {number} pageable_count total_count 중 노출 가능 문서 수 (최대값: 45)
 * @property {boolean} is_end 현재 페이지가 마지막 페이지인지 여부, 값이 false면 page를 증가시켜 다음 페이지를 요청할 수 있음
 */
type Meta = {
  total_count: number;
  pageable_count: number;
  is_end: boolean;
};

/**
 * 키워드로 장소 검색 결과
 */
export type PlaceByKeyword = {
  id: string; // 	장소 ID
  place_name: string; //장소명, 업체명
  category_name: string; // 카테고리 이름
  category_group_code: string; // 중요 카테고리만 그룹핑한 카테고리 그룹 코드
  category_group_name: string; // 중요 카테고리만 그룹핑한 카테고리 그룹명
  phone: string; // 	전화번호
  address_name: string; // 	전체 지번 주소
  road_address_name: string; // 	전체 도로명 주소
  x: string; // X 좌표값, 경위도인 경우 longitude (경도)
  y: string; // Y 좌표값, 경위도인 경우 latitude(위도)
  place_url: string; // 	장소 상세페이지 URL
  distance: string; // 중심좌표까지의 거리 (단, x,y 파라미터를 준 경우에만 존재) 단위 meter
};

export type FetchPlaceByKeywordRes = {
  meta: Meta & {
    same_name: RegionInfo;
  };
  same_name: {
    region: string[]; //질의어에서 인식된 지역의 리스트 예: '중앙로 맛집' 에서 중앙로에 해당하는 지역 리스트
    keyword: string; // 질의어에서 지역 정보를 제외한 키워드 예: '중앙로 맛집' 에서 '맛집'
    selected_region: string; //인식된 지역 리스트 중, 현재 검색에 사용된 지역 정보
  };
  documents: PlaceByKeyword[];
};

/**
 * 키워드로 장소검색
 * @param  {string} query 검색을 원하는 질의어
 * @param  {string} category_group_code 카테고리 그룹 코드, 결과를 카테고리로 필터링을 원하는 경우 사용
 * @param  {string} x 중심 좌표의 X값 혹은 longitude, 특정 지역을 중심으로 검색하려고 할 경우 radius와 함께 사용 가능
 * @param  {string} y 중심 좌표의 Y값 혹은 latitude, 특정 지역을 중심으로 검색하려고 할 경우 radius와 함께 사용 가능
 * @param  {number} radius 중심 좌표부터의 반경거리. 특정 지역을 중심으로 검색하려고 할 경우 중심좌표로 쓰일 x,y와 함께 사용 단위 meter, 0~20000 사이의 값
 * @param  {string} rect 사각형 범위내에서 제한 검색을 위한 좌표. 지도 화면 내 검색시 등 제한 검색에서 사용 가능. 좌측 X 좌표,좌측 Y 좌표, 우측 X 좌표, 우측 Y 좌표 형식
 * @param  {number} page 결과 페이지 번호, 1~45 사이의 값 (기본값: 1)
 * @param  {number} size 한 페이지에 보여질 문서의 개수 1~15 사이의 값 (기본값: 15)
 * @param  {string} sort 결과 정렬 순서, distance 정렬을 원할 때는 기준 좌표로 쓰일 x, y와 함께 사용
 * @see https://developers.kakao.com/docs/latest/ko/local/dev-guide
 */
export const fetchPlaceByKeyword = (params: FetchPlaceByKeywordReq) =>
  v2RestClient.get<FetchPlaceByKeywordRes>(`/parcels/search/keyword`, {
    params,
  });

export type FetchLocalByAddressReq = {
  query: string;
  analyze_type?: string;
  page?: number;
  size?: number;
};

/**
 * 지번주소 상세정보
 * @property {string} address_name	전체 지번 주소
 * @property {string} region_1depth_name 지역 1 Depth, 시도 단위
 * @property {string} region_2depth_name 지역 2 Depth, 구 단위
 * @property {string} region_3depth_name 지역 3 Depth, 동 단위
 * @property {string} region_3depth_h_name 	지역 3 Depth, 행정동 명칭
 * @property {string} h_code 	행정 코드
 * @property {string} b_code 법정 코드
 * @property {string} mountain_yn 	산 여부, Y 또는 N
 * @property {string} main_address_no 지번 주번지
 * @property {string} sub_address_no 	지번 부번지. 없을 경우 ""
 * @property {string} zip_code Deprecated 우편번호(6자리) 공지사항 참고
 * @property {string} x X 좌표값, 경위도인 경우 longitude (경도)
 * @property {string} y Y 좌표값, 경위도인 경우 latitude(위도)
 */
type Address = {
  address_name: string;
  region_1depth_name: string;
  region_2depth_name: string;
  region_3depth_name: string;
  region_3depth_h_name: string;
  h_code: string;
  b_code: string;
  mountain_yn: string;
  main_address_no: string;
  sub_address_no: string;
  zip_code?: string;
  x: string;
  y: string;
};

/**
 * 도로명 주소 상세 정보
 * @property {string} address_name 전체 도로명 주소
 * @property {string} region_1depth_name 	지역명1
 * @property {string} region_2depth_name 	지역명2
 * @property {string} region_3depth_name 지역명3
 * @property {string} road_name  도로명
 * @property {string} underground_yn 	지하 여부, Y 또는 N
 * @property {string} main_building_no 건물 본번
 * @property {string} sub_building_no 	건물 부번. 없을 경우 ""
 * @property {string} building_name 건물 이름
 * @property {string} zone_no 우편번호(5자리)
 * @property {string} x X 좌표값, 경위도인 경우 longitude (경도)
 * @property {string} y Y 좌표값, 경위도인 경우 latitude(위도)
 */
type RoadAddress = {
  address_name: string;
  region_1depth_name: string;
  region_2depth_name: string;
  region_3depth_name: string;
  road_name: string;
  underground_yn: string;
  main_building_no: string;
  sub_building_no: string;
  building_name: string;
  zone_no: string;
  x: string;
  y: string;
};

/**
 * @property REGION 지명
 * @property REGION_ADDR 지명주소
 * @property ROAD 도로명
 * @property ROAD_ADDR 도로명 주소
 */
type AddressType = 'REGION' | 'REGION_ADDR' | 'ROAD' | 'ROAD_ADDR';

/**
 * 공통 주소정보
 * @property {string} address_name 	전체 지번 주소 또는 전체 도로명 주소, 입력에 따라 결정됨
 * @property {string} address_type address_name의 값의 타입(Type)  REGION(지명), ROAD(도로명), REGION_ADDR(지번 주소), ROAD_ADDR (도로명 주소) 중 하나
 * @property {string} x X 좌표값, 경위도인 경우 longitude (경도)
 * @property {string} y Y 좌표값, 경위도인 경우 latitude(위도)
 */
interface AddressDocument {
  address_name: string;
  address_type: AddressType;
  x: string;
  y: string;
}

/**
 * 지명
 */
export interface RegionDocument extends AddressDocument {
  address_type: 'REGION';
  address: Address;
}

/**
 * 지명주소
 */
export interface RegionAddressDocument
  extends Omit<RegionDocument, 'address_type'> {
  address_type: 'REGION_ADDR';
  road_address: RoadAddress;
}

/**
 * 도로주소
 * @property {RoadAddress} road_address	도로명 주소 상세 정보, 아래 RoadAddress 항목 구성 요소 참고
 */
interface RoadDocument extends AddressDocument {
  address_type: 'ROAD';
  road_address: RoadAddress;
}

/**
 * 도로주소
 * @property {RoadAddress} road_address	도로명 주소 상세 정보, 아래 RoadAddress 항목 구성 요소 참고
 */
interface RoadAddressDocument extends Omit<RoadDocument, 'address_type'> {
  address_type: 'REGION_ADDR';
  address: Address;
  road_address: RoadAddress;
}

export type LocalDocumentByAddress =
  | RoadDocument
  | RoadAddressDocument
  | RegionDocument
  | RegionAddressDocument;

export type FetchLocalByAddressRes = {
  meta: Meta;
  documents: LocalDocumentByAddress[];
};

export type LocalDocument = LocalDocumentByAddress | PlaceByKeyword;

/**
 * 주소를 지도 위에 정확하게 표시하기 위해 해당 주소의 좌표 정보를 제공하는 API로 주소에 해당하는 지번 주소, 도로명 주소, 좌표, 우편번호, 빌딩명 등의 다양한 정보를 함께 제공합니다
 */
export const fetchLocalByAddress = (params: FetchLocalByAddressReq) =>
  v2RestClient.get<FetchLocalByAddressRes>(`/parcels/search/address`, {
    params,
  });

/**
 * 검색 결과가 주소임을 구분합니다.
 * 검색 결과는 주소 혹은 장소입니다.
 */
export const isAddress = (
  documents: LocalDocument
): documents is LocalDocumentByAddress =>
  documents?.hasOwnProperty('address_type');

/**
 * 구주소 타입
 */
export const isRegionType = (
  documents: LocalDocument
): documents is RegionDocument | RegionAddressDocument => {
  const addressType = (documents as LocalDocumentByAddress)?.address_type;

  if (addressType) {
    return ['REGION', 'REGION_ADDR'].includes(addressType);
  }
  return false;
};

/**
 * 도로주소 타입
 */
export const isRoadType = (
  documents: LocalDocument
): documents is RoadDocument | RoadAddressDocument => {
  const addressType = (documents as LocalDocumentByAddress)?.address_type;

  if (addressType) {
    return ['ROAD', 'ROAD_ADDR'].includes(addressType);
  }

  return false;
};

export type SeachHistory = {
  latitude: number; // 위도 (latitude)
  longitude: number; // 경도(longitude)
  address_name: string; // 전체 주소 (address_name)
  region_1depth_name?: string;
  region_2depth_name?: string;
  region_3depth_name?: string;
  region_3depth_h_name?: string;
  place_name?: string;
};

export type RegistedSearchHistory = SeachHistory & {
  id: string; // history id
  createdAt: string;
  updatedAt: string;
};

/**
 * 최근 검색 결과 조회
 */
const fetchSearchHistory = () =>
  v2RestClient.get<RegistedSearchHistory[]>('/articles/me/search/histories');

/**
 * 최근 검색 항목 개별 삭제.
 */
const deleteSearchHistory = (id: string) =>
  v2RestClient.delete(`/articles/me/search/histories/${id}`);

/**
 * 최근 검색 항목 전체 삭제.
 */
const deleteAllSearchHistory = () =>
  v2RestClient.delete(`/articles/me/search/histories`);

/**
 * 최근 검색 항목 개별등록
 */
const postSearchHistory = (history: SeachHistory) =>
  v2RestClient.post(`/articles/me/search/histories`, history);

const searchApis = {
  fetchPlaceByKeyword,
  fetchLocalByAddress,
  fetchSearchHistory,
  postSearchHistory,
  deleteSearchHistory,
  deleteAllSearchHistory,
};

export default searchApis;
