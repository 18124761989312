import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store/client';

export type AnalyticsTabName =
  /** 토지 건물 현황 */
  | 'landInfo'
  /** 신축분석 */
  | 'buildingInfo'
  /** 사업성분석 */
  | 'profitInfo'
  /** 입지정보 */
  | 'locationInfo'
  /** 추정가 */
  | 'landPriceInfo'
  // /** 실거래 사례 */
  // | 'realTradingInfo'
  /** 공시지가 */
  | 'officialLandValue'
  | 'floorInfo'
  /** 경매정보 */
  | 'auction'
  /** 소유정보 */
  | 'proprietaryInfo'
  /** 시세분석 */
  | 'marketPrice'
  /** 공사비용 */
  | 'constructionCost';

interface AnalyticsTabSlice {
  clickedTab: AnalyticsTabName | '';
  isLandInfoVisible: boolean;
  isBuildingInfoVisible: boolean;
  isProfitInfoVisible: boolean;
  isLocationInfoVisible: boolean;
  status: 'idle' | 'inProgress';
}

const initialState: AnalyticsTabSlice = {
  clickedTab: '',
  isLandInfoVisible: false,
  isBuildingInfoVisible: false,
  isProfitInfoVisible: false,
  isLocationInfoVisible: false,
  status: 'idle',
};

const analyticsTabSlice = createSlice({
  name: 'analyticsTab',
  initialState,
  reducers: {
    /**
     * @version v1
     * @deprecated
     */
    setLandInfoVisible: (state, action) => {
      state.isLandInfoVisible = action.payload;
    },
    /**
     * @version v1
     * @deprecated
     */
    setBuildingInfoVisible: (state, action) => {
      state.isBuildingInfoVisible = action.payload;
    },
    /**
     * @version v1
     * @deprecated
     */
    setProfitInfoVisible: (state, action) => {
      state.isProfitInfoVisible = action.payload;
    },
    /**
     * @version v1
     * @deprecated
     */
    setLocationInfoVisible: (state, action) => {
      state.isLocationInfoVisible = action.payload;
    },

    /**
     * @version v1
     * @deprecated
     */
    setClickedTab: (state, action) => {
      state.clickedTab = action.payload;
    },
    /**
     * @version v1
     * @deprecated
     */
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const {
  setClickedTab,
  setStatus,
  setLandInfoVisible,
  setBuildingInfoVisible,
  setProfitInfoVisible,
  setLocationInfoVisible,
} = analyticsTabSlice.actions;

export const selectAnalyticsTab = (state: RootState) => state.analyticsTab;

export const selectCurrentActiveTab = ({ analyticsTab }: RootState) =>
  analyticsTab.clickedTab;

export default analyticsTabSlice.reducer;
