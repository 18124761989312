import { MergedParcelData } from 'services/mergedParcel';
import dedupe from 'utils/dedupe';
import v2RestClient from './clients/v2RestClient';
import { InsightJson } from './parcel';

const fetchMergedParcel = (pnus: string[]) => {
  return v2RestClient.get<MergedParcelData>(`insight/merged_parcels`, {
    params: {
      merged_pnu_list: pnus,
    },
  });
};

export interface MergedSmallHousingParams {
  pnus: string[];
  eval_type: string;
  bcr?: number | string;
  far?: number | string;
  max_floor?: number | string;
}

const fetchMergedSmallHousing = (params: MergedSmallHousingParams) => {
  const { pnus } = params;
  if (pnus.length > 1) {
    const firstElement = pnus[0];
    const sortedRest = pnus.slice(1).sort((a, b) => {
      return BigInt(a) > BigInt(b) ? 1 : -1;
    });
    params.pnus = [firstElement, ...sortedRest];
  }

  const mergedSmallHousing = v2RestClient
    .get<InsightJson>(`basic/small_housings`, {
      params,
      timeout: 30000,
    })
    .then(res => res);
  return mergedSmallHousing;
};

interface MergedProfitScenarioParams {
  merged_pnu_list: string[];
  fa_ratio: number;
  max_gf_area: number;
}
const fetchMergedProfitScenario = (params: MergedProfitScenarioParams) => {
  return v2RestClient.get(`insight/merged_profit_scenarios`, {
    params,
  });
};

export type CheckMergeableResponse = {
  merged_parcels: {
    pnu: string[] | null;
  };
};
export type CheckMergeableMessageResponse = {
  messages: string[];
};

const requestCheckMergeable = (lat: number, lon: number, pnus: string[]) => {
  return v2RestClient.get<
    CheckMergeableResponse | CheckMergeableMessageResponse
  >(`insight/check_mergeables`, {
    params: {
      lat,
      lon,
      selected_pnu_list: pnus,
    },
  });
};

const fetchCheckMergeable = (pnus: string[]) => {
  return v2RestClient.get<
    CheckMergeableResponse | CheckMergeableMessageResponse
  >(`insight/check_mergeables`, {
    params: {
      selected_pnu_list: pnus,
    },
  });
};

const mergedParcelApis = {
  fetchMergedProfitScenario,
  fetchMergedSmallHousing,
  fetchMergedParcel: dedupe(fetchMergedParcel),
  fetchCheckMergeable,
  requestCheckMergeable,
};

export default mergedParcelApis;
