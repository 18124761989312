import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'store/client';
import {
  emptyFilterOptions,
  FilterOptions,
} from 'components/Filter/FilterDefaultOption';
import filterApis, { FilterSource } from 'apis/filter';
import filterUtil from 'utils/filterUtil';
import { batch } from 'react-redux';

export type FilterApiStatus = 'idle' | 'fetching' | 'updating' | 'error';

interface DefaultFilterState {
  landArea: number[];
  landPrice: number[];
  landPricePerPy: number[];
  grossFloorArea: number[];
  buildingFloors: number[];
  buildingAge: number[];
  landUse: string[];
  mainUse: string[];
  isFilterActive: boolean;

  // v3.0 state
  interestedFilter: FilterOptions;
  articleMapFilter: FilterOptions;
  filterApiStatus: FilterApiStatus;
  filterUpdatedManually: boolean;
}

const initialState: DefaultFilterState = {
  landArea: [0, 300],
  landPrice: [0, 10000000000],
  landPricePerPy: [0, 80000000],
  grossFloorArea: [0, 360],
  buildingFloors: [1, 7],
  buildingAge: [0, 60],
  landUse: [
    '1종전용주거',
    '2종전용주거',
    '1종일반주거',
    '2종일반주거',
    '3종일반주거',
    '상업',
    '준주거',
    '미지정',
    '기타',
  ],
  mainUse: [
    '단독주택',
    '공동주택',
    '상업',
    '업무',
    '문화',
    '공공',
    '나대지',
    '기타',
  ],
  isFilterActive: false,

  // v3.0 state
  interestedFilter: emptyFilterOptions,
  articleMapFilter: emptyFilterOptions,
  filterApiStatus: 'idle',
  filterUpdatedManually: false,
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    updateLandArea: (state, { payload }) => {
      state.isFilterActive = true;
      state.landArea = payload;
    },
    updateLandPrice: (state, { payload }) => {
      state.isFilterActive = true;
      state.landPrice = payload;
    },
    updateLandPricePerPy: (state, { payload }) => {
      state.isFilterActive = true;
      state.landPricePerPy = payload;
    },
    updateGrossFloorArea: (state, { payload }) => {
      state.isFilterActive = true;
      state.grossFloorArea = payload;
    },
    updateBuildingFloors: (state, { payload }) => {
      state.isFilterActive = true;
      state.buildingFloors = payload;
    },
    updateBuildingAge: (state, { payload }) => {
      state.isFilterActive = true;
      state.buildingAge = payload;
    },
    updateLandUse: (state, { payload }) => {
      state.isFilterActive = true;
      state.landUse = payload;
    },
    updateMainUse: (state, { payload }) => {
      state.isFilterActive = true;
      state.mainUse = payload;
    },
    updateActive: (state, { payload }) => {
      state.isFilterActive = payload;
    },
    reset: () => {
      return initialState;
    },

    // v3.0 actions
    setInterestedFilter: (state, action: PayloadAction<FilterOptions>) => {
      state.interestedFilter = {
        ...state.interestedFilter,
        ...action.payload,
      };
    },
    updateinterestedFilter: (state, action: PayloadAction<FilterOptions>) => {
      state.interestedFilter = {
        ...state.interestedFilter,
        ...action.payload,
      };
    },
    setArticleMapFilter: (state, action: PayloadAction<FilterOptions>) => {
      state.articleMapFilter = {
        ...state.articleMapFilter,
        ...action.payload,
      };
    },
    updatearticleMapFilter: (state, action: PayloadAction<FilterOptions>) => {
      state.articleMapFilter = {
        ...state.articleMapFilter,
        ...action.payload,
      };
    },
    setFilterUpdateFlag: (state, action: PayloadAction<boolean>) => {
      state.filterUpdatedManually = action.payload;
    },
    setFilterApiStatus: (state, action: PayloadAction<FilterApiStatus>) => {
      state.filterApiStatus = action.payload;
    },
  },
});

/**
 * @version 3.0 actions
 */
export const fetchFilter =
  (
    source: FilterSource = 'articleMap'
  ): AppThunk<Promise<Partial<FilterOptions> | undefined>> =>
  async (dispatch, getState) => {
    let filterOptions: Partial<FilterOptions> | undefined;
    try {
      const { auth } = getState();

      if (!auth.isSignedIn) {
        return;
      }

      dispatch(filterSlice.actions.setFilterApiStatus('fetching'));

      const response = await filterApis.fetchFilter(source);
      filterOptions = filterUtil.toFilterOptions(response.data);

      if (source === 'interested') {
        dispatch(filterSlice.actions.setInterestedFilter(filterOptions));
      } else {
        dispatch(filterSlice.actions.setArticleMapFilter(filterOptions));
      }

      dispatch(filterSlice.actions.setFilterApiStatus('idle'));
    } catch (error: any) {
      dispatch(filterSlice.actions.setFilterApiStatus('error'));
    }

    return filterOptions;
  };

/**
 * @version 3.0 actions
 */
export const updateFilter =
  (filterOptions: FilterOptions, source: FilterSource): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { auth, filter } = getState();
      const { isSignedIn } = auth;

      dispatch(filterSlice.actions.setFilterApiStatus('updating'));

      if (isSignedIn) {
        const newFilterOption = {
          ...(source === 'interested'
            ? filter.interestedFilter
            : filter.articleMapFilter),
          ...filterOptions,
        };

        /** 한달이내 게시는 저장하지 않는다. */
        let newfilterQuery = filterUtil.toFilterQuery(newFilterOption);
        newfilterQuery = {
          ...newfilterQuery,
          registeredWithInDays: null,
        };

        await filterApis.updateFilter(source, newfilterQuery);
      }

      if (source === 'interested') {
        dispatch(filterSlice.actions.setInterestedFilter(filterOptions));
      } else {
        batch(() => {
          dispatch(filterSlice.actions.setArticleMapFilter(filterOptions));
          dispatch(filterSlice.actions.setFilterUpdateFlag(true));
        });
      }

      dispatch(filterSlice.actions.setFilterApiStatus('idle'));
    } catch (error: any) {
      dispatch(filterSlice.actions.setFilterApiStatus('error'));
      throw error;
    }
  };

/**
 * @version 3.0
 * 서버에 저장된 필터 조건을 제거합니다.
 */
export const clearFilterPermanently =
  (source: FilterSource): AppThunk =>
  async (dispatch, getState) => {
    const { auth } = getState();

    if (auth.isSignedIn) {
      await filterApis.resetFilter(source);
    }

    if (source === 'interested') {
      dispatch(filterSlice.actions.setInterestedFilter(emptyFilterOptions));
    } else {
      dispatch(filterSlice.actions.setArticleMapFilter(emptyFilterOptions));
    }

    dispatch(filterSlice.actions.setFilterUpdateFlag(true));
  };

/**
 * @version 3.0
 * redux state에 저장된 필터 조건을 제거합니다.
 */
export const clearFilter = (): AppThunk => dispatch => {
  dispatch(filterSlice.actions.setInterestedFilter(emptyFilterOptions));
  dispatch(filterSlice.actions.setArticleMapFilter(emptyFilterOptions));

  dispatch(filterSlice.actions.setFilterUpdateFlag(false));
};

export const {
  updateLandArea,
  updateLandPrice,
  updateLandPricePerPy,
  updateGrossFloorArea,
  updateBuildingFloors,
  updateBuildingAge,
  updateLandUse,
  updateMainUse,
  updateActive,
  reset,
  setFilterUpdateFlag,
} = filterSlice.actions;
export const selectFilter = (state: RootState) => state.filter;

/**
 * @version 3.0 selectors
 */
export const selectFilterBySource =
  (source: FilterSource) => (state: RootState) =>
    source === 'interested'
      ? state.filter.interestedFilter
      : state.filter.articleMapFilter;
export const selectFilterApiStatus = (state: RootState) =>
  state.filter.filterApiStatus;
export const selectFilterUpdateFlag = (state: RootState) =>
  state.filter.filterUpdatedManually;

export default filterSlice.reducer;
