import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/client';

interface TooltipState {
  show: boolean;
}

const initialState: TooltipState = {
  show: false,
};

const premiumListTooltip = createSlice({
  name: 'premiumListTooltip',
  initialState,
  reducers: {
    setPremiumListTooltipShow: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
  },
});

export const { setPremiumListTooltipShow } = premiumListTooltip.actions;

export const selectPremiumListTooltip = (state: RootState) =>
  state.premiumListTooltip;

export default premiumListTooltip.reducer;
