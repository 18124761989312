import { AxiosRequestConfig } from 'axios';
import v2RestClient from 'apis/clients/v2RestClient';

import type { FilterType } from 'apis/filter';
import type { MapType, PriceUnit } from 'apis/map';
import type { TimeArray } from 'utils/dateUtil';
import type { AreaUnit } from 'hooks/useAreaUnit';
import type {
  MarkerTypeDesktop,
  BookmarkMarker,
} from 'components/ParcelSearchMap/shared';
import { SignUpPayload } from './auth';

export interface ArchiPropInfos {
  isArchiProAllowed: boolean;
  archiPropCount: number;
  archiProLimitCount: number;
  archiPropPnuList: string;
  expiresAt: string;
}

export type Provider = 'landbook' | 'facebook' | 'kakao' | 'naver' | 'apple';

/**
 * ROLE_SUPERUSER: 프로모션 권한
 */
export type UserRole =
  | 'ROLE_ADMIN'
  | 'ROLE_SUPERUSER'
  | 'ROLE_COMPANY'
  | 'ROLE_MEMBERSHIP'
  | 'ROLE_ENTERPRISE';
export interface User {
  userId: number;
  name: string;
  phoneNumber: string;
  birth: string;
  email: string;
  age: string;
  /** 가입경로 */
  provider: Provider[];
  /** 사용목적 */
  usePurpose: string;
  /** 마케팅 동의 여부 */
  marketingAgreement?: boolean;
  /** 마케팅 동의 변경 시점 */
  marketingUpdatedAt?: TimeArray;
  /** 필수 약관 동의 여부 */
  requiredTermsAgreement?: boolean;
  /** 직업 */
  position?: string;
  /** 결제 진행여부 */
  hasBeenOrdered?: boolean;
  /** 거래 경험 여부 */
  hasEverDeal?: boolean;
  /** 신축 경험 여부 */
  hasEverNewConstruction?: boolean;
  /** 개인정보처리방침 동의 여부 */
  personalDataProcessAgreement?: boolean;
  /** 제 3자 정보 제공 동의 여부 */
  disclosureOfInformationToThirdPartiesAgreement?: boolean;
  /** 설문조사 완료 여부 */
  isSurveyDone: boolean;
  /** 이용권 갯수 */
  ticketCount: number;
  /** 사용자 권한 (일반 사용자의 경우 빈 배열) */
  role: UserRole[];
  /** 본인인증 여부 */
  isCertified: boolean;
  /** 가입목적 */
  purposeOfFunnels: string[];
  /** 유입경로 */
  funnels: string;
  /** 토지주 여부 */
  landOwner: string;
  /** 연동계좌 */
  linkedAccount: LinkedAccount[];
  /** 구독할인 사용 여부  */
  discountUsageCount: number;
}

export interface LinkedAccount {
  group: boolean;
  provider: Provider;
  email: string;
  userId: number;
}

export type UpdatableUserInfo = Partial<
  Pick<
    User,
    | 'name'
    | 'age'
    | 'usePurpose'
    | 'position'
    | 'hasEverDeal'
    | 'hasEverNewConstruction'
    | 'requiredTermsAgreement'
    | 'personalDataProcessAgreement'
    | 'marketingAgreement'
    | 'phoneNumber'
    | 'birth'
  >
> &
  Partial<Pick<SignUpPayload, 'impUid'>>;

export type SurveyPayload = Pick<
  SignUpPayload,
  | 'position'
  | 'funnels'
  | 'hasEverNewConstruction'
  | 'purposeOfFunnels'
  | 'landOwner'
  | 'landAddress'
>;

export enum FeedbackType {
  LAND = 'LAND',
  SERVICE_REQUIREMENT = 'SERVICE_REQUIREMENT',
  SYSTEM_ISSUE = 'SYSTEM_ISSUE',
  ETC = 'ETC',
  PREMIUM = 'PREMIUM',
}
export interface SendFeedbackPayload {
  pnu?: string;
  email: string;
  contents: string;
  type: FeedbackType;
  analysisVersion?: string;
}

/** 기업구독 문의 */
export interface SendCompanyInquiryPayload {
  name: string;
  phoneNumber: string;
  company: string;
  type: 'UNMEMBERSHIP_COMPANY' | 'MEMBERSHIP_COMPANY';
  analysisVersion?: string;
}

/** 가격문의 Type */
export interface SendPriceFeedBackPayload {
  email: string;
  phoneNumber: string;
  contents: string;
  type: 'PRICE';
  analysisVersion?: string;
}

/**유저 경공매  즐겨찾기 */
const fetchUserFavoritesRegions = async (config?: AxiosRequestConfig) => {
  const response = await v2RestClient.get(
    `auth/users/me/favorites/regions`,
    config
  );
  return response.data;
};

/**유저 경공매  즐겨찾기  수정*/
const updateUserFavoritesRegions = (preferences: { regions: string[] }) => {
  const regionsQueryParam = preferences.regions.join(',');
  return v2RestClient.put(
    `auth/users/me/favorites/regions?regions=${regionsQueryParam}`
  );
};

const fetchUser = (config?: AxiosRequestConfig) => {
  return v2RestClient.get<User>(`auth/users/me`, config);
};

const fetchUserInfo = async (config?: AxiosRequestConfig) => {
  const response = await fetchUser(config);
  return response.data;
};

const updateUserInfo = (
  payload: UpdatableUserInfo,
  config?: AxiosRequestConfig
) => {
  return v2RestClient.patch<User>(`auth/users/me`, payload, config);
};

const fetchArchiPropInfos = () => {
  return v2RestClient.get<ArchiPropInfos>(`/users/archi_prop_infos`);
};

const submitSurvey = (payload: SurveyPayload, config?: AxiosRequestConfig) => {
  return v2RestClient.post<User>(`/auth/users/me/survey`, payload, config);
};

const sendCompanyInquiry = ({
  name,
  phoneNumber,
  company,
  type,
  analysisVersion = '3.0',
}: SendCompanyInquiryPayload) => {
  return v2RestClient.post<void>(`/feedbacks?version=${analysisVersion}`, {
    name,
    phoneNumber,
    company,
    type,
  });
};

const sendFeedback = ({
  pnu,
  email,
  contents,
  type,
  analysisVersion = '3.0',
}: SendFeedbackPayload) => {
  return v2RestClient.post<void>(`/feedbacks?version=${analysisVersion}`, {
    pnu,
    type,
    email,
    contents,
  });
};

const sendPriceFeedback = ({
  email,
  phoneNumber,
  contents,
  type = 'PRICE',
  analysisVersion = '3.0',
}: SendPriceFeedBackPayload) => {
  return v2RestClient.post<void>(`/feedbacks?version=${analysisVersion}`, {
    email,
    phoneNumber,
    contents,
    type,
  });
};

type AccessLogParams = {
  browser: string;
  os: string;
  landbookVersion: string;
};

const sendAccessLog = async (params: AccessLogParams) => {
  try {
    await v2RestClient.put<void>(`/users/me/logs/access`, params);
  } catch (error) {}
};

export type LandbookVersion = 'app_3.0' | 'web_3.0';

export type LandbookVersionCriteria = {
  isWebview: boolean;
};

export type BehaviorLogParams = {
  pnu: string;
  serviceType: 'basic' | 'premium' | 'light' | 'analytics';
  action: 'pageView' | 'buildRequest';
  os: string;
  browser: string;
  landbookVersion: LandbookVersion;
  isBuildCoverage?: boolean;
};

const sendBehaviorLog = async (params: BehaviorLogParams) => {
  try {
    await v2RestClient.put<void>(`/users/me/logs/behavior`, params);
  } catch (error) {}
};

export type DeviceInfo = {
  appId: string;
  appName: string;
  appVersion: string;
  os: 'ios' | 'android';
  deviceToken: string;
};

const updateDeviceInfo = async (params: DeviceInfo) => {
  try {
    await v2RestClient.put(`/auth/users/me/device`, params);
  } catch (error) {}
};

const deleteDeviceInfo = async (deviceToken: string) => {
  try {
    await v2RestClient.delete(`/auth/users/me/device/${deviceToken}`);
  } catch (error) {}
};

export interface NotificationSetting {
  /** 매물 알림 */
  isArticleOn?: boolean;
  /** @deprecated isPushOn을 참조할 것 */
  isEventOn: null | boolean;
  /** 이메일 알림 */
  isEmailOn: boolean;
  emailUpdatedAt: null | string;
  /** sms 알림 */
  isSmsOn: boolean;
  smsUpdatedAt: null | string;
  /** 푸시 알림 */
  isPushOn: boolean;
  pushUpdatedAt: null | string;
}

const fetchNotificationSetting = async () => {
  const response = await v2RestClient.get<NotificationSetting>(
    `/auth/users/me/notifications/setting`
  );
  return response.data;
};

const updateNotificationSetting = (params: Partial<NotificationSetting>) =>
  v2RestClient.patch(`/auth/users/me/notifications/setting`, params);

export type UserAbTestIds =
  | 'tutorial'
  | 'payment-test'
  | 'promotion-test'
  | 'membership-trial'
  | 'analysis-card-view';
export type UserABTest = {
  id: UserAbTestIds;
  group: { name: string; description: string };
  startDate: TimeArray;
  endDate: TimeArray;
};
export type UserABTestResponse = {
  tests: UserABTest[];
};

//종료일: 2022년 5월 26일 00시
export const PROMOTION_TEST_A_EXPIRE_AT = 1653490800000;

const fetchUserABTests = async () => {
  const response = await v2RestClient.get<UserABTestResponse>(
    `/users/me/tests`
  );
  const now = Date.now();
  return {
    tests: response.data.tests.filter(test => {
      const isPromotionSpecial =
        test.id === 'promotion-test' && test.group.name === 'A';
      const hasPromotionExpired = now > PROMOTION_TEST_A_EXPIRE_AT;

      if (isPromotionSpecial && hasPromotionExpired) {
        return false;
      } else {
        return true;
      }
    }),
  };
};

/**
 * @version 3.0_Analytics
 * @description 사용자별 선호 옵션을 저장해 데스크탑/모바일 환경에서 일관된 경험을 제공함
 * 옵션 업데이트 시 다른 필드를 덮어씌우는 이슈를 방지하기 위해 1-depth를 기본으로 함
 */
export type UserPreferences = {
  /**
   * @description 탐색 지도 내 필터 유형별 마커 활성화 여부 (매물/실거래/신축사례), 북마크 활성화 여부
   * @example ['mapArticle', 'bookmark']
   */
  articleMapMarkers: (BookmarkMarker | FilterType)[];
  /** 탐색 지도 기본 타입 (일반/지적도/위성) */
  articleMapType: MapType;
  /** 입지정보 지도 기본 타입 (일반/지적도/위성) */
  locationMapType: MapType;
  /** 지도 마커에 표시되는 가격 단위 (총액/단가) */
  priceUnit: PriceUnit;
  /** 면적 단위 (평/m²) */
  areaUnit: AreaUnit;
  /**
   * @description 데스크탑용 탐색 지도 내 필터 유형별 마커 활성화 여부 (토지건물매매/신축사례/매물/주택임대/주택매매)
   * @example { land: true, newConstruction: false, article: false, rent: false, deal: false  }
   */
  mapMarkersDesktop: (BookmarkMarker | MarkerTypeDesktop)[];
};

export const defaultUserPreferencesMobile: UserPreferences = {
  articleMapMarkers: [
    'bookmark',
    'mapArticle',
    'publicAuction',
    'courtAuction',
  ],
  articleMapType: 'normal',
  locationMapType: 'district',
  priceUnit: 'total',
  areaUnit: 'py',
  mapMarkersDesktop: [
    'bookmark',
    'land',
    'newConstruction',
    'article',
    'publicAuction',
    'courtAuction',
  ],
};

export const defaultUserPreferencesDesktop: UserPreferences = {
  ...defaultUserPreferencesMobile,
  mapMarkersDesktop: [
    'bookmark',
    'land',
    'newConstruction',
    'article',
    'publicAuction',
    'courtAuction',
  ],
};

const fetchUserPreferences = async (
  defaultUserPreferences: UserPreferences
) => {
  const { data } = await v2RestClient.get<UserPreferences>(
    `/users/me/preferences`
  );

  return {
    ...defaultUserPreferences,
    ...data,
  };
};

const updateUserPreferences = (preferences: Partial<UserPreferences>) =>
  v2RestClient.put(`/users/me/preferences`, preferences);

const userApis = {
  fetchUser,
  fetchUserInfo,
  updateUserInfo,
  fetchArchiPropInfos,
  submitSurvey,
  sendFeedback,
  sendAccessLog,
  sendBehaviorLog,
  updateDeviceInfo,
  deleteDeviceInfo,
  fetchNotificationSetting,
  updateNotificationSetting,
  fetchUserABTests,
  fetchUserPreferences,
  updateUserPreferences,
  sendPriceFeedback,
  fetchUserFavoritesRegions,
  updateUserFavoritesRegions,
  sendCompanyInquiry,
};

export default userApis;
