import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import tradingApis, { Notification } from 'apis/trading';
import userApis, {
  DeviceInfo,
  SurveyPayload,
  UpdatableUserInfo,
  User,
} from 'apis/user';
import { AppThunk, RootState } from 'store/client';
import gtm from 'utils/gtm';
import sentry from 'utils/sentry';
import { isMobileDevice } from 'utils/environment';
import { fetchInterestedRegion, clearInterestedRegion } from './regionSlice';
import { fetchNotifications, clearNotifications } from './notificationSlice';
import { clearFilter } from './filterSlice';

interface UserState {
  userInfo: User | undefined | null;
  notification: Notification | undefined;
  deviceInfo: DeviceInfo | undefined;
}

const initialState: UserState = {
  userInfo: undefined,
  notification: undefined,
  deviceInfo: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<User>) => {
      state.userInfo = action.payload;
    },
    setNotification: (state, action: PayloadAction<Notification>) => {
      state.notification = action.payload;
    },
    setDeviceInfo: (state, action: PayloadAction<DeviceInfo>) => {
      state.deviceInfo = action.payload;
    },
    resetUser: () => {
      return initialState;
    },
  },
});

export const fetchUser = (): AppThunk<Promise<User>> => dispatch => {
  const user = dispatch(fetchUserInfo());

  if (isMobileDevice) {
    dispatch(fetchInterestedRegion());
    dispatch(fetchNotifications());
  } else {
    dispatch(fetchNotification());
  }

  return user;
};

export const clearUserPreferences = (): AppThunk => dispatch => {
  batch(async () => {
    if (isMobileDevice) {
      dispatch(clearInterestedRegion());
      dispatch(clearFilter());
      dispatch(clearNotifications());
    }
  });
};

export const fetchUserInfo = (): AppThunk<Promise<User>> => async dispatch => {
  const resp = await userApis.fetchUser();
  const user = resp.data;

  dispatch(userSlice.actions.setUserInfo(user));

  sentry.setUser({
    id: String(user.userId),
  });

  gtm.setUser(user);
  return user;
};

export const updateUserInfo =
  (payload: UpdatableUserInfo): AppThunk<Promise<void>> =>
  async dispatch => {
    const resp = await userApis.updateUserInfo(payload);
    const user = resp.data;
    dispatch(userSlice.actions.setUserInfo(user));
  };

export const submitSurvey =
  (payload: SurveyPayload): AppThunk =>
  async dispatch => {
    const resp = await userApis.submitSurvey(payload);
    const user = resp.data;
    dispatch(userSlice.actions.setUserInfo(user));

    gtm.setUser(user);
  };

export const fetchNotification = (): AppThunk => async dispatch => {
  const resp = await tradingApis.fetchNotification();
  const { userId, ...notification } = resp.data;

  dispatch(userSlice.actions.setNotification(notification));
};

export const updateNotification =
  (payload: Notification): AppThunk =>
  async dispatch => {
    const resp = await tradingApis.updateNotification(payload);
    const { userId, ...notification } = resp.data;

    dispatch(userSlice.actions.setNotification(notification));
  };

export const updateDeviceInfo =
  (payload: DeviceInfo): AppThunk =>
  async dispatch => {
    await userApis.updateDeviceInfo(payload);
    dispatch(userSlice.actions.setDeviceInfo(payload));
  };

export const { resetUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;
export const selectUserInfo = (state: RootState) => state.user.userInfo;
export const selectNotification = (state: RootState) => state.user.notification;
export const selectDeviceInfo = (state: RootState) => state.user.deviceInfo;

export default userSlice.reducer;
