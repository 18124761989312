import isEmpty from 'lodash/isEmpty';
import { User } from 'apis/user';
import { NEXT_APP_ROUTES } from 'constants/routes';

/**
 * @link https://docs.google.com/spreadsheets/d/1MvlVre-GdWAL01xzbfuGvBl6owaSfjVPfKczxv8nGTY/edit#gid=1601143278
 */

export type CustomEventCategory =
  | 'home'
  | 'search'
  | 'search_map'
  | 'analytics_basic'
  | 'analytics_premium'
  | 'mainhome'
  | '';

const getCategoryFromUrl = (): CustomEventCategory => {
  const pathname = window.location.pathname;

  switch (true) {
    case pathname.startsWith(NEXT_APP_ROUTES.HOME):
      return 'home';
    case pathname.startsWith(NEXT_APP_ROUTES.SEARCH_MAP):
      return 'search';
    case pathname.startsWith(NEXT_APP_ROUTES.ANALYTICS_BASIC):
      return 'analytics_basic';
    case pathname.startsWith(NEXT_APP_ROUTES.ANALYTICS_PREMIUM):
      return 'analytics_premium';
    default:
      return '';
  }
};

export type CustomEventAction =
  | 'home_favorite_region_setup_click'
  | 'home_favorite_region_setup_gu'
  | 'home_favorite_region_setup_gu_done'
  | 'home_favorite_region_setup_dong'
  | 'home_favorite_region_setup_dong_done'
  | 'home_favorite_region_setup_price_filter'
  | 'home_favorite_region_setup_area_filter'
  | 'home_favorite_region_setup_age_filter'
  | 'home_favorite_region_setup_gfa_filter'
  | 'home_favorite_region_setup_star_filter'
  | 'home_favorite_region_setup_distance_filter'
  | 'home_favorite_region_setup_type_filter'
  | 'home_favorite_region_setup_recent_filter'
  | 'home_favorite_region_setup_extra_filter'
  | 'home_favorite_region_setup_filter_done'
  | 'search_order_by_feasibility'
  | 'search_order_by_price_gap'
  | 'search_order_by_recent'
  | 'search_order_by_low_unit_price'
  | 'search_order_by_low_total_price'
  | 'search_filter_price_mini'
  | 'search_filter_area_mini'
  | 'search_filter_age_mini'
  | 'search_filter_gfa_mini'
  | 'search_filter_star_mini'
  | 'search_filter_distance_mini'
  | 'search_filter_type_mini'
  | 'search_filter_recent_mini'
  | 'search_filter_reset_mini'
  | 'search_filter_price'
  | 'search_filter_area'
  | 'search_filter_age'
  | 'search_filter_gfa'
  | 'search_filter_star'
  | 'search_filter_distance'
  | 'search_filter_type'
  | 'search_filter_recent'
  | 'search_deal_filter_area'
  | 'search_deal_filter_time'
  | 'search_deal_filter_age'
  | 'search_construction_filter_area'
  | 'search_construction_filter_state'
  | 'search_construction_filter_time'
  | 'search_marker_click'
  | 'search_parcel_click'
  | 'analytics_basic_view_land_building'
  | 'analytics_basic_view_auction'
  | 'analytics_basic_view_public_auction'
  | 'analytics_basic_view_development'
  | 'analytics_basic_view_owns'
  | 'analytics_basic_view_feasibility'
  | 'analytics_premium_view_land_building'
  | 'analytics_premium_view_development'
  | 'analytics_premium_view_feasibility'
  | 'analytics_basic_development_3d'
  | 'analytics_premium_development_3d' // v2에선 쓰이지 않음.
  | 'analytics_feasibility_rental_income_house'
  | 'analytics_feasibility_rental_income_commerce'
  | 'analytics_feasibility_rental_loan_share'
  | 'analytics_feasibility_rental_interest_rate'
  | 'analytics_feasibility_rental_land_price'
  | 'analytics_feasibility_rental_construction_cost'
  | 'analytics_feasibility_sales_income_house'
  | 'analytics_feasibility_sales_income_commerce'
  | 'analytics_feasibility_sales_land_price'
  | 'analytics_feasibility_sales_construction_cost'
  | 'analytics_feasibility_sales_loan_share'
  | 'analytics_feasibility_sales_interest_rate'
  | 'analytics_location_details_parcel_click'
  | 'analytics_building_details_see_more'
  | 'analytics_building_details_close'
  | 'analytics_floor_overview_open'
  | 'analytics_floor_overview_floor_button'
  | 'analytics_feasibility_close'
  | 'analytics_feasibility_sales_tab'
  | 'analytics_feasibility_rentals_tab'
  | 'analytics_land_details_close'
  | 'analytics_basic_development_close'
  | 'analytics_premium_development_close'
  | 'analytics_premium_conversion_done_click_app_noti'
  | 'analytics_premium_floor_overview_list_open'
  | 'analytics_premium_floor_overview_list_close'
  | 'analytics_basic_location_large_map'
  | 'analytics_premium_location_large_map'
  | 'analytics_premium_development_3d_summary'
  | 'analytics_premium_development_3d_analysis'
  | 'location_deal_land_area'
  | 'location_deal_land_time'
  | 'location_deal_land_age'
  | 'location_deal_land_distance'
  | 'location_deal_land_state'
  | 'location_deal_rental_area'
  | 'location_deal_rental_time'
  | 'location_deal_rental_age'
  | 'location_deal_rental_distance'
  | 'location_deal_rental_state'
  | 'location_deal_sales_area'
  | 'location_deal_sales_time'
  | 'location_deal_sales_age'
  | 'location_deal_sales_distance'
  | 'location_deal_sales_state'
  | 'location_construction_lot_area'
  | 'location_construction_state'
  | 'location_construction_time'
  | 'location_construction_distance'
  | 'location_construction_area'
  | 'location_construction_age'
  | 'search_house_filter_area'
  | 'search_house_filter_time'
  | 'home_posting_click'
  | 'home_event_banner'
  | 'home_event_banner_mobile'
  | 'home_youtube_thumbnail'
  | 'bls_preview_detail_click'
  | 'bls_parcel_page_link_click'
  | 'landbook_parcel_page_link_click'
  | 'apply_consultation_desktop' // desktop '건축 상담하기' 클릭 (메인홈, 지도우측, 랜드북 VE건축사례페이지)
  | 'apply_consultation_mobile' // mobile '건축 상담하기' 클릭 (pm메인페이지, 랜드북 VE건축사례페이지)
  | 'mainhome_search_box_click'
  | 'mainhome_main_search_box_click'
  | 'analytics_premium_purchase_complete'
  | 'analytics_premium_list_page_option'
  | 'analytics_ai_buy_ticket_review_hover'
  | 'analytics_ai_buy_ticket_review_more'
  | 'analytics_ai_buy_ticket_review_more_close'
  | 'search_auction_filter_type'
  | 'search_auction_filter_time'
  | 'search_auction_filter_price'
  | 'search_auction_filter_lowest_price'
  | 'search_auction_filter_bids'
  | 'search_public_auction_filter_type'
  | 'search_public_auction_filter_time'
  | 'search_public_auction_filter_price'
  | 'search_public_auction_filter_lowest_price'
  | 'search_public_auction_filter_bids'
  | 'search_auction_region_on'
  | 'search_auction_region_off'
  | 'mainhome_adver_banner_click'
  | 'mainhome_adver_banner_application_click'
  | 'home_adver_banner_click'
  | 'home_adver_banner_application_click'
  | 'search_adver_banner_click'
  | 'search_adver_banner_application'
  | 'search_auction_region_like'
  | 'search_auction_region_like_region'
  | 'search_auction_region_like_region_done'
  | 'notification_data_update_more_click_back'
  | 'mainhome_faq_click'
  // light
  | 'mainhome_ai_membership_more'
  | 'mainhome_ai_plus_buy_click'
  | 'light_list_page_option'
  | 'light_dp_view_development'
  | 'light_dp_view_feasibility'
  | 'light_dp_view_estimate_price'
  | 'light_dp_view_location'
  | 'light_dp_view_official_land_value'
  | 'light_dp_3d_click'
  | 'premium_list_light_tab_find_address'
  | 'analytics_ai_cta_signup_click'
  | 'analytics_ai_cta_login_click'
  | 'mainhome_ai_login_click'
  | 'mainhome_ai_signup_click'
  | 'mainhome_membership_login_click'
  | 'mainhome_membership_signup_click'
  | 'analytics_ai_cta_subscribe_click'
  | 'mainhome_membership_subscribe_click'
  | 'mainhome_ai_subscribe_click'
  | 'analytics_ai_cta_subscribe_complete_click'
  | 'mainhome_ai_subscribe_complete_click'
  | 'mainhome_membership_subscribe_complete_click'
  | 'deal_marketPrice'
  | 'analytics_basic_view_marketprice'
  | 'light_dp_marketprice'
  | 'analytics_premium_view_marketprice'
  | 'analytics_basic_marketprice_list_click'
  | 'analytics_basic_marketprice_list_hover'
  | 'analytics_basic_marketprice_list_open_list_hover'
  | 'light_dp_pdf_file'
  | 'light_dp_pdf_title'
  | 'analytics_premium_detail_page_pdf_title'
  | 'light_dp_pdf_subtitle'
  | 'analytics_premium_detail_page_pdf_subtitle'
  | 'light_dp_pdf_date'
  | 'analytics_premium_detail_page_pdf_date'
  | 'mainhome_ai_plus_buy_complete_click';

export type CustomEventRequest = {
  action: CustomEventAction;
  category?: CustomEventCategory;
  label?: string;
  payload?: string;
};

type CustomEvent = CustomEventRequest & {
  event: 'customEvent';
};

export type PageViewAction =
  | 'main_home_landing_pageview'
  | 'main_home_ai_pageview'
  | 'main_home_consulting_pageview'
  | 'main_home_pm_pageview'
  | 'main_home_zion_pageview'
  | 'main_home_north_south_pageview'
  | 'main_home_huam_pageview'
  | 'main_home_escape_pageview'
  | 'main_home_obelisk_pageview'
  | 'main_home_tetris_pageview'
  | 'main_home_plant_pageview'
  | 'main_home_horinjae_pageview'
  | 'main_home_corner_pageview'
  | 'main_home_sowol_pageview'
  | 'home_landing_pageview'
  | 'home_intro_card_1_pageview'
  | 'home_intro_card_2_pageview'
  | 'home_notification_pageview'
  | 'home_sample_page_pageview'
  | 'bookmark_landing_pageview'
  | 'search_landing_pageview'
  | 'search_map_pageview'
  | 'analytics_premium_pageview'
  | 'analytics_basic_pageview'
  | 'analytics_merged_pageview'
  | 'location_basic_pageview'
  | 'account_landing_pageview'
  | 'account_profile_pageview'
  | 'account_criteria_landing_pageview'
  | 'account_criteria_landprice_pageview'
  | 'account_criteria_development_pageview'
  | 'account_criteria_feasibility_pageview'
  | 'account_criteria_data_pageview'
  | 'account_criteria_premium_pageview'
  | 'account_qna_pageview'
  | 'landbook_2_pageview'
  | 'premium_list_landing_pageview'
  | 'home_intro_card_pageview'
  | 'merged_basic_pageview'
  | 'roadview_landing_pageview'
  | 'landbook_catalog_pageview'
  | 'main_corner_mobile_pageview'
  | 'main_escape_mobile_pageview'
  | 'main_horinjae_mobile_pageview'
  | 'main_huamshop_mobile_pageview'
  | 'main_northsouth_mobile_pageview'
  | 'main_obelisk_mobile_pageview'
  | 'main_obelisk_mobile_pageview'
  | 'main_sikmulgwan_mobile_pageview'
  | 'main_zion_mobile_pageview'
  | 'main_tetris_mobile_pageview'
  | 'main_comfort_mobile_pageview'
  | 'analytics_premium_list_page_pageview'
  | 'analytics_premium_detail_page_pageview'
  | 'analytics_auction_pageview'
  | 'analytics_public_auction_pageview'
  | 'light_list_page_pageview'
  | 'light_dp_pageview'
  | 'analytics_light_pageview'
  | 'mainhome_membership_pageview';

type PageViewEventRequest = {
  action: PageViewAction;
  label?: string;
} & CustomDimensionRequest;

type PageViewEvent = PageViewEventRequest & {
  event: 'pageView';
};

type Data = {
  [key: string]: string | number | boolean | undefined;
};

type CustomDimensionRequest = {
  /** 매물 유무 구분 */
  is_listings?: boolean;
  /** 웹뷰 여부 */
  is_web_view?: boolean;
  /** path 카테고리 지원을 위한 페이지뷰 이벤트 */
  location_pathname?: string;
  /** 앱 출시 버전 정보 */
  app_version?: string;
  /** 로그인 여부  */
  is_user_login?: boolean;
};

let initialized = false;

const addData = (data: Data) => {
  window.dataLayer.push(data);
};

const setDimensions = () => {
  addData({
    dimension1: 'user_id',
    dimension2: 'user_job',
    dimension3: 'user_age',
    dimension4: 'trade_experience',
    dimension5: 'build_experience',
    dimension6: 'is_listings',
    dimension7: 'is_web_view',
    dimension8: 'app_version',
    dimension9: 'is_user_login',
    dimension10: 'membership_paid',
  });
};

const gtm = {
  initialize: () => {
    if (!Array.isArray(window.dataLayer)) {
      return;
    }

    initialized = true;

    setDimensions();
  },
  setUser: (user: User) => {
    if (!initialized) {
      return;
    }

    const {
      userId: user_id,
      position: user_job,
      age: user_age,
      hasEverDeal: trade_experience,
      hasEverNewConstruction: build_experience,
    } = user;
    const userDimensions = {
      user_id: String(user_id),
      user_job: user_job,
      user_age: user_age,
      trade_experience: trade_experience,
      build_experience: build_experience,
      is_user_login: true,
      membership_paid: user.role.includes('ROLE_MEMBERSHIP'),
    };

    addData(userDimensions);
  },
  clearUser: () => {
    if (!initialized) {
      return;
    }

    addData({
      user_id: undefined,
      user_job: undefined,
      user_age: undefined,
      trade_experience: undefined,
      build_experience: undefined,
      is_user_login: false,
      membership_paid: undefined,
    });
  },
  customEvent: ({
    action,
    category = getCategoryFromUrl(),
    label,
    payload,
  }: CustomEventRequest) => {
    if (!initialized) {
      return;
    }

    const customEvent: CustomEvent = {
      event: 'customEvent',
      category: category,
      action: action,
    };

    if (label) {
      customEvent['label'] = label;
    }

    if (payload) {
      customEvent['payload'] = payload;
    }

    addData(customEvent);
  },
  pageView: ({ action, label, ...dimensions }: PageViewEventRequest) => {
    if (!initialized) {
      return;
    }

    const pageViewEvent: PageViewEvent = {
      event: 'pageView',
      action: action,
      label,
      ...dimensions,
    };

    addData(pageViewEvent);
  },
  customDimension: (payload: CustomDimensionRequest = {}) => {
    if (!initialized || isEmpty(payload)) {
      return;
    }

    addData(payload);
  },
};

export default gtm;
