import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import articleApis, {
  Article,
  ArticlesQuery,
  ArticlesMapQuery,
  RecommendedArticles,
  NearbyArticles,
  defaultLatestArticles,
  defaultNearbyArticles,
  ArticleCountsByRegion,
} from 'apis/articles';
import { AppThunk, RootState } from 'store/client';
import filterUtil from 'utils/filterUtil';

export type ApiStatus = 'idle' | 'fetching' | 'error';

type InitialState = {
  latestArticles: RecommendedArticles;
  nearbyArticles: NearbyArticles;
  latestApiStatus: ApiStatus;
  nearbyApiStatus: ApiStatus;
  mapArticles: Article[];
  mapApiStatus: ApiStatus;
  articleCounts: ArticleCountsByRegion;
};

const initialState: InitialState = {
  latestArticles: defaultLatestArticles,
  nearbyArticles: defaultNearbyArticles,
  latestApiStatus: 'idle',
  nearbyApiStatus: 'idle',
  mapArticles: [],
  mapApiStatus: 'idle',
  articleCounts: {
    city: [],
    gu: [],
    dong: [],
  },
};

const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    setLatestArticles: (state, action: PayloadAction<RecommendedArticles>) => {
      state.latestArticles = action.payload;
    },
    setNearbyArticles: (state, action: PayloadAction<NearbyArticles>) => {
      state.nearbyArticles = action.payload;
    },
    setLatestApiStatus: (state, action: PayloadAction<ApiStatus>) => {
      state.latestApiStatus = action.payload;
    },
    setNearbyApiStatus: (state, action: PayloadAction<ApiStatus>) => {
      state.nearbyApiStatus = action.payload;
    },
    setArticleMap: (state, action: PayloadAction<Article[]>) => {
      state.mapArticles = action.payload;
    },
    setArticleMapApiStatus: (state, action: PayloadAction<ApiStatus>) => {
      state.mapApiStatus = action.payload;
    },
    setArticleCounts: (state, action: PayloadAction<ArticleCountsByRegion>) => {
      state.articleCounts = {
        ...state.articleCounts,
        ...action.payload,
      };
    },
  },
});

export const fetchLatestArticles =
  (params?: ArticlesQuery): AppThunk =>
  async (dispatch, getState) => {
    const {
      filter: { interestedFilter },
    } = getState();
    const paramsWithFilter = {
      ...(params || ({} as ArticlesQuery)),
      ...filterUtil.toFilterQuery(interestedFilter),
      size: 10,
    };

    dispatch(articleSlice.actions.setLatestApiStatus('fetching'));

    const latestArticles = await articleApis.getLastestArticles(
      paramsWithFilter
    );

    dispatch(articleSlice.actions.setLatestArticles(latestArticles));
    dispatch(articleSlice.actions.setLatestApiStatus('idle'));
  };

export const fetchNearbyArticles =
  (params?: ArticlesQuery): AppThunk =>
  async (dispatch, getState) => {
    const {
      filter: { interestedFilter },
    } = getState();
    const paramsWithFilter = {
      ...(params || ({} as ArticlesQuery)),
      ...filterUtil.toFilterQuery(interestedFilter),
      size: 3,
    };

    dispatch(articleSlice.actions.setNearbyApiStatus('fetching'));

    const nearbyArticles = await articleApis.getNearbyArticles(
      paramsWithFilter
    );

    dispatch(articleSlice.actions.setNearbyArticles(nearbyArticles));
    dispatch(articleSlice.actions.setNearbyApiStatus('idle'));
  };

export const fetchMapRegionArticles =
  (params: ArticlesMapQuery): AppThunk =>
  async dispatch => {
    try {
      dispatch(articleSlice.actions.setArticleMapApiStatus('fetching'));

      const articles = await articleApis.fetchArticlesByAddress(params);

      dispatch(articleSlice.actions.setArticleMap(articles.items));
      dispatch(articleSlice.actions.setArticleMapApiStatus('idle'));
    } catch (error) {
      dispatch(articleSlice.actions.setArticleMapApiStatus('error'));
    }
  };

export const fetchMapBoundsArticles =
  (params: ArticlesMapQuery): AppThunk =>
  async dispatch => {
    try {
      dispatch(articleSlice.actions.setArticleMapApiStatus('fetching'));

      const articles = await articleApis.fetchArticlesByBounds(params);

      dispatch(articleSlice.actions.setArticleMap(articles.items));
      dispatch(articleSlice.actions.setArticleMapApiStatus('idle'));
    } catch (error) {
      dispatch(articleSlice.actions.setArticleMapApiStatus('error'));
    }
  };

export const fetchMapArticleCounts =
  (params: ArticlesMapQuery): AppThunk =>
  async dispatch => {
    if (!params.regionType) {
      return;
    }

    const articleCounts = await articleApis.fetchArticleCounts(params);

    if (articleCounts) {
      dispatch(
        articleSlice.actions.setArticleCounts({
          [params.regionType]: articleCounts,
        } as ArticleCountsByRegion)
      );
    }
  };

export const selectArticle = (state: RootState) => state.article;

export default articleSlice.reducer;
