import { UserPremiumRequest } from 'apis/analysis';
import mergedParcelApis from 'apis/mergedParcel';
import { PREMIUM_COVERAGE_REGIONS } from 'constants/premium';
import { MergedParcel, ParcelList } from 'services/mergedParcel';
import { Parcel } from 'services/parcel';
import { MapLatLng } from './map';
import { isDevelopmentPhase, isProductionPhase } from './environment';
import { InValidBasicParcelErrorMessage } from 'components/Analytics/_comps/AreaExamine/validationUtil';
import { STAGING_ENTERPRICE_MAX_AREA } from 'constants/area';

/**
 * AI 건축분석 커버리지 판단을 위한 util
 */

/**
 * 필지조건
지역조건 : 서울
설계검토 가능 조건을 만족하는 필지
지목 : 대지, 전, 답, 공장, 창고, 잡종지, 과수원, 임야, 목장

용도지역 : 전용주거·일반주거지역
용도지역 : 복합 불가능
노후년도 : 고려하지 않음
대지면적 : 70~400m2
대지형상: 역삼각
지구단위계획 포함 필지 제외
 */
const validRegion = PREMIUM_COVERAGE_REGIONS.split(', ').map(region =>
  region.trim()
);

const validJimoks = [
  '대지',
  '전',
  '답',
  '공장',
  '창고',
  '잡종지',
  '과수원',
  '임야',
  '목장',
];

/**
 * 유효 필지 형상
 */
const validLotShape = [
  '가장형',
  '사다리',
  '역삼각',
  '세장형',
  '정방형',
  '자루형',
  '삼각형',
  '',
];

/**
 * 프리미엄분석 면적제한
 */
/**
 * staging : 테스트 용 스테이징 및 dev에서 사용 라이트분석에 사용
 * all : 일반유저 + 모든 프리미엄 해당 부분으로 조건
 * enterprise: enterprise권한 가진 유저 라이트분석에 사용
 */
type areaTypeKey = 'all' | 'staging' | 'enterprise';

export const validArea: AreaType = {
  all: { min: 70, max: 410 },
  staging: { min: 70, max: STAGING_ENTERPRICE_MAX_AREA },
  enterprise: { min: 70, max: STAGING_ENTERPRICE_MAX_AREA },
};
type AreaType = {
  [key in areaTypeKey]: { min: number; max: number };
};

export const invalidMessages = {
  mergeable: {
    main: '합필이 가능한 필지가 아닙니다',
    sub: '',
  },
  region: {
    // TODO: 이미 인천 지역 서비스 되고 있음 이부분 어디서 쓰이는 확인 후 삭제.
    main: `${PREMIUM_COVERAGE_REGIONS} 외 지역입니다`,
    sub: '연내 인천 및 경기로 확장 예정입니다',
  },
  otherParcels: {
    // TODO: 현재는 연계 필지 되고 있고, 추후 되지 않아야함. 이부분 쓰이는지 확인 후 삭제.
    main: '연계필지에서는 AI 건축분석 요청을 할 수 없습니다',
    sub: '연내 확장 예정입니다',
  },
  jimok: {
    main: '분석 가능한 지목이 아닙니다',
    sub: '',
  },
  shape: {
    main: '대지와 도로가 만나는 길이가 짧습니다',
    sub: '분석 가능하도록 확장 예정입니다',
  },
  landuseZoneMulti: {
    main: '단일 용도지역이 아닙니다',
    sub: '분석 가능하도록 확장 예정입니다',
  },
  landuseZoneInvalid: {
    main: '일반주거지역이 아닙니다',
    sub: '연내 준주거 및 상업지역으로 확장 예정입니다',
  },
  area: {
    main: '분석 면적(70~400m²)이 아닙니다',
    sub: '연내 1,000m²로 확장 예정입니다',
  },
  cityPlan: {
    main: '지구단위계획 필지입니다',
    sub: '연내 분석 가능하도록 확장 예정입니다',
  },
};

/**
 * AI 건축분석이 가능한 지역인지 확인
 */
export const validateRegion = (address: string) => {
  const isValid = validRegion.filter(n => address.includes(n)).length > 0;
  return isValid ? null : invalidMessages.region;
};
/**
 * AI 건축분석이 가능한 필지 형상 확인
 */
export const validateShape = (lotShape: string) => {
  const isValid = validLotShape.includes(lotShape);
  return isValid ? null : invalidMessages.shape;
};
/**
 * AI 건축분석이 가능한 지목인지 확인
 */
export const validateJimok = (jimok: string) => {
  const jimoks = jimok.split('|');
  const isValid = jimoks.every(n => validJimoks.includes(n));
  return isValid ? null : invalidMessages.jimok;
};

/**
 * @deprecated [IB-5055] 복합 용도지역에서 프리미엄 분석이 가능하도록 수정되었다.
 */
export const hasMultipleLanduseZone = (landuseZone: string) => {
  const zones = landuseZone.split('|');
  return zones.length > 1;
};
/**
 * 용도지역이 다수인지 확인하고, AI 건축분석 가능한 용도지역인지 확인
 */
export const validateLanduseZoneValue = (landuseZone: string) => {
  if (!isLanduseZoneValid(landuseZone)) {
    // if (hasMultipleLanduseZone(landuseZone)) {
    //   return invalidMessages.landuseZoneMulti;
    // }
    return invalidMessages.landuseZoneInvalid;
  }
  return null;
};
/**
 * AI 건축분석 가능한 용도지역의 면적 범위에 포함되는지 확인 (라이트)
 */
export const validateArea = (area: number, isEnterpriceRole: boolean) => {
  const validateType =
    //  !isProductionPhase
    //   ? 'staging'
    //   :
    isEnterpriceRole || isDevelopmentPhase ? 'enterprise' : 'all';

  const isValid =
    area >= validArea['all'].min && area <= validArea[validateType].max;

  return isValid ? null : invalidMessages.area;
};

/**
 * AI 건축분석 가능한 용도지역의 면적 범위에 포함되는지 확인 (프리미엄)
 */
export const validatePremiumArea = (area: number) => {
  const isValid = area >= validArea['all'].min && area <= validArea['all'].max;
  return isValid ? null : invalidMessages.area;
};

/**
 * 어디민유저인 경우 면적제한을 600까지 상향한다.
 */
export const validateAreaForAdmin = (area: number) => {
  const isValid =
    area >= validArea['all'].min &&
    area <= (isProductionPhase ? 600 : STAGING_ENTERPRICE_MAX_AREA);
  return isValid ? null : invalidMessages.area;
};

type MessageType = null | { main: string; sub: string };

/**
 * 프리미엄 분석 가능여부
 * @param parcel AI 건축분석이 가능한지 판별한 필지의 parcel 데이터
 * @description AI 건축분석이 가능한지 검증하는 함수입니다.
 * @testPnus 1162010200114840001: 면적초과, 도시계획 포함
 * @testPnus 1162010200114740010: 미지원 용도지역 , 도시계획 포함
 * @testPnus 2623010100100620013: 미지원 지역
 * @testPnus 1168010800100900001: 복수 용도지역
 * @testPnus 1159010700102330012: 미지원 지목
 */
export const validatePremium = (parcel: Parcel, isEnterpriceRole: boolean) => {
  const { parcel_info } = parcel;
  const { address, landuse_zone, jimok, area } = parcel_info;
  //이 배열에서 우선순위가 정해짐
  const validators = [
    () => validateRegion(address),
    () => validateLanduseZoneValue(landuse_zone),
    () => validateArea(area, isEnterpriceRole),
    () => validateJimok(jimok),
    /** 20240806 삭제 */
    // () => validateShape(lot_shape),
  ];
  let message: MessageType = null;
  const isValid =
    Boolean(validators.find(validator => (message = validator()) !== null)) ===
    false;

  return { isValid, message: message as MessageType };
};

enum LanduseZone {
  Invalid = 0,
  제1종일반주거지역 = 1 << 1,
  제2종일반주거지역 = 1 << 2,
  제3종일반주거지역 = 1 << 3,
  도시관리계획 = 1 << 4,
  도시지역기타 = 1 << 5,
  일반상업지역 = 1 << 6,
  제1종전용주거지역 = 1 << 7,
  제2종전용주거지역 = 1 << 8,
  준주거지역 = 1 << 9,
  준공업지역 = 1 << 10,
  중심상업지역 = 1 << 11,
  근린상업지역 = 1 << 12,
  유통상업지역 = 1 << 13,
  전용공업지역 = 1 << 14,
  일반공업지역 = 1 << 15,
  보전녹지지역 = 1 << 16,
  생산녹지지역 = 1 << 17,
  자연녹지지역 = 1 << 18,
}

const STAGING_LAND_ZONE = [
  LanduseZone.준공업지역,
  LanduseZone.중심상업지역,
  LanduseZone.근린상업지역,
  LanduseZone.유통상업지역,
  LanduseZone.전용공업지역,
  LanduseZone.일반공업지역,
  LanduseZone.보전녹지지역,
  LanduseZone.생산녹지지역,
  LanduseZone.자연녹지지역,
];
export const STAGIN_ONLY_LIGHT_LANDUSEZONE = [
  '준공업지역',
  '중심상업지역',
  '근린상업지역',
  '유통상업지역',
  '전용공업지역',
  '일반공업지역',
  '보전녹지지역',
  '생산녹지지역',
  '자연녹지지역',
];

export const ONLY_LIGHT_LANDUSEZONE = ['일반상업지역', '준주거지역'].concat(
  STAGIN_ONLY_LIGHT_LANDUSEZONE
);

const baseLandUse = [
  LanduseZone.제1종일반주거지역,
  LanduseZone.제2종일반주거지역,
  LanduseZone.제3종일반주거지역,
  LanduseZone.제1종전용주거지역,
  LanduseZone.제2종전용주거지역,
  LanduseZone.일반상업지역,
  LanduseZone.준주거지역,
].concat(STAGING_LAND_ZONE);

const subLandUse = [
  LanduseZone.제1종일반주거지역,
  LanduseZone.제2종일반주거지역,
  LanduseZone.제3종일반주거지역,
  LanduseZone.도시관리계획,
  LanduseZone.도시지역기타,
  LanduseZone.제1종전용주거지역,
  LanduseZone.제2종전용주거지역,
  LanduseZone.일반상업지역,
  LanduseZone.준주거지역,
].concat(STAGING_LAND_ZONE);

const filterDup = (item: any, index: number, array: any[]) =>
  array.indexOf(item) === index;

/**
 * [합필] 일반주거지역만 합필할 수 있다.
 * [IB-5055] 복합용도지역을 프리미엄 분석 가능하도록 확장
 */
const validLanduseZonesForMergeParcel = baseLandUse
  .reduce((prev, cur) => {
    const mergedLandUse = subLandUse.map(landUse => landUse | cur);
    return [...prev, ...mergedLandUse];
  }, baseLandUse)
  .filter(filterDup);

type LanduseZoneType = keyof typeof LanduseZone;

const convertLanduseToEnumFlags = (landuseZone: string) => {
  const landuseZoneArray = landuseZone?.split('|') as LanduseZoneType[];
  return landuseZoneArray?.reduce((prev, item) => {
    if (item in LanduseZone) {
      return prev | LanduseZone[item];
    } else {
      return prev * LanduseZone.Invalid;
    }
  }, 0);
};

export const isLanduseZoneValid = (landuseZone: string) => {
  const result = convertLanduseToEnumFlags(landuseZone);
  return validLanduseZonesForMergeParcel.includes(result);
};

/**
 * 합필 가능 용도지역인지 확인
 */
export const isLanduseZoneValidForMergeParcel = (landuseZone: string) => {
  const result = convertLanduseToEnumFlags(landuseZone);

  return validLanduseZonesForMergeParcel.includes(result);
};

/**
 *
 * @param jimok 클릭한 필지 지목
 * @returns 유효할경우 true
 * @description 지도에서 필지를 클릭할때 유효한 지목인지 검증하는 함수
 */
const INVALID_JIMOKS = ['도로', '하천', '구거'];
export const isValidJimok = (jimok: string) => {
  if (!INVALID_JIMOKS.includes(jimok)) {
    return true;
  } else {
    return false;
  }
};

export class InvalidJimokError extends Error {
  name: string;

  constructor(message: string) {
    super(message);
    this.name = 'InvalidJimokError';
  }
}

/**
 * @function validateUserName
 * @param name 입력된 유저 이름
 * @returns 유효할 경우 true 유효하지 않을 경우 문구
 * @description 입력한 유저의 이름이 20자 초과하는지 검증하는 함수
 */
export const validateUserName = (name: string) => {
  return name.length < 21 || '20자까지만 입력 가능합니다';
};

/**
 *
 * @param includedStatues "SUCCEEDED" | "FAILED" | "RUNNING"| "REQUESTED" | "PREPARED" | "REJECTED" | "CANCELED"
 * @returns returns empty array if userStatus is undefined or something went wrong
 */
export const filterPremiumRequests = (userStatus: UserPremiumRequest[]) => {
  const include = (filter: string[]) => {
    return userStatus?.filter(n => filter.includes(n.buildStatus)) || [];
  };
  const exclude = (filter: string[]) => {
    return userStatus?.filter(n => !filter.includes(n.buildStatus)) || [];
  };

  return { include, exclude };
};

type Validator = () => boolean;

/**
 * [베이직] 합필이 가능한 지역인지 확인
 * @returns `{ isValid, invalidMessage }`
 */
export const validateMergeParcel = (landuseZone: string) => {
  // validators index 높을수록 우선순위
  const validators: [Validator, string][] = [
    [
      () => !isLanduseZoneValidForMergeParcel(landuseZone),
      InValidBasicParcelErrorMessage.isNotResidentialArea.message,
    ],
    // [
    //   () => hasMultipleLanduseZone(landuseZone),
    //   '하나의 용도지역에서만 제공합니다',
    // ],
  ];

  const message = validators.reduce(
    (message, [validator, errorMessage]) =>
      validator() ? errorMessage : message,
    ''
  );
  const isValid = message === '';

  return { isValid, message };
};

export const mergedCoverage = [
  { label: '지역', value: '수도권, 5대 광역시, 제주' },
  {
    label: '용도지역',
    value: '도시지역',
  },
  { label: '토지면적', value: '70 ~ 400㎡' },
  {
    label: '지목',
    value: '대지, 전, 답, 공장, 창고, 잡종지, 과수원, 임야, 목장',
  },
];
export const premiumCoverage = [
  { label: '지역', value: '수도권, 5대 광역시, 제주' },
  {
    label: '용도지역',
    value: '전용주거지역 · 일반주거지역',
  },
  { label: '토지면적', value: '70 ~ 400㎡' },
  {
    label: '지목',
    value: '대지, 전, 답, 공장, 창고, 잡종지, 과수원, 임야, 목장',
  },
];

export const lightCoverage = [
  { label: '지역', value: '수도권, 5대 광역시, 제주' },
  {
    label: '용도지역',
    value: '도시지역',
  },
  { label: '토지면적', value: '70 ~ 400㎡' },
  {
    label: '지목',
    value: '대지, 전, 답, 공장, 창고, 잡종지, 과수원, 임야, 목장',
  },
];

export type PremiumValidation = {
  isValid: boolean;
  message: {
    main: string;
    sub: string;
  } | null;
};
/**
 * [프리미엄] AI 건축분석 합필이 가능한 지역인지 확인
 * @param mergedParcel 합필 결과
 * @param isEnterpriceRole EnterpriceRole인 경우 합필 프리미엄 면적 커버리지를 완화한다.
 * @returns AI 건축분석 합필이 가능하면 true 아닐시 false
 */
export const validatePremiumMerged = (
  mergedParcel: MergedParcel,
  isEnterpriceRole: boolean
): PremiumValidation => {
  const combined = combineParcels(mergedParcel.parcel_list);
  const { address: addresses, landuse_zone, jimok } = combined;
  const area = mergedParcel.merged_parcel_info[0].merged_parcel_area;

  const address = addresses.split('|')[0];
  //이 배열에서 우선순위가 정해짐
  const validators = [
    validateRegion(address),
    validateLanduseZoneValue(landuse_zone),
    validateArea(area, isEnterpriceRole),
    validateJimok(jimok),
  ];
  const isValid = validators.every(n => n === null);
  const message = validators.find(n => n !== null) || null;

  return { isValid, message };
};

type CombinedParcel = {
  address: string;
  jimok: string;
  landuse_zone: string;
};

const toCombinedString = (
  field: 'address' | 'jimok' | 'landuse_zone',
  parcels: ParcelList[]
) => {
  const fields = parcels.map(parcel => parcel[field]);
  const uniqueFields = Array.from(new Set(fields));

  return uniqueFields.join('|');
};

export const combineParcels = (parcelList: ParcelList[]): CombinedParcel => {
  return {
    address: toCombinedString('address', parcelList),
    jimok: toCombinedString('jimok', parcelList),
    landuse_zone: toCombinedString('landuse_zone', parcelList),
  };
};

export const validateIsMergeable = async (
  basePnus: string[],
  targetLatLng: MapLatLng
) => {
  const isMergeable = await mergedParcelApis.requestCheckMergeable(
    targetLatLng.latitude,
    targetLatLng.longitude,
    basePnus
  );
  return {
    isValid: isMergeable,
    message: isMergeable ? invalidMessages.mergeable : null,
  };
};
