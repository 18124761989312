import analysisApis from 'apis/analysis';
import { toast } from 'react-toastify';
import { filterPremiumRequests } from 'utils/aiAnalysisUtil';

const isBuildRunning = (buildStatus: string) => {
  return buildStatus !== 'SUCCEEDED' && buildStatus !== 'FAILED';
};

const requestPolling = (interval: number) => {
  return new Promise<{ pnu: string; buildStatus: string }>(
    async (resolve, reject) => {
      const execute = async () => {
        try {
          const result = await analysisApis.checkPremiumRequestStatus();
          const validRequests = filterPremiumRequests(result?.data).exclude([
            'PREPARED',
            'CANCELED',
            'REJECTED',
          ]);
          const { buildStatus, pnu } = validRequests[0];
          if (buildStatus && pnu) {
            if (!isBuildRunning(buildStatus)) {
              if (buildStatus === 'SUCCEEDED') {
                resolve({ pnu, buildStatus: 'SUCCEEDED' });
              }
            } else {
              setTimeout(async () => await execute(), interval);
              return;
            }
          }
          reject({ pnu: '', buildStatus: 'FAILED' });
        } catch (error) {
          const errorStatus = error?.response?.status;
          if (errorStatus === 500) {
            setTimeout(async () => await execute(), interval);
          } else if (errorStatus === 401) {
            toast.dismiss();
          } else {
            reject({ pnu: '', buildStatus: 'FAILED' });
          }
        }
      };
      execute();
    }
  );
};
const poll = {
  requestPolling,
};

export default poll;
