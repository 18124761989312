import qs, { IStringifyOptions } from 'qs';
import { toNumber } from './numberUtils';

export type ObjectType = {
  [props: string]: any;
};

const sortFn = (a: string, b: string) => a.localeCompare(b);

/**
 * querystring을 생성합니다.
 */
export const buildQueryString = (
  params: ObjectType,
  option: IStringifyOptions = {}
) =>
  qs.stringify(params, {
    arrayFormat: 'brackets',
    encode: false,
    skipNulls: true,
    sort: sortFn,
    ...option,
  });

/**
 * 객체의 속성 중 문자열로 캐스팅된 숫자를 문자열로 타임 캐스팅합니다.
 */
export const toNumberFromQueryObect = (obj: ObjectType) => {
  const entry = Object.entries(obj).map(([queryParamter, value]) => [
    queryParamter,
    toNumber(value),
  ]);

  return Object.fromEntries(entry) as ObjectType;
};

export const toAllowedQueryParams = (params: ObjectType, allowlist: string[]) =>
  allowlist.reduce((allowedParams: ObjectType, key: string) => {
    if (key in params) {
      allowedParams[key] = params[key];
    }

    return allowedParams;
  }, {});
