import { hotjar } from 'react-hotjar';

const hjid = process.env.REACT_APP_HOTJAR_ID;
const hjsv = process.env.REACT_APP_HOTJAR_VERSION;

const hj = {
  initialize: () => {
    try {
      if (hjid && hjsv) {
        /**2번째 인자는 hotjar snippet 버전이며 현재는 6으로 동일합니다 */
        hotjar.initialize(parseInt(hjid), parseInt(hjsv));
      }
    } catch (error) {
      console.error('Hotjar Initialize Failed');
    }
  },
};

export default hj;
