import { ArticleType } from 'apis/filter';
import { convertArea } from 'utils/areaUtil';
import { toCurrency } from 'utils/currencyUtil';
import { Range } from './FilterSlider';

export enum FilterType {
  DEAL_PRICE = 'dealPrice',
  LOT_AREA = 'lotArea',
  BUILDING_AGE = 'buildingAge',
  GROSS_FLOOR_AREA = 'grossFloorArea',
  TIME_TO_STATION = 'timeToStation',
  ARTICLE_TYPE = 'type',
  RATINGS = 'ratings',
  REGISTRED_WITHIN_DAYS = 'registeredWithInDays',
}

export enum SilderFilter {
  DEAL_PRICE = FilterType.DEAL_PRICE,
  LOT_AREA = FilterType.LOT_AREA,
  BUILDING_AGE = FilterType.BUILDING_AGE,
  GROSS_FLOOR_AREA = FilterType.GROSS_FLOOR_AREA,
  TIME_TO_STATION = FilterType.TIME_TO_STATION,
}

export type OptionType = {
  label: string;
  type: FilterType;
};

type FormatterOption = {
  isPy?: boolean;
};

export type SliderOption = OptionType & {
  min: number;
  max: number;
  step: number;
  markCount: number;
  unit: string;
  displayFormatter?: (value: any, option?: FormatterOption) => any;
};

// api 기본 단위가 만원으로 설정되어 있어 기본 단위를 만원으로 설정합니다.
export const DEAL_PRICE_OPTION: SliderOption = {
  type: FilterType.DEAL_PRICE,
  label: '매매가',
  min: 0,
  max: 1000000, // 100억
  step: 50000, // 5억
  markCount: 3,
  unit: '억',
  displayFormatter: (value: number) => {
    // 기본단위 10000원
    const priceUnit = 10000;
    const { shortValue } = toCurrency(value * priceUnit);
    return shortValue;
  },
};

export const LOT_AREA_OPTION: SliderOption = {
  type: FilterType.LOT_AREA,
  label: '토지면적',
  min: 0,
  max: 300,
  step: 10,
  markCount: 3,
  unit: '평', // 기본 단위 평
  displayFormatter: (value, option) => {
    const isPy = typeof option?.isPy === 'undefined' || option?.isPy;
    return isPy ? value : Math.round(convertArea.pyeongToM2(value));
  },
};

export const BUILDING_AGE_OPTION: SliderOption = {
  type: FilterType.BUILDING_AGE,
  label: '노후',
  min: 0,
  max: 60,
  step: 1,
  markCount: 3,
  unit: '년',
};

export const GROSS_FLOOR_AREA_OPTION: SliderOption = {
  type: FilterType.GROSS_FLOOR_AREA,
  label: '연면적',
  min: 0,
  max: 360,
  step: 10,
  markCount: 3,
  unit: '평',
  displayFormatter: (value, option) => {
    const isPy = typeof option?.isPy === 'undefined' || option?.isPy;
    return isPy ? value : Math.round(convertArea.pyeongToM2(value));
  },
};

export const TIME_TO_STATION_OPTION: SliderOption = {
  type: FilterType.TIME_TO_STATION,
  label: '역과의 거리',
  min: 0,
  max: 20,
  step: 5,
  markCount: 5,
  unit: '분',
};

export const RATINGS_OPTION: OptionType = {
  type: FilterType.RATINGS,
  label: '신축시 별점',
};
export const ARTICLE_TYPE_OPTION: OptionType = {
  type: FilterType.ARTICLE_TYPE,
  label: '매물 유형',
};
export const REGISTRED_WITHIN_DAYS_OPTION: OptionType = {
  type: FilterType.REGISTRED_WITHIN_DAYS,
  label: '한달 이내 게시',
};

export type FilterOptions = Partial<{
  [FilterType.DEAL_PRICE]: Range | null;
  [FilterType.LOT_AREA]: Range | null;
  [FilterType.BUILDING_AGE]: Range | null;
  [FilterType.GROSS_FLOOR_AREA]: Range | null;
  [FilterType.TIME_TO_STATION]: Range | null;
  [FilterType.ARTICLE_TYPE]: ArticleType[] | null; // 매물 유형
  [FilterType.RATINGS]: number[] | null; // 신축시 별점
  [FilterType.REGISTRED_WITHIN_DAYS]: number | null; // 기타
}>;

export const DEFAULT_VALUE: Required<FilterOptions> = {
  [FilterType.DEAL_PRICE]: [DEAL_PRICE_OPTION.min, DEAL_PRICE_OPTION.max],
  [FilterType.LOT_AREA]: [LOT_AREA_OPTION.min, LOT_AREA_OPTION.max],
  [FilterType.BUILDING_AGE]: [BUILDING_AGE_OPTION.min, BUILDING_AGE_OPTION.max],
  [FilterType.GROSS_FLOOR_AREA]: [
    GROSS_FLOOR_AREA_OPTION.min,
    GROSS_FLOOR_AREA_OPTION.max,
  ],
  [FilterType.TIME_TO_STATION]: [
    TIME_TO_STATION_OPTION.min,
    TIME_TO_STATION_OPTION.max,
  ],
  [FilterType.RATINGS]: null,
  [FilterType.ARTICLE_TYPE]: null,
  [FilterType.REGISTRED_WITHIN_DAYS]: null,
};

export const ExtranFilters = [
  FilterType.GROSS_FLOOR_AREA,
  FilterType.RATINGS,
  FilterType.TIME_TO_STATION,
  FilterType.ARTICLE_TYPE,
  FilterType.REGISTRED_WITHIN_DAYS,
];

/**
 * 필터가 적용되지 않은 초기상태.
 */
export const emptyFilterOptions = {
  [FilterType.DEAL_PRICE]: null,
  [FilterType.LOT_AREA]: null,
  [FilterType.BUILDING_AGE]: null,
  [FilterType.GROSS_FLOOR_AREA]: null,
  [FilterType.TIME_TO_STATION]: null,
  [FilterType.RATINGS]: null,
  [FilterType.ARTICLE_TYPE]: null,
  [FilterType.REGISTRED_WITHIN_DAYS]: null,
};
