import {
  BuildStatus,
  FailedHistory,
  FirstFloorType,
  PremiumAnalysis,
  PremiumResult,
} from 'store/server/query/premiumParcel';
import { TimeArray } from 'utils/dateUtil';

import v2RestClient from './clients/v2RestClient';
import { Pagination } from './clients/v2RestClient/type';
import { LightAnalysis, LightResult } from 'store/server/query/lightParcel';

type PromotionUserApiReq = {
  isPromotionUser?: boolean;
};

type ProfitScenarioParameter = {
  pnuList: string[]; //설계에 사용된 pnu들 리스트
  uniqueCode?: string; //설계안 코드. AI 건축분석의 경우 보내주시면 됩니다.
  serviceType?: string; //	일반/합필/프리미엄(AI 건축분석) 찾으시는 서비스 컴포넌트에 따라서 넣어주시면 될 것 같습니다.
  propertyCost?: number; //	토지비
  constCostPerPyeong?: number; //	평당건축비
  loanImportance?: number; //	대출비중
  loanRatio?: number; //	대출이율
  residenceRentPerPyeong?: number; //	평당주거임대료
  facilityRentPerPyeong?: number; //	평당상가임대료
};

type LeaseProfitScenarioResponse = {
  yearlyLeaseProfit: number;
  leaseProfitOnEquity: number;
  monthlyLeaseProfit: number;
  residenceRentPerPyeong: number;
  facilityRentPerPyeong: number;
  monthlyInterestCost: number;
  loanImportance: number;
  equity: number;
  loanRatio: number;
  businessCost: number;
  propertyCost: number;
  propertyIncidentalCost: number;
  constCostPerPyeong: number;
  totalConstCost: number;
  constIncidentalCost: number;
};

type SaleProfitScenarioResponse = {
  netSalesProfit: number;
  salesProfitOnEquity: number;
  salesProfitOnSales: number;
  salesProfit: number;
  residencePricePerPyeong: number;
  facilityPricePerPyeong: number;
  businessCost: number;
  propertyCost: number;
  propertyIncidentalCost: number;
  constCostPerPyeong: number;
  totalConstCost: number;
  constIncidentalCost: number;
  loanImportance: number;
  equity: number;
  loanRatio: number;
  financeCost: number;
};

const fetchLeaseProfitScenario = (params: ProfitScenarioParameter) => {
  return v2RestClient.get<LeaseProfitScenarioResponse>(`insight/v2/lease`, {
    params: {
      ...params,
    },
  });
};

const fetchSaleProfitScenario = (params: ProfitScenarioParameter) => {
  return v2RestClient.get<SaleProfitScenarioResponse>(`insight/v2/sales`, {
    params: {
      ...params,
    },
  });
};

export type FirstFloor = '필로티' | '주거' | '상가' | '전층상가';
export type Unit = '원룸형' | '투룸형' | '쓰리룸' | '쓰리룸 이상';
export type HousingType =
  | '근린'
  | '다가구'
  | '다세대'
  | '단독주택'
  | '다중'
  | '업무'
  | '판매'
  | '임대형기숙사';

export type PreviewResultV3 = {
  uniqueCode: string;
  firstFloor: FirstFloor;
  unit: Unit;
  housingType: HousingType;
  realFar: number;
  /** 세대수 */
  residentUnit: number;
  /** 주차대수 */
  parkLotCount: number;
};

/**
 * 사용자 선택 설정, v2이후 존재
 */
type UserPrefer = {
  housingType: HousingType;
  firstFloor: FirstFloor;
  unit: Unit;
  uniqueCode: string;
};

export type PremiumPreviewV3 = {
  pnu: string;
  buildStatus: BuildStatus;
  /** 분석 로그 */
  logId: number;
  /** 설계에 사용된 ML 버전 */
  mlVersion: string;
  mlVersions: {
    drlMultiVersion: string;
    drlRetailVersion: string;
    drlVersion: string;
  };

  /** BO에 설정된 ML 버전 */
  latestVersion: string;
  userPrefer: UserPrefer;
  result: PreviewResultV3[];
};

export type PremiumAnalysisStatus = {
  pnu: string;
  buildStatus: BuildStatus;
  otherPnu: string[];
  engineVersions: string;
  isBuildUpdatable: boolean;
  isInProgress: boolean;
  logId?: number;
  customInput?: StatusCustompInput;
};
type FetchPremiumPreviewReq_deprecated = {
  pnu: string;
} & PromotionUserApiReq;

/**
 * @version v2 프로모션 고객 버전
 * @version v3 유료화 버전
 * @deprecated
 */
const fetchPremiumPreviewV3_deprecated = ({
  pnu,
  isPromotionUser,
}: FetchPremiumPreviewReq_deprecated) =>
  v2RestClient.get<PremiumPreviewV3>(
    `insight/premium/small_housings/${pnu}/preview`,
    {
      timeout: 15000,
      params: {
        version: isPromotionUser ? 'v2' : 'v3',
      },
    }
  );
type FetchPremiumPreviewReq = {
  pnus: string[];
} & PromotionUserApiReq;

/**
 * @version v2 프로모션 고객 버전
 * @version v3 유료화 버전
 */
const fetchPremiumPreviewV3 = ({
  pnus,
  isPromotionUser,
}: FetchPremiumPreviewReq) =>
  v2RestClient.get<PremiumPreviewV3>(`insight/premium/small_housings/preview`, {
    timeout: 15000,
    params: {
      pnus: pnus,
      version: isPromotionUser ? 'v2' : 'v3',
    },
  });

/**
 * @version v3 샘플 버전
 */
const fetchPremiumPreviewV3Sample = () =>
  v2RestClient.get<PremiumPreviewV3>(
    `insight/sample/premium/small_housings/preview`,
    {
      timeout: 15000,
    }
  );

export type PremiumOptionType = {
  pnu: string;
  firstFloor: FirstFloor;
  housingType: HousingType;
  unit?: Unit;
  uniqueCode: string;
};

type ResponseSavePremiumOption = {
  userId: string;
  pnu: string;
  userPrefer: PremiumPreviewV3['userPrefer'];
};

/**
 * savePremiumOption
 * 신축옵션모달에서 선택한 옵션을 저장하는 API 입니다.
 * @link https://www.notion.so/spacewalkcorp/API-7f4fbd4928d54e42af778ad13fda2064
 * @param pnu
 * @param firstFloor
 * @param housingType
 */
const savePremiumOption = ({
  pnu,
  firstFloor,
  housingType,
  uniqueCode,
}: PremiumOptionType) => {
  return v2RestClient.put<ResponseSavePremiumOption>(
    `insight/premium/user_prefer`,
    {
      firstFloor,
      housingType,
      uniqueCode,
    },
    {
      params: { pnu },
    }
  );
};

export type RequestPremiumAnalysisReq_deprecated = {
  pnu: string;
  isCanceled?: boolean;
} & PromotionUserApiReq;

/**
 * AI 건축분석 요청, AI 건축분석 엔진 버전 업데이트 요청
 * @param pnu
 * @version v1: 프로모션 유저, 요청한도 내에 결제없이 AI 건축분석 요청 가능
 * @version v2: 유료화 버전, 일반 사용자용 (티켓과의 연결 검사 로직)
 * @deprecated
 */
const requestPremiumAnalysis_deprecated = (
  options: RequestPremiumAnalysisReq_deprecated
) => {
  const { pnu, isCanceled = false, isPromotionUser } = options;
  return v2RestClient.put<PremiumAnalysis>(
    `insight/premium/small_housings/${pnu}`,
    null,
    {
      timeout: 30000,
      params: {
        isCanceled,
        version: isPromotionUser ? 'v1' : 'v2',
      },
    }
  );
};

export type RequestPremiumAnalysisReq = {
  pnus: string[];
  isCanceled?: boolean;
  version?: 'v1' | 'v2' | 'v3';
  orderId?: string;
  customInput?: CustomInput;
} & PromotionUserApiReq;

export type CustomInputResponse = {
  max_bcr: number;
  max_far: number;
  max_floor: number | null;
  max_height: number | null;
};
export type RequestLightAnalysisReq = {
  pnus: string[];
  isCustomInput?: boolean;
  customResponse?: CustomInputResponse;
} & PromotionUserApiReq;
export type RequestLightAnalysisParams = {
  pnus: string[];
  isCustomInput?: string;
};
/**
 * AI 건축분석 분석 요청, AI 건축분석 엔진 버전 업데이트 요청
 * @param pnu
 * @version v1: 프로모션 유저, 요청한도 내에 결제없이 AI 건축분석 요청 가능
 * @version v2: 유료화 버전, 일반 사용자용 (티켓과의 연결 검사 로직)
 */
const requestPremiumAnalysis = (options: RequestPremiumAnalysisReq) => {
  const {
    pnus,
    isCanceled = false,
    isPromotionUser,
    version,
    orderId,
    customInput,
  } = options;
  const headers = {
    'Content-Type': 'application/json',
  };

  const params = orderId
    ? {
        pnus: pnus,
        isCanceled,
        version: version ? version : isPromotionUser ? 'v1' : 'v2',
        orderId,
      }
    : {
        pnus: pnus,
        isCanceled,
        version: version ? version : isPromotionUser ? 'v1' : 'v2',
      };

  return v2RestClient.put<PremiumAnalysis>(
    `insight/premium/small_housings`,
    customInput ? { customInput: { ...customInput } } : null,
    {
      headers,
      timeout: 30000,
      params,
    }
  );
};

/**
 * AI 라이트 건축분석 분석 요청, AI 건축분석 엔진 버전 업데이트 요청
 * @param pnu
 * @version v1: 프로모션 유저, 요청한도 내에 결제없이 AI 건축분석 요청 가능
 * @version v2: 유료화 버전, 일반 사용자용 (티켓과의 연결 검사 로직)
 */

const requestLightAnalysis = (options: RequestLightAnalysisReq) => {
  const { pnus, isCustomInput, customResponse } = options;
  const headers = {
    'Content-Type': 'application/json',
  };
  const params: RequestLightAnalysisParams = {
    pnus: pnus,
    // version: version ? version : isPromotionUser ? 'v1' : 'v2',
  };

  if (isCustomInput) {
    params.isCustomInput = 'true';
  }

  const body = isCustomInput ? { ...customResponse } : null;

  return v2RestClient.put<LightAnalysis>(`insight/light/small_housings`, body, {
    headers,
    timeout: 30000,
    params,
  });
};

/**
 * @property maxCount 총 풀링을 실핼할 함수
 * @property resultStatus 상태 성공 여부
 */
export type StatusTestOptions = {
  resultStatus: 'SUCCEEDED' | 'FAILED';
  maxCount: number;
};

type CheckPremiumRequestStatusReq = {
  /** 단건 요청시 pnu */
  pnu: string;
  /** 연계필지 포함 여부 */
  others?: string[];
};

/**
 *
 * @param pnu
 * @param test 더미 api를 사용할 경우 true.polling을 시뮬레이트하는 함수입니다.
 * @description 400: 신청한적 없는 사용자, 401: 사용자 권한이 없을때(refresh token필요), 403: 사용자 횟수 제한 걸림 408: timeout error
 */
export type UserPremiumRequest = {
  userId: number;
  pnu: string;
  /** 연계필지 */
  otherPnu: string[];
  buildStatus: BuildStatus;
};

/**
 * 신청한 설계 상태 조회
 */
const checkPremiumRequestStatus = () =>
  v2RestClient.get<UserPremiumRequest[]>('users/premium/request', {
    timeout: 60000,
  });

/**
 * 신청한 설계 상태 단건 조회
 */
const checkPremiumRequestStatusByPnu = (params: CheckPremiumRequestStatusReq) =>
  v2RestClient.get<UserPremiumRequest>('users/premium/request', {
    timeout: 60000,
    params,
  });

let count = 0;
const checkPremiumRequestStatusSimulator = (
  pnu: string,
  resultStatus: string
) => {
  return new Promise<{ data: [{ pnu: string; buildStatus: string }] }>(
    (resolve, reject) => {
      if (count < 5) {
        count++;
        resolve({ data: [{ pnu, buildStatus: 'REQUESTED' }] });
      } else {
        if (resultStatus === 'SUCCEEDED') {
          resolve({ data: [{ pnu, buildStatus: resultStatus }] });
          count = 0;
        } else {
          reject({ data: [{ pnu, buildStatus: resultStatus }] });
          count = 0;
        }
      }
    }
  );
};

/** 라이트 버전도 동일하게 사용 */
export type SmallHousingTestOptions = {
  resultStatus: 'FAILED' | 'SUCCEEDED';
};
export interface FetchPremiumSmallHousingParmas {
  uniqueCode: string;
  /** admin  권한일 경우 premiumBuildRequestLogId가 포함되어야 함 */
  premiumBuildRequestLogId?: string;
  isAdmin?: boolean;
}

export interface FetchLightSmallHousingParmas {
  uniqueCode: string;
  /** admin  권한일 경우 premiumBuildRequestLogId가 포함되어야 함 */
  lightBuildRequestLogId?: string;
  isAdmin?: boolean;
}

/** 실사이미지  */
export type fetchLightBuildRenderParams = {
  uniqueCode: string;
};

/** 실사이미지  */
export type postLightBuildRenderParams = {
  uniqueCode: string;
  userId: string;
  /** 건축물 목적  */
  purpose: string;
  pnu: string;
  address: string;
};

/**
 * @param uniqueCode
 * @returns
 */
const fetchPremiumSmallHousing = (params: FetchPremiumSmallHousingParmas) => {
  const url =
    params?.isAdmin && Boolean(params.premiumBuildRequestLogId)
      ? 'insight/admin/premium/small_housings'
      : 'insight/premium/small_housings';

  return v2RestClient.get<PremiumResult>(url, {
    timeout: 30000,
    params,
  });
};

/**
 * 셈플 설계안 조회
 */
const fetchPremiumSmallHousingSample = (
  params: FetchPremiumSmallHousingParmas
) => {
  return v2RestClient.get<PremiumResult>(
    `insight/sample/premium/small_housings`,
    { timeout: 30000, params }
  );
};

type fetchPremiumResultsReq = {
  pnu: string;
} & PromotionUserApiReq;

/**
 * @deprecated
 */
const fetchPremiumResults = async ({
  pnu,
  isPromotionUser,
}: fetchPremiumResultsReq) => {
  const res = await fetchPremiumPreviewV3_deprecated({ pnu, isPromotionUser });
  const data = res.data;
  if (data.result.length > 0) {
    const requests = data.result.map(n =>
      fetchPremiumSmallHousing({ uniqueCode: n.uniqueCode })
        .then(res => res.data)
        .catch((e: Error) => e)
    );
    const results = await Promise.all(requests);
    const validResults = results.filter(
      result => !(result instanceof Error)
    ) as PremiumResult[];

    return validResults;
  } else {
    return [];
  }
};

export type CustomInputValue = {
  buildStatus?: BuildStatus;
  id?: number;
  logId?: number;
  maxBcr?: number | null;
  maxFar?: number | null;
  maxFloor?: number | null;
  maxHeight?: number | null;
  isVersionUpdated?: boolean;
};

export type LightItem = {
  pnu: string;
  buildStatus: BuildStatus;
  address: string;
  dealPrice: number;
  estimatedPrice: number;
  area: number;
  landuseZone: string;
  landuseDistrict: string;
  updatedAt: TimeArray;
  /** 유효기간 만료 여부 */
  isExpired: boolean;
  /** 환불 여부 */
  isRefunded: boolean;
  readAt: TimeArray;
  expiredAt: TimeArray;
  completedAt: TimeArray;
  buildRequestLogId: number;
  /** 연계필지, 합필 pnus */
  otherPnu: string[];
  memo: string;
  logId: string;
  /** 연계필지 여부 */
  isConnection: boolean;
  isVersionUpdated: boolean;
  /** 법규 사용자 설정 값 */
  customInputs: CustomInputValue[];
};

export type PremiumItem = {
  pnu: string;
  buildStatus: BuildStatus;
  address: string;
  dealPrice: number;
  estimatedPrice: number;
  area: number;
  /** 지구단위계획구역 판별 */
  landuseDistrict: string;
  landuseZone: string;
  updatedAt: TimeArray;
  /** 유효기간 만료 여부 */
  isExpired: boolean;
  /** 환불 여부 */
  isRefunded: boolean;
  readAt: TimeArray;
  expiredAt: TimeArray;
  completedAt: TimeArray;
  buildRequestLogId: number;
  /** 연계필지, 합필 pnus */
  otherPnu: string[];
  memo: string;
  logId: string;
  /** 연계필지 여부 */
  isConnection: boolean;
  isPremiumUpdated: boolean;
  /** 법규 사용자 설정 값 */
  customInputs: CustomInputValue;
};

export type LightListParameter = {
  query?: string; //검색어
  status?: 'SUCCEEDED' | 'FAILED' | ''; //정렬 타입
  page?: number; //페이지
  size?: number; //아이템수
  logId?: number; // 아이템 마지막 로그 ID
} & PromotionUserApiReq;

export type PremiumListParameter = {
  query?: string; //검색어
  status?: 'SUCCEEDED' | 'FAILED' | ''; //정렬 타입
  page?: number; //페이지
  size?: number; //아이템수
  logId?: number; // 아이템 마지막 로그 ID
} & PromotionUserApiReq;

export type PremiumList = Pagination<PremiumItem>;
export type LightList = Pagination<LightItem>;
/**
 * @version v1 프로모션 유저 버전
 * @version v2 유료화버전
 */
const fetchCompletedPremiumListV2 = (params: PremiumListParameter) => {
  const {
    page = 0,
    size = 10,
    query = '',
    status = '',
    isPromotionUser,
    logId,
  } = params;
  return v2RestClient.get<PremiumList>('/users/me/premiums', {
    params: {
      size,
      query,
      status,
      page,
      version: isPromotionUser ? 'v1' : 'v2',
      logId,
    },
    timeout: 60000,
  });
};

/**
 * @version v1 프로모션 유저 버전
 * @version v2 유료화버전
 */
const fetchCompletedLightListV2 = (params: LightListParameter) => {
  const {
    page = 0,
    size = 10,
    query = '',
    status = '',
    isPromotionUser,
    logId,
  } = params;
  return v2RestClient.get<LightList>('/users/me/lights', {
    params: {
      size,
      query,
      status,
      page,
      version: isPromotionUser ? 'v1' : 'v2',
      logId,
    },
    timeout: 60000,
  });
};

/**
 * @version v1 프로모션고객 버전
 * @version v2 유료화버전
 */
const fetchInProgressPremiumListV2 = (params?: PromotionUserApiReq) =>
  v2RestClient.get<PremiumItem[]>('/users/me/premiums/inprogress', {
    params: { version: params?.isPromotionUser ? 'v1' : 'v2' },
  });

export type NearestPlace = {
  consumingTime: number;
  distance: number;
  pnu: string;
  stationLine: string;
  stationName: string;
  travelMode: string;
};
export type SummaryResponse = {
  /** pnu */
  id: string;
  /** pnu */
  pnu: string;
  /** longitude */
  longitude: string;
  /** latitude */
  latitude: string;
  /** 추정가 */
  estimatedPrice: number;
  /** 단위면적당 추정가(m2) */
  estimatedUnitPrice: number;
  originEstimatedPrice: number;
  /** 대지면적(m2) */
  groundSpace: number;
  /** 매매가 */
  dealPrice: number;
  /** 단위면적당 매매가(py) */
  dealPriceByPy: number;
  /** 매물타입 */
  articleType: string;
  /** 연계필지 면적(m2) */
  lotArea: number;
  code: string; //매물번호
  /** 매물 사진 url */
  imgUrls: string[];
  /** 중계사 url */
  articleUrls: string[];
  /** 중계사 모바일 url */
  mobileUrl: string;
  /** 중계사 데스크탑 url */
  desktopUrl: string;
  /** 연면적(m2) */
  gfArea: number; //면적 정보
  nearestPlace: NearestPlace[]; //인근 역 도보 거리
  /** 노출일자,매물 등록 날짜  */
  exposedAt: Date;
  /** 별점 */
  ratings: number;
  /** 노후년도 */
  age: number;
  /** 주소 */
  address: string;
  /** 용도지역 */
  landuseZone: string;
};

/**
 * 404일경우 매물이 없는것으로 가정하면 됩니다.
 */
const fetchArticleSummary = (pnu: string) => {
  return v2RestClient.get<SummaryResponse>(`/articles/${pnu}/summary`);
};

interface PremiumStatusResponseType {
  buildStatus: BuildStatus | null;
  pnu: string;
  /** 연계필지의 경우 다른 필지들의 pnu */
  otherPnu: string[];
  /** AI 건축분석 분석 요청 가능여부, (맹지, 엔진 버전 이슈 등 반복 실패가 예상될 경우 false)*/
  isAvailable: boolean;
  /** 사용가능 티켓 카운트 */
  ticketCount: number;
  /** 사용자의 AI 건축분석 분석 결과 존재 여부 */
  hasResult: boolean;
  /** AI 건축분석 분석 로그 */
  logId: number | null;
  /** 조회 만료 여부 */
  isExpired: boolean | null;
  /** 환불 여부 */
  isRefunded: boolean | null;
  /** 접근 권한 */
  isAccessible: boolean | null;
  /** 최초 조회 일시 */
  readAt: TimeArray | null;
  /** 만료 일시 */
  expiredAt: TimeArray | null;
  /** 요청한 날짜 */
  updatedAt: TimeArray | null;
  /**
   * AI 건축분석 버전 업데이트 가능 여부
   * 기존 로그의 엔진 버전이 최신 버전이 아니고, 현재 진행 중인 최신 엔진 버전의 AI 건축분석 설계 요청이 없음
   * 기존 로그가 없는 경우에는 null
   */
  updateAvailable: boolean | null;

  /** 잔여 프로모션 요청 횟수 */
  promoRemaining: number;

  /** 설계 엔진 실패하는 경우 사유 */
  failedHistory: FailedHistory;

  lightFailedHistory: 'EMPTY_RESULT' | 'NOT_ENOUGH_PARKING_LOT' | null;
}

/**
 * 분석 이력이 있는 케이스
 */
interface PremiumStatusResponseHasResult extends PremiumStatusResponseType {
  hasResult: true;
  buildStatus: BuildStatus;
  isExpired: boolean;
  isRefunded: boolean;
  isAccessible: boolean;
  readAt: TimeArray;
  expiredAt: TimeArray;
  updateAvailable: boolean;
  logId: number;
  /**맹지 유무  True: X  , False : O(맹지) */
  isEngineInput: boolean;

  /**라이트 실패내역 */
  lightStatus: boolean;
}
/**
 * 분석 이력이 없는 케이스
 */
interface PremiumStatusResponseNoResult extends PremiumStatusResponseType {
  hasResult: false;
  buildStatus: null;
  isExpired: null;
  isRefunded: null;
  isAccessible: null;
  readAt: null;
  expiredAt: null;
  updateAvailable: null;
  logId: null;
  /**맹지 유무  True: X  , False : O(맹지) */
  isEngineInput: boolean;
  /**라이트 실패내역 */
  lightStatus: boolean;
}

interface LightStatusRes {
  pnu: string;
  buildStatus: BuildStatus;
  otherPnu: string[];
  lightVersion: string;
  createdAt: TimeArray;
  isInProgress: boolean;
  runCompleted: boolean;
  totalRunCount: string;
  averageUnitArea: string;
  customInput?: StatusCustompInput;
  logId?: number;
}
interface StatusCustompInput {
  id: number;
  hashKey: string;
  maxBcr?: number | null;
  maxFar?: number | null;
  maxFloor?: number | null;
  maxHeight?: number | null;
  createdAt: TimeArray;
  updatedAt: TimeArray;
  isVersionUpdated?: boolean;
  logId?: number;
}

export type PremiumStatusResponse =
  | PremiumStatusResponseHasResult
  | PremiumStatusResponseNoResult;

export type LightStatusResponse = LightStatusRes;

/**
 * @description AI 건축분석 요청을 보내기전에 미리 요청 가능 여부를 확인하는 api입니다.
 */
const fetchPremiumStatus_deprecated = (pnu: string) => {
  return v2RestClient.get<PremiumStatusResponse>(
    `/users/me/premium/${pnu}/status`
  );
};
/**
 * @description AI 건축분석 요청을 보내기전에 미리 요청 가능 여부를 확인하는 api입니다.
 */
const fetchPremiumStatus = (pnus: string[]) => {
  return v2RestClient.get<PremiumStatusResponse>(`/users/me/premium/status`, {
    params: {
      pnus,
    },
  });
};

/**
 * 비회원용 프리미엄 분서 요청 가능 여부 조회
 * @description AI 건축분석 요청을 보내기전에 미리 요청 가능 여부를 확인하는 api입니다.
 */
const fetchPremiumStatusForGuest = (pnus: string[]) => {
  return v2RestClient.get<PremiumStatusResponse>(`/premium/status`, {
    params: {
      pnus,
    },
  });
};

export type CustomInput = {
  max_bcr?: number | null;
  max_far?: number | null;
  max_floor?: number | null;
  max_height?: number | null;
};
export type CustomFormData = {
  isCustomInput?: boolean;
} & CustomInput;

export type CustomInputParam = {
  isCustomInput?: boolean;
  maxBcr?: number;
  maxFar?: number;
  maxFloor?: number;
  maxHeight?: number;
};

type LightStatusParams = {
  pnus: string[];
  logId?: number;
} & CustomInputParam;

/**
 * @description AI 건축분석 요청을 보내기전에 미리 요청 가능 여부를 확인하는 api입니다.
 */
const fetchLightStatus = (
  pnus: string[],
  logId?: number,
  isCustomInput?: CustomFormData
) => {
  const params: LightStatusParams = {
    pnus,
  };

  if (logId) {
    params.logId = logId;
  }

  if (isCustomInput && isCustomInput.isCustomInput) {
    params.isCustomInput = isCustomInput?.isCustomInput;
    params.maxBcr = isCustomInput?.max_bcr ?? undefined;
    params.maxFar = isCustomInput?.max_far ?? undefined;
    params.maxFloor = isCustomInput?.max_floor ?? undefined;
    params.maxHeight = isCustomInput?.max_height ?? undefined;
  }

  return v2RestClient.get<LightStatusResponse>(
    `insight/light/small_housings/status`,
    {
      params,
    }
  );
};

type UpdateMemoPayload = {
  pnu: string;
  memo: string;
  logId: string;
};

const updateMemo = ({ pnu, memo, logId }: UpdateMemoPayload) => {
  return v2RestClient.put(`/users/me/premium/${pnu}`, {
    memo,
    logId,
    // api에서는 아래 값들도 받을 수 있도록 되어있지만, 해당내용은 현재 기획에서 삭제된 기능으로 사용되지 않음.
    // 미소님께 이 api requestBody에서 해당내용 삭제하는 방향으로 의견드린 상태입니다.
    housingType: '',
    firstFloor: '',
    unit: '',
    uniqueCode: '',
  });
};

/**
 * dagagu: 다가구
 * dajung: 다중
 * dasedae: 다세대
 * commercial|dagagu: 상가|다가구
 * commercial|dajung: 상가|다중
 * commercial|dasedae: 상가|다세대
 * commercial: 근린
 */
export type HousingTypeFilter =
  | 'dagagu'
  | 'dajung'
  | 'dasedae'
  | 'commercial|dagagu'
  | 'commercial|dajung'
  | 'commercial|dasedae'
  | 'commercial'
  | 'business'
  | 'sales'
  | 'dormitory'
  | 'commercial|dormitory';

/**
 * proposing: 랜드북 추천 제안
 * realFar: 실용적률
 * residentUnit: 세대 수
 * parkLotCount:  주차 수
 */

/** 라이트 버전도 동일하게 사용 */
export type PremiumResultListOrderType =
  | 'proposing'
  | 'realFar'
  | 'residentUnit'
  | 'parkLotCount';

export interface FetchPremiumResultListAdminParams {
  premiumBuildRequestLogId: string;
  /**
   * v2: 프리미엄 개선 버전
   * v3: (default) 유료화 적용 버전
   */
  version?: 'v2' | 'v3';
  /** default: [], 전체 */
  types?: HousingTypeFilter[];
  /** default:recommendation */
  sortBy?: PremiumResultListOrderType;
  isAdmin?: boolean;
}

export interface FetchPremiumResultListUserParams {
  /**
   * v2: 프리미엄 개선 버전
   * v3: (default) 유료화 적용 버전
   */
  version?: 'v2' | 'v3';
  pnus: string[];
  /** default: [], 전체 */
  types?: HousingTypeFilter[];
  /** default:recommendation */
  sortBy?: PremiumResultListOrderType;
  isAdmin?: boolean;
}

export interface FetchLightResultListAdminParams {
  logId: string;
  /**
   * v2: 프리미엄 개선 버전
   * v3: (default) 유료화 적용 버전
   */
  version?: 'v2' | 'v3';
  /** default: [], 전체 */
  types?: HousingTypeFilter[];
  /** default:recommendation */
  sortBy?: PremiumResultListOrderType;
  isAdmin?: boolean;
}

export interface FetchLightResultListUserParams {
  /**
   * v2: 프리미엄 개선 버전
   * v3: (default) 유료화 적용 버전
   */
  version?: 'v2' | 'v3';
  pnus: string[];
  /** default: [], 전체 */
  types?: HousingTypeFilter[];
  /** default:recommendation */
  sortBy?: PremiumResultListOrderType;
  isAdmin?: boolean;
}
export type FetchLightResultListParams =
  | FetchLightResultListUserParams
  | FetchLightResultListAdminParams;

export type FetchPremiumResultListParams =
  | FetchPremiumResultListUserParams
  | FetchPremiumResultListAdminParams;

/** 라이트 버전도 동일하게 사용 */
export type fetchPremiumAnalysisStatusParams = {
  /**
   * v2: 프리미엄 개선 버전
   * v3: (default) 유료화 적용 버전
   */
  version?: 'v2' | 'v3';
  pnus: string[];
  logId?: number;
} & CustomInputParam;

interface Coordinate {
  X: number;
  Y: number;
  Z: number;
}

export type RoomType = '원룸형' | '투룸형' | '쓰리룸 이상';

interface FavoriteInfo {
  id: string;
  userId: number;
  uniqueCode: string;
  createdAt: string;
  updatedAt: string;
}
export interface BuildHistory {
  buildStatus: BuildStatus;
  createdAt: TimeArray;
  logId: number;
  pnu: string;
  otherPnu: string[];
}

/** 라이트 버전도 동일하게 사용 */
export interface PremiumOverviewResult {
  pnu: string;
  uniqueCode: string;
  housingType: HousingType;
  firstFloor: FirstFloorType;
  unit: RoomType;
  createAt: string;
  plotOutline: Coordinate[];
  siteOutline: Coordinate[];
  fieldOfView: Coordinate[];
  siteBounds: Coordinate[][];
  buildings: any[];
  reportLink: [];
  extra: string;
  realFar: number;
  parkLotCount: number;
  residentUnit: number;
  floorCount: number;
  isFirst: boolean;
  proposedRanking: number;
  favoriteInfo: FavoriteInfo | null;
  isOwnerVersion: boolean;
}

/** 라이트 버전도 동일하게 사용 */
export interface FetchPremiumResultListRes {
  pnu: string;
  buildStatus: BuildStatus;
  logId: number;
  otherPnu: string[];
  EngineVersions: string;
  userPrefer: UserPrefer;
  result: PremiumOverviewResult[];
  buildHistory: BuildHistory[];
}

/**
 * 설계안 리스트 조회
 * @param version v2 swk 어드민 계정으로 분석한 결과는 v2로 요청해야한다.
 * @param premiumBuildRequestLogId FetchPremiumResultListAdminParams은  ADMIN 권한이 있는 경우에만 유효하다
 */
const fetchPremiumResultList = (params: FetchPremiumResultListParams) => {
  const url =
    params?.isAdmin &&
    (params as FetchPremiumResultListAdminParams).premiumBuildRequestLogId
      ? '/insight/admin/premium/small_housings/list'
      : '/insight/premium/small_housings/list';
  return v2RestClient.get<FetchPremiumResultListRes>(url, {
    params,
    timeout: 60000,
  });
};

/**
 * 샘플 설계안 리스트 조회
 * @param version v2 swk 어드민 계정으로 분석한 결과는 v2로 요청해야한다.
 */
const fetchPremiumResultListSample = (params: FetchPremiumResultListParams) =>
  v2RestClient.get<FetchPremiumResultListRes>(
    '/insight/sample/premium/small_housings/list',
    {
      params,
      timeout: 60000,
    }
  );

/**
/** 어드민 설정@@@@
 * @param uniqueCode
 * @returns
 */

const fetchLightSmallHousing = (params: FetchLightSmallHousingParmas) => {
  const url =
    params?.isAdmin && Boolean(params.lightBuildRequestLogId)
      ? 'insight/light/small_housings'
      : // ? 'insight/admin/light/small_housings'
        'insight/light/small_housings';

  return v2RestClient.get<LightResult>(url, {
    timeout: 30000,
    params,
  });
};

/**
 * 셈플 설계안 조회
 */

const fetchLightSmallHousingSample = (params: FetchLightSmallHousingParmas) => {
  return v2RestClient.get<LightResult>(`insight/sample/light/small_housings`, {
    timeout: 30000,
    params,
  });
};

/** 가장 최근 라이트 설계 내역 조회  */
const fetchLastestLightResult = () => {
  const url = '/users/me/lights/latest';
  return v2RestClient.get<LightStatusResponse>(url, {
    timeout: 30000,
  });
};
/** 어드민 설정@@@@
 * 설계안 리스트 조회
 * @param version v2 swk 어드민 계정으로 분석한 결과는 v2로 요청해야한다.
 * @param logId FetchPremiumResultListAdminParams은  ADMIN 권한이 있는 경우에만 유효하다
 */
const fetchLightResultList = (params: FetchLightResultListParams) => {
  const url =
    params?.isAdmin && (params as FetchLightResultListAdminParams).logId
      ? // ? '/insight/admin/light/small_housings/list'
        '/insight/light/small_housings/list'
      : '/insight/light/small_housings/list';
  return v2RestClient.get<FetchPremiumResultListRes>(url, {
    params,
    timeout: 60000,
  });
};

/**
 * 샘플 설계안 리스트 조회
 * @param version v2 swk 어드민 계정으로 분석한 결과는 v2로 요청해야한다.
 */
const fetchLightResultListSample = (params: FetchLightResultListParams) =>
  v2RestClient.get<FetchPremiumResultListRes>(
    '/insight/sample/light/small_housings/list',
    {
      params,
      timeout: 60000,
    }
  );

interface UpdateFavoriteAnlaysisScenarioRes {
  id: string;
  userId: number;
  uniqueCode: string;
  createdAt: string;
  updatedAt: string;
}

/**
 * 설계안 리스트 조회
 * @param version - v2: 프리미엄분석 개선 버전- v3: 유료화 적용 버전 default
 */
const fetchPremiumAnalysisStatus = (
  params: fetchPremiumAnalysisStatusParams
) => {
  const url = '/insight/premium/small_housings/status';
  return v2RestClient.get<PremiumAnalysisStatus>(url, {
    params,
    timeout: 60000,
  });
};

/**
 * 설계안 좋아요 등록
 */
const updateFavoriteAnlaysisScenario = (
  uniqueCode: string,
  isLight?: boolean
) => {
  const url = isLight
    ? `/users/me/light/${uniqueCode}/favorites`
    : `/users/me/premium/${uniqueCode}/favorites`;
  return v2RestClient.post<UpdateFavoriteAnlaysisScenarioRes>(url);
};

/**
 * 설계안 좋아요 제거
 */
const deleteFavoriteAnlaysisScenario = (
  uniqueCode: string,
  isLight?: boolean
) => {
  const url = isLight
    ? `/users/me/light/${uniqueCode}/favorites`
    : `/users/me/premium/${uniqueCode}/favorites`;
  return v2RestClient.delete<void>(url);
};

const analysisApis = {
  fetchPremiumPreviewV3_deprecated,
  fetchPremiumPreviewV3,
  fetchPremiumPreviewV3Sample,
  savePremiumOption,
  fetchPremiumResults,
  checkPremiumRequestStatusSimulator,
  fetchLeaseProfitScenario,
  fetchSaleProfitScenario,
  requestPremiumAnalysis_deprecated,
  requestPremiumAnalysis,
  checkPremiumRequestStatus,
  checkPremiumRequestStatusByPnu,
  fetchPremiumSmallHousing,
  fetchPremiumSmallHousingSample,
  fetchCompletedPremiumListV2,
  fetchInProgressPremiumListV2,
  fetchArticleSummary,
  fetchPremiumStatus_deprecated,
  fetchPremiumStatus,
  updateMemo,
  fetchPremiumResultList,
  updateFavoriteAnlaysisScenario,
  deleteFavoriteAnlaysisScenario,
  fetchPremiumResultListSample,
  fetchPremiumStatusForGuest,
  fetchPremiumAnalysisStatus,
  fetchCompletedLightListV2,
  fetchLightResultList,
  fetchLightResultListSample,
  fetchLightSmallHousing,
  fetchLightSmallHousingSample,
  requestLightAnalysis,
  fetchLightStatus,
  fetchLastestLightResult,
};

export default analysisApis;
