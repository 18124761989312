import { Nullable } from 'utils/filterUtil';
import v2RestClient from './clients/v2RestClient';

/**
 * household: 단독다가구
 * commercial: 상가
 */
export enum ArticleType {
  HOUSEHOLD = 'dandok',
  COMMERCIAL = 'commercial',
}

export type FilterQuery = Nullable<
  Partial<{
    type: ArticleType[]; // 매물유형
    maxDealPrice: number; // 최대 매매가
    minDealPrice: number; // 최소 매매가
    maxLotArea: number; // 최대 토지면적 (단위 m²)
    minLotArea: number; // 최소 토지면적 (단위 m²)
    maxAge: number; // 최대 노후년도
    minAge: number; // 최소 노후년도
    maxGfArea: number; // 최소 연면적 (단위 m²)
    minGfArea: number; // 최소 연면적 (단위 m²)
    ratings: number[]; // 규모 검토 별점
    maxTimeToStation: number; // 역까지 최대 소요시간 (분)
    minTimeToStation: number; // 역까지 최소 소요시간 (분)
    registeredWithInDays: number; // d 일 이내
  }>
>;

/** API에 저장된 필터의 용도 */
export type FilterSource = 'interested' | 'articleMap';

const getFilterType = (source: FilterSource) =>
  source === 'interested' ? 'interested' : 'base';

/**
 * 매물찾기 필터조회
 * maxGfArea, maxLotArea등 면적 단위는 평 단위 입니다.
 * 면적 단위를 평단위로 설정하는 이유는 필터 슬라이더의 초기값이 평으로 설정되어있기 때문입니다.
 */
const fetchFilter = (source: FilterSource) =>
  v2RestClient.get<Required<FilterQuery>>(
    `/articles/me/filter/${getFilterType(source)}`
  );

const defaultFilterQuery: FilterQuery = {
  maxAge: null,
  maxDealPrice: null,
  maxGfArea: null,
  maxLotArea: null,
  maxTimeToStation: null,
  minAge: null,
  minDealPrice: null,
  minGfArea: null,
  minLotArea: null,
  minTimeToStation: null,
  ratings: null,
  registeredWithInDays: null,
};

export const filterQueryList = Object.keys(
  defaultFilterQuery
) as (keyof FilterQuery)[];

/**
 * 매물찾기 필터 업데이트
 * maxGfArea, maxLotArea등 면적 단위는 평 단위 입니다.
 * 면적 단위를 평단위로 설정하는 이유는 필터 슬라이더의 초기값이 평으로 설정되어있기 때문입니다.
 */
const updateFilter = (source: FilterSource, payload: FilterQuery) =>
  v2RestClient.put(`/articles/me/filter/${getFilterType(source)}`, {
    ...defaultFilterQuery,
    ...payload,
  });

/**
 * 필터 초기화 요청
 * @version 3.0
 */
const resetFilter = (source: FilterSource) =>
  v2RestClient.delete(`/articles/me/filter/${getFilterType(source)}`);

/**
 * 필터 파라미터 타입
 * @version 3.0_Analytics
 * @link https://www.notion.so/spacewalkcorp/API-d368a188ad8247768a656b11fdec61f7
 */

/** 매물 필터 파라미터 */
export type MapArticleFilterParams = Nullable<{
  /** 매물 유형 */
  articleType: ArticleType[];
  /** 최소 준공연차 (월 단위) */
  minConstructionAge: number;
  /** 최대 준공연차 (월 단위) */
  maxConstructionAge: number;
  /** 최소 매매가 (만원 단위) */
  minDealPrice: number;
  /** 최대 매매가 (만원 단위) */
  maxDealPrice: number;
  /** 최소 토지면적 (m² 단위) */
  minLotArea: number;
  /** 최대 토지면적 (m² 단위) */
  maxLotArea: number;
  /** 역까지 최소 소요시간 (분 단위) */
  minTimeToStation: number;
  /** 역까지 최대 소요시간 (분 단위) */
  maxTimeToStation: number;
  /**
   * @description 규모 검토 별점
   * @example [1, 2, 3, 4, 5]
   */
  ratings: number[];
  /**
   * n일 이내
   * 현재는 한달 이내(30)만 지원됨
   */
  registeredWithInDays: number;
}>;

/** 실거래 필터 파라미터 */
export type NearbyFilterParams = Nullable<{
  /**
   * @description 최소 기간 (월 단위)
   * @example 6개월 = 6, 1년 = 12
   */
  minContractAge: number;
  /**
   * @description 최대 기간 (월 단위)
   * @example 6개월 = 6, 1년 = 12
   */
  maxContractAge: number;
  /** 최소 준공연차 (월 단위) */
  minConstructionAge: number;
  /** 최대 준공연차 (월 단위) */
  maxConstructionAge: number;
  /** 최소 토지면적 (m² 단위) */
  minLotArea: number;
  /** 최대 토지면적 (m² 단위) */
  maxLotArea: number;
  /** 최소 전용면적 (m² 단위) */
  minExclusiveArea: number;
  /** 최대 전용면적 (m² 단위) */
  maxExclusiveArea: number;
  /** 필지와의 최소 거리 (m 단위) */
  minDistance: number;
  /** 필지와의 최대 거리 (m 단위) */
  maxDistance: number;
}>;

export type ConstructionState = '인허가' | '공사중' | '공사완료';

/** 신축사례 필터 파라미터 */
export type NewConstructionFilterParams = Nullable<{
  /** 최소 토지면적 (m² 단위) */
  minLotArea: number;
  /** 최대 토지면적 (m² 단위) */
  maxLotArea: number;
  /**
   * 상태
   * @example ['인허가', '공사완료']
   */
  state: ConstructionState[];
  /**
   * 최소 기간 (개월 단위)
   * @example 6개월 = 6, 1년 = 12
   */
  minDevelopmentAge: number;
  /**
   * 최대 기간 (개월 단위)
   * @example 6개월 = 6, 1년 = 12
   */
  maxDevelopmentAge: number;
}>;

/** 관심지역 필터 파라미터 */
export type InterestedArticleFilterParams = Nullable<{
  /** 최소 준공연차 (월 단위) */
  minConstructionAge: number;
  /** 최대 준공연차 (월 단위) */
  maxConstructionAge: number;
  /** 최소 매매가 (만원 단위) */
  minDealPrice: number;
  /** 최대 매매가 (만원 단위) */
  maxDealPrice: number;
  /** 최소 토지면적 (m² 단위) */
  minLotArea: number;
  /** 최대 토지면적 (m² 단위) */
  maxLotArea: number;
}>;

/** 주택임대 필터 파라미터 */
export type NearbyRentFilterParams = Nullable<{
  /** 최소 전용면적 (m² 단위) */
  minExclusiveArea: number;
  /** 최대 전용면적 (m² 단위) */
  maxExclusiveArea: number;
  /**
   * 최소 기간 (월 단위)
   * @example 6개월 = 6, 1년 = 12
   */
  minContractAge: number;
  /**
   * 최대 기간 (월 단위)
   * @example 6개월 = 6, 1년 = 12
   */
  maxContractAge: number;
  /** 필지와의 최소 거리 (m 단위) */
  minDistance: number;
  /** 필지와의 최대 거리 (m 단위) */
  maxDistance: number;
}>;

/** 주택임대/매매 필터 파라미터 */
export type DealCaseFilterParams = Nullable<{
  /** 최소 전용면적 (m² 단위) */
  minExclusiveArea: number;
  /** 최대 전용면적 (m² 단위) */
  maxExclusiveArea: number;
  /**
   * 최소 기간 (월 단위)
   * @example 6개월 = 6, 1년 = 12
   */
  minContractAge: number;
  /**
   * 최대 기간 (월 단위)
   * @example 6개월 = 6, 1년 = 12
   */
  maxContractAge: number;
  /** 필지와의 최소 거리 (m 단위) */
}>;

export type HousingType = '주거 전체' | '단독주택' | '다가구주택';
export type CommercialType =
  | '업무 및 상업 전체'
  | '상가'
  | '사무실'
  | '공장/창고'
  | '농가시설'
  | '기타';
export type LandUseType =
  | '토지 전체'
  | '대지'
  | '전.답'
  | '임야'
  | '잡종지'
  | '과수원'
  | '목장용지'
  | '공장용지'
  | '창고용지';

export type CourtAuctionFilterParams = {
  /** 부동산유형 (주거) */
  housingCategory: HousingType[];
  /** 부동산유형 (상업업무) */
  commercialCategory: CommercialType[];
  /** 부동산유형 (대지) */
  landCategory: LandUseType[];
  /** 최소 매각기일 */
  startBiddingPeriod: string | null;
  /** 최대 매각기일 */
  endBiddingPeriod: string | null;
  /** 최소 감정가(원) */
  minAppraisedValue: number | null;
  /** 최대 감정가(원) */
  maxAppraisedValue: number | null;
  /** 최저가 최소 금액(원) */
  minLowestBiddingPrice: number | null;
  /** 최저가 최대 금액(원) */
  maxLowestBiddingPrice: number | null;
  /** 최소 유찰 횟수 */
  minFailedBiddingCount: number | null;
  /** 최대 유찰 횟수 */
  maxFailedBiddingCount: number | null;
};

export type PublicAuctionFilterParams = {
  /** 부동산유형 (주거) */
  housingCategory: HousingType[];
  /** 부동산유형 (상업업무) */
  commercialCategory: CommercialType[];
  /** 부동산유형 (대지) */
  landCategory: LandUseType[];
  /** 최소 입찰기간 */
  startBiddingPeriod: string | null;
  /** 최대 입찰기간 */
  endBiddingPeriod: string | null;
  /** 최소 감정가(원) */
  minAppraisedValue: number | null;
  /** 최대 감정가(원) */
  maxAppraisedValue: number | null;
  /** 최저가 최소 금액(원) */
  minLowestBiddingPrice: number | null;
  /** 최저가 최대 금액(원) */
  maxLowestBiddingPrice: number | null;
  /** 최소 유찰 횟수 */
  minFailedBiddingCount: number | null;
  /** 최대 유찰 횟수 */
  maxFailedBiddingCount: number | null;
};

export type FilterParams = {
  mapArticle: MapArticleFilterParams;
  nearby: NearbyFilterParams;
  newConstruction: NewConstructionFilterParams;
  interestedArticle: InterestedArticleFilterParams;
  dealCase: DealCaseFilterParams;
  courtAuction: CourtAuctionFilterParams;
  publicAuction: PublicAuctionFilterParams;
};

export type PartialMapArticleFilterParams = Partial<MapArticleFilterParams>;
export type PartialNearbyFilterParams = Partial<NearbyFilterParams>;
export type PartialNewConstructionFilterParams =
  Partial<NewConstructionFilterParams>;
export type PartialInterestedArticleFilterParams =
  Partial<InterestedArticleFilterParams>;
export type PartialDealCaseFilterParams = Partial<DealCaseFilterParams>;
export type PartialCourtAuctionFilterParams = Partial<CourtAuctionFilterParams>;
export type PartialPublicAuctionFilterParams =
  Partial<PublicAuctionFilterParams>;

export type PartialCommonFilterParams = Partial<
  Nullable<{
    /** 최소 준공연차 (월 단위) */
    minConstructionAge: number;
    /** 최대 준공연차 (월 단위) */
    maxConstructionAge: number;
    /** 최소 매매가 (만원 단위) */
    minDealPrice: number;
    /** 최대 매매가 (만원 단위) */
    maxDealPrice: number;
    /** 최소 토지면적 (m² 단위) */
    minLotArea: number;
    /** 최대 토지면적 (m² 단위) */
    maxLotArea: number;
    minLowestBiddingPrice: number;
    maxLowestBiddingPrice: number;
    minAppraisedValue: number;
    maxAppraisedValue: number;
    minFailedBiddingCount: number;
    maxFailedBiddingCount: number;
    startBiddingPeriod: string;
    endBiddingPeriod: string;
  }>
>;

export type PartialFilterParams = Partial<{
  mapArticle: PartialMapArticleFilterParams;
  nearby: PartialNearbyFilterParams;
  newConstruction: PartialNewConstructionFilterParams;
  interestedArticle: PartialInterestedArticleFilterParams;
  dealCase: PartialDealCaseFilterParams;
  publicAuction: PartialPublicAuctionFilterParams;
  courtAuction: PartialCourtAuctionFilterParams;
}>;

export type FilterType =
  | 'mapArticle'
  | 'nearby'
  | 'newConstruction'
  | 'interestedArticle'
  | 'dealCase'
  | 'courtAuction'
  | 'publicAuction';

export type RangeInput = {
  min: number | null;
  max: number | null;
};

/**
 * 필터 파라미터 기본값
 * API 응답 fallback 또는 필터 초기화에 활용함
 */
export const defaultFilterParams: FilterParams = {
  mapArticle: {
    articleType: null,
    minConstructionAge: null,
    maxConstructionAge: null,
    minDealPrice: null,
    maxDealPrice: null,
    minLotArea: null,
    maxLotArea: null,
    minTimeToStation: null,
    maxTimeToStation: null,
    ratings: null,
    registeredWithInDays: null,
  },
  nearby: {
    minContractAge: null,
    maxContractAge: null,
    minConstructionAge: 240,
    maxConstructionAge: null,
    minLotArea: null,
    maxLotArea: null,
    minExclusiveArea: null,
    maxExclusiveArea: null,
    minDistance: null,
    maxDistance: null,
  },
  newConstruction: {
    minLotArea: null,
    maxLotArea: null,
    state: ['공사완료'],
    minDevelopmentAge: null,
    maxDevelopmentAge: null,
  },
  interestedArticle: {
    minConstructionAge: null,
    maxConstructionAge: null,
    minDealPrice: null,
    maxDealPrice: null,
    minLotArea: null,
    maxLotArea: null,
  },
  dealCase: {
    minContractAge: null,
    maxContractAge: null,
    minExclusiveArea: null,
    maxExclusiveArea: null,
  },
  courtAuction: {
    minLowestBiddingPrice: null,
    maxLowestBiddingPrice: null,
    startBiddingPeriod: null,
    endBiddingPeriod: null,
    minAppraisedValue: null,
    maxAppraisedValue: null,
    maxFailedBiddingCount: null,
    minFailedBiddingCount: null,
    housingCategory: ['주거 전체'],
    commercialCategory: ['업무 및 상업 전체'],
    landCategory: ['토지 전체'],
  },
  publicAuction: {
    minLowestBiddingPrice: null,
    maxLowestBiddingPrice: null,
    startBiddingPeriod: null,
    endBiddingPeriod: null,
    minAppraisedValue: null,
    maxAppraisedValue: null,
    maxFailedBiddingCount: null,
    minFailedBiddingCount: null,
    housingCategory: ['주거 전체'],
    commercialCategory: ['업무 및 상업 전체'],
    landCategory: ['토지 전체'],
  },
};

/**
 * 필터 정보 조회
 * @version 3.0_Analytics
 */
const fetchFilterV2 = () => v2RestClient.get<FilterParams>(`/users/me/filters`);

/**
 * 필터 저장
 * @version 3.0_Analytics
 */
const updateFilterV2 = (params: Partial<FilterParams>) =>
  v2RestClient.put<void>(`/users/me/filters`, params);

const filterApis = {
  fetchFilter,
  updateFilter,
  resetFilter,
  fetchFilterV2,
  updateFilterV2,
};

export default filterApis;
