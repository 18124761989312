import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/client';

type InitialState = {
  isOpenInterestedSettingModal: boolean;
  isOpenInterestedContent: boolean;
};

const initialState: InitialState = {
  isOpenInterestedSettingModal: false,
  isOpenInterestedContent: true,
};

const homeSlice = createSlice({
  name: 'homeSlice',
  initialState,
  reducers: {
    setIsInterestedSettingModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenInterestedSettingModal = action.payload;
    },
    setIsOpenInterestedContent: (state, action: PayloadAction<boolean>) => {
      state.isOpenInterestedContent = action.payload;
    },
  },
});

export const { setIsInterestedSettingModal, setIsOpenInterestedContent } =
  homeSlice.actions;

export const selectHome = (state: RootState) => state.home;

export default homeSlice.reducer;
